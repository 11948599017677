<template>
    <div class="otpscreen">
        <div class="container-fluid" style="">
            <div class="containers">
                <div class="row header-login">
                    <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">

                    </div>
                    <div class="col-lg-6 col-sm-10 col-md-8 col-xs-12"
                        style="background: #FFFFFF; border-radius: 15px;">

                        <h2 class="text-center acount-header" style="color: #1D1D1D; font-weight: 600;">Verification
                            Code</h2>
                        <p class="login-paragraph text-center">Please type the verification code send to</p>
                        <h4 class="text-center mb-4" style="margin-top: -12px; color: #1D1D1D; font-weight: 600;">{{
                            emailotp }}</h4>
                        <div class="mb-3 mt-2 emailmargin">
                            <v-otp-input v-model="otp" @finish="addOtp" type="number" length="4"></v-otp-input>
                            <v-snackbar v-model="snackbar" color="red" :timeout="2000">
                                {{ text }}
                            </v-snackbar>
                        </div>
                        <div class="mb-4 mt-2 emailmargin" style="display: flex; justify-content: space-between;">

                            <div>
                                <p class="login-paragraph" style="font-size: 14px;">{{ textsendotp }}</p>
                            </div>
                            <div class="resendmail">
                                <button :disabled="disabled" @click="ResendCodeotp()"
                                    style="color: #000000; cursor: pointer;">{{ textbuttonresend }}</button>
                            </div>


                        </div>
                        <v-row justify="center">
                            <v-dialog v-model="dialogComfirmopt" persistent max-width="400px"
                                style="z-index: 1002; background-color: #FFFFFF !important; border-radius: 6px !important;">
                                <v-card style="background-color: #FFFFFF !important; border-radius: 10px !important;">
                                    <div class="container">
                                        <div class="row d-flex justify-center">
                                            <div class="col-10">
                                                <div style="text-align: center;">
                                                    <img src="@/assets/alert/ic_tick_green.svg" alt=""
                                                        style="width: 128px;">
                                                </div>
                                                <div style="text-align: center;">
                                                    <h1 class="titlebox" style="font-size: 20px !important;">Your
                                                        Account Success</h1>
                                                </div>
                                                <div style="text-align: center;">
                                                    <p class="titlebox">You can buy bus ticket, travel package</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                </v-card>
                            </v-dialog>
                        </v-row>
                    </div>
                    <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            otp: "",
            dialogComfirmopt: false,
            snackbar: false,
            text: '',
            textsendotp: `Didn't received code ?`,
            seconds: 180,
            disabled: false,
            textbuttonresend: 'Resend Code',
            remanberPage: localStorage.getItem('handleRemamberPage'),

            emailotp: localStorage.getItem('emailUser'),
        }
    },
    computed: {

    },

    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },

        startTimer() {
            this.timer = setInterval(() => {
                if (this.seconds > 1) {
                    this.seconds--;
                    this.textsendotp = 'Request new code in ' + this.formatTime(this.seconds);
                } else {
                    this.textsendotp = `Didn't received code ?`;
                    clearInterval(this.timer);
                    // Optionally, you can perform an action when the countdown reaches zero
                }
            }, 1000);
        },
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${this.pad(minutes)}:${this.pad(remainingSeconds)}`;
        },
        pad(value) {
            return value < 10 ? `0${value}` : value;
        },


        async ResendCodeotp() {
            clearInterval(this.timer);
            this.disabled = true;
            this.textbuttonresend = "Loading";
            const data = {
                'email': localStorage.getItem('emailUser'),
            }
            const url = this.$url + `user-register/resend-verification-email`;
            let config = {
                method: "POST",
                headers: {
                    ...this.$header,
                    "Content-Type": "multipart/form-data"
                },
                data: data,
                url,
            };
            axios(config).then((response) => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        if (response.data.body.status == true) {
                            let token = response.data.body.message;
                            this.seconds = 180;
                            this.startTimer();
                            this.disabled = false;
                            this.textbuttonresend = "Resend Code";
                            localStorage.setItem('tokenUser', token);
                        } else {
                            alert(response.data.body.message);
                        }
                    } else {
                        alert("Resend Email is Errors");
                    }
                }
            }).catch((error) => {
                console.log(error);
            });


        },
        async addOtp(value) {
            // Filter out non-numeric characters
            this.otp = value.replace(/\D/g, '');
            if (this.remanberPage == 1) {
                const data = {
                    'code': this.otp,
                    'token': localStorage.getItem('tokenUser'),
                }
                const url = this.$url + `user-register/verification`;
                let config = {
                    method: "POST",
                    headers: {
                        ...this.$header,
                        "Content-Type": "multipart/form-data"
                    },
                    data: data,
                    url,
                };
                axios(config).then((response) => {
                    if (response.status == 200) {
                        if (response.data.header.result == true && response.data.header.statusCode == 200) {
                            if (response.data.body.status == true) {
                                localStorage.removeItem('handleRemamberPage');
                                this.dialogComfirmopt = true;
                                localStorage.setItem('session_start_login', response.data.body.message);
                                // Retrieve the stored redirect URL
                                const redirectUrl = localStorage.getItem('redirect_after_login') || '/';
                                // Clear the stored redirect URL to prevent future redirects
                                localStorage.removeItem('redirect_after_login');

                                setTimeout(() => {
                                    this.$router.push(redirectUrl+"?loginSuccess=1");
                                }, 500);
                            } else {
                                this.otp = '';
                                this.snackbar = true;
                                this.text = response.data.body.message;
                            }
                        } else {
                            alert("Please Input Information");
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                const data = {
                    'code': this.otp,
                    'token': localStorage.getItem('tokenUser'),
                }
                const url = this.$url + `user-register/reset-password`;
                let config = {
                    method: "POST",
                    headers: {
                        ...this.$header,
                        "Content-Type": "multipart/form-data"
                    },
                    data: data,
                    url,
                };
                axios(config).then((response) => {
                    if (response.status == 200) {
                        if (response.data.header.result == true && response.data.header.statusCode == 200) {
                            if (response.data.body.status == true) {
                                let token = response.data.body.message;
                                localStorage.setItem('tokenUser', token);
                                localStorage.removeItem('handleRemamberPage');
                                this.dialogComfirmopt = true;
                                setTimeout(() => {
                                    this.dialogComfirmopt = false;
                                    this.$router.push('/reset-new-password');
                                }, 500);
                            } else {
                                this.otp = '';
                                this.snackbar = true;
                                this.text = response.data.body.message;
                            }
                        } else {
                            alert("Please Input Information");
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        },
    },

    created() {
        this.scrollToTop();
    }
}
</script>
<style scoped>
/* body {
    background: #f2f2f2 !important;
}
body .container-fluid{
    background: #f2f2f2;
}
.container-fluid{
    min-height: 28rem;
    height: calc(85vh - 150px);
} */
.containers {
    min-height: 28rem;
    height: 100%;

}

.otpscreen {
    padding-top: 55px;
    /* padding-bottom: 50px; */
    margin-top: 28px;

}

.resendmail button:hover {
    color: #DE5D0A !important;
    /* font-weight: 400; */
}

.v-text-field__slot {
    border: 1px solid red !important;
}

.acount-header {
    margin-top: 15px;

}

.emailmargin {
    margin-left: 100px;
    width: 62%;
}

.header-login {
    padding-top: 35px;
    padding-bottom: 70px;
    box-sizing: border-box;
}

.login-paragraph {
    color: #6A6969;
    font-size: 13px;
}

label {
    font-weight: bold;
}

.v-application .primary--text {
    color: #DE5D0A !important;
}

.inner-block {
    display: block;
    width: 92%;
    margin-bottom: 4px;
}

.form-input-login {
    /* width: 100%; */
    width: 91%;
    border: 1px solid #C6C6C6;
    border-radius: 6px;
    height: 45px;
    outline: none;
    padding-left: 12px;
}

.forgetPasswordform {
    width: 91%;
    text-align: right;
}

.btn-width {
    width: 91%;
    border-radius: 4px;
    background-color: #DE5D0A;
    /* z-index: 10000; */
}

.v-dialog__content {
    top: -32px;
}

@media(min-width: 1740px) {}

@media(width < 1025px) AND (width > 1023.50px) {
    .v-dialog__content {
        top: -330px;
    }
}

@media screen and (width < 916px) AND (width > 900px) {

    /* .otpscreen {
          margin-top: -20px;
      } */
    /* .mobilescreencol{
          display: none !important;
      } */
    .v-dialog__content {
        top: -290px;
    }


}

@media screen and (width < 620px) {

    /* .otpscreen {
          margin-top: -20px;
      } */
    /* .mobilescreencol{
          display: none !important;
      } */
    .header-login {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .acount-header {
        margin-top: 10px;
    }

    .emailmargin {
        margin-left: 44px;
    }



}

@media screen and (width < 500px) {

    /* .otpscreen {
          margin-top: -20px;
      } */
    .mobilescreencol {
        display: none !important;
    }

    .header-login {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .acount-header {
        margin-top: 10px;
    }

    .emailmargin {
        margin-left: 44px;
    }



}

@media(width < 450px) {

    /* .otpscreen {
          margin-top: -20px;
      } */
    .mobilescreencol {
        display: none !important;
    }

    .header-login {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .acount-header {
        margin-top: 10px;
    }

    .emailmargin {
        margin-left: 44px;
    }


}

@media screen and (width < 438px) AND (width > 389px) {
    /* .otpscreen {
          margin-top: -20px;
      } */


    .mobilescreencol {
        display: none !important;
    }

    .header-login {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .acount-header {
        margin-top: 10px;
    }

    .emailmargin {
        margin-left: 44px;
    }

    .v-dialog__content {
        top: -190px;
    }



}
</style>