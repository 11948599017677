<template>
    <div class="ticket_acc_his" :class="{ 'overlay-active': showPopup }">
        <div class="container-fluid" style="background: #f2f2f2;">
            <div class="containers">
                <div class="row header-profile">
                    <div class="d-flex"
                        style="border-bottom: 1px solid #C6C6C6; width: 100%; margin-left: 6px; margin-right: 6px;">
                        <div class="account-ticket account-ticket-1" :class="{ active: activeTab == '1' }"
                            @click="toggleTab('1')">
                            {{ $t('message.ticketHistory') }} 
                        </div>
                    </div>
                    <div v-show="activeTab == 1" class="upcom-ticket mt-8">

                        <div v-if="isLoading" class="text-center">
                            Loading...
                        </div>

                        <div v-else-if="tickets && tickets.length > 0" class="row" style="">
                            <div class="col-lg-11 col-md-11 col-sm-11">
                                <div class="row justify-space-between ticket-history-grid">
                                    <div v-for="ticket in tickets" :key="ticket.id" class="box-ticket-his"
                                        style="background-color: #FFFFFF; border-radius: 10px; padding: 12px; margin-bottom: 0px;"
                                        @click="openPopup(ticket.id)">
                                        <div class="d-flex align-center">
                                            <div class="img-src">
                                                <img :src="ticket.journeyType === 1 ? require('@/assets/vireak-buntham.png') : (ticket.journeyType === 3 ? require('@/assets/airbus.png') : require('@/assets/vireak-buntham.png'))"
                                                    :alt="ticket.journeyType === 1 ? 'VET' : (ticket.journeyType === 3 ? 'Airbus' : 'VET')" />
                                            </div>
                                            <div>
                                                <span class="titile-span">{{ ticket.destinationFrom }} - {{
                                                    ticket.destinationTo }}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-center mt-4">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon12">
                                                    <img src="@/assets/ticket_history/ticket-code.png" alt="" />
                                                </div>
                                                <div>
                                                    <span class="title-item">{{ ticket.code }}</span>
                                                </div>
                                            </div>
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/truck.svg" alt="" />
                                                    <!-- <i class="fa-regular fa-calendar"></i> -->
                                                </div>
                                                <div>
                                                    <span class="title-item">{{ ticket.travelDate }} ({{
                                                        ticket.departure }})</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex align-center mt-4">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/icon-bus.png" alt="" />
                                                </div>
                                                <div>
                                                    <span class="title-item">{{ ticket.transportationType }}</span>
                                                </div>
                                            </div>
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/user.svg" alt="" />
                                                </div>
                                                <div>
                                                    <span class="title-item">{{ ticket.totalSeat }} {{ $t('message.seats') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else class="no-data-container">
                            <div class="no-data-content">
                                <img src="@/assets/ticket_history/ticket-history.svg" alt="No Tickets"
                                    class="no-data-icon" />
                                <p class="no-data-text">{{ $t('message.dataNotFound') }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- Add overlay div -->
                    <div class="overlay" v-if="showPopup" @click="closePopup"></div>

                    <div class="component-popup" :class="{ 'popup-show': showPopup }">
                        <TicketHistoryDetailComponent v-if="showPopup" @close="closePopup" />
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import TicketHistoryDetailComponent from './TicketHistoryDetail-Component.vue';
import axios from 'axios'

export default {
    components: {
        TicketHistoryDetailComponent
    },
    data() {
        return {
            activeTab: 1,
            copyStatus: 'Copy',
            showPopup: false,
            tickets: [],
            isLoading: false,
            currentPage: 1,
            rowsPerPage: 100,
        }
    },
    computed: {

    },

    methods: {
        toggleTab(tab) {
            this.activeTab = tab;
        },
        openPopup(ticketId) {
            // Save the ticket ID first
            localStorage.setItem('selectedTicketId', ticketId);

            // Force scroll to top immediately
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0; // For Safari

            // Then show popup
            this.showPopup = true;

            // Prevent body scrolling
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
        },
        closePopup() {
            this.showPopup = false;
            // Re-enable scrolling
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
        },
        // Copytitle(text) {
        //     this.copyStatus = 'Copied!';
        //     const textarea = document.createElement('textarea');
        //     textarea.value = text;
        //     document.body.appendChild(textarea);
        //     // Select and copy the text from the textarea
        //     textarea.select();
        //     document.execCommand('copy');
        //     // Remove the textarea from the DOM
        //     setTimeout(() => {
        //         this.copyStatus = 'Copy';
        //     }, 1000); // You can adjust the delay as needed
        //     document.body.removeChild(textarea);
        // },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        async fetchTicketHistory() {
            try {
                this.isLoading = true;
                this.error = null;
                const url = `${this.$url}booking/list`;

                const requestData = {
                    session: localStorage.getItem("session_start_login"),
                    page: this.currentPage,
                    rowsPerPage: this.rowsPerPage,
                };

                const response = await axios.post(url, requestData, {
                    headers: {
                        ...this.$header,
                        "Content-Type": "application/json",
                    },
                });

                // Check if the response is successful
                if (response.data && response.data.body && response.data.body.status === true && Array.isArray(response.data.body.data)
                ) {
                    this.tickets = response.data.body.data;

                    this.pagination = response.data.body.pagination;
                } else {
                    this.tickets = [];
                    this.pagination = {
                        page: 1,
                        rowsPerPage: this.rowsPerPage,
                        total: 0,
                    };
                    if (response.data.body && response.data.body.message) {
                        this.error = response.data.body.message;
                    } else {
                        this.error = "No ticket data available.";
                    }
                }
            } catch (error) {
                this.error = "Failed to load tickets. Please try again.";
            } finally {
                this.isLoading = false;
            }
        },
        formatDate(dateStr) {
            const options = { year: "numeric", month: "long", day: "numeric" };
            return new Date(dateStr).toLocaleDateString(undefined, options);
        },
        formatTime(timeStr) {
            const [hours, minutes, seconds] = timeStr.split(":");
            const date = new Date();
            date.setHours(parseInt(hours), parseInt(minutes), parseInt(seconds));
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        },
    },

    created() {
        this.scrollToTop();
        this.fetchTicketHistory();
    }
}
</script>
<style scoped>
body.body-lock {
    position: fixed;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.img-icon {
    width: 24px;
}

.img-icon12 {
    width: 24px;
}

.img-icon12 img {
    width: 100%;
}

.img-icon img {
    width: 100%;
}

.img-src {
    width: 36px;
}

.img-src img {
    width: 100%;
}

.component-popup {
    position: fixed;
    right: -100%;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    transition: right 0.5s ease-in-out;
    z-index: 1000;
    border-radius: 12px;
    max-height: 90vh; /* Limit height to 90% of viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
}

.component-popup.popup-show {
    right: 180px;
}

.ticket_acc_his {
    padding-top: 55px;
    /* min-height: calc(100vh - 250px); */
    display: flex;
    flex-direction: column;
    position: relative;
}

.header-profile {
    padding-top: 30px;
    padding-bottom: 100px;
    box-sizing: border-box;
    flex: 1;
}

.container-fluid {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.container {
    flex: 1;
}

.inf-account .tel {
    color: #1D1D1D !important;
    font-size: 16px;
}

.inf-account .number {
    color: #312783 !important;
    font-size: 16px;
}

.box-left-img {
    width: 28%;
}

.items .title-item {
    color: #1D1D1D;
    font-weight: 600;
    margin-left: 12px;
}

.upcom-ticket {
    margin-left: 65px;
    width: 100%;
}

.used-ticket {
    margin-left: 65px;
    width: 100%;
}

.box-qr {
    background-color: #D9D9D9;
    padding: 25px 30px 50px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.box-ticket-his {
    cursor: pointer;
    width: 49%;
}

.box-ticket-his .titile-span {
    color: #1D1D1D;
    font-weight: 650;
    margin-left: 20px;

}

.account-ticket-1 {
    margin-left: 50px;
}

.inf-left-box div,
.inf-right-box div {
    color: #1D1D1D;
    font-size: 15px;
}

.title-distination {
    color: #1D1D1D;
    font-weight: 700;
}

.in-seat span {
    color: #DE5D0A;
    font-size: 17px;
    font-weight: 650;
}

.account-ticket.active {
    border-bottom: 3px solid #312783;
}

.account-ticket:hover {
    color: #312783 !important;
}

.box-account {
    background: #FF0000;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 15px 7px 15px;
}

.account-ticket {
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    color: #000000;
}

.used-ticket .title-package {
    color: #312783;
}

.information-travel ul li {
    color: #6A6969;
    padding: 1px;
}

.copy-past {
    cursor: pointer;
}

@media screen and (width < 2000px) {
    .component-popup.popup-show {
        right: 330px;
        max-width: 1250px;
    }
}

@media screen and (width < 1900px) {
    .component-popup.popup-show {
        right: 260px;
        overflow-y: auto;
    }

}

@media screen and (width < 1800px) {
    .component-popup.popup-show {
        right: 240px;
        overflow-y: auto;
    }
}

@media screen and (width < 1750px) {
    .component-popup.popup-show {
        right: 220px;
        overflow-y: auto;
    }
}

@media screen and (width < 1700px) {
    .component-popup.popup-show {
        right: 200px;
        overflow-y: auto;
    }
}

@media screen and (width < 1650px) {
    .component-popup.popup-show {
        right: 185px;
        overflow-y: auto;
        max-width: 1150px;
    }
}

@media screen and (width < 1500px) {
    .component-popup.popup-show {
        right: 170px;
    }
}

@media screen and (width < 1460px) {
    .component-popup.popup-show {
        right: 160px;
        overflow-y: auto;
    }
}

@media screen and (width < 1430px) {
    .component-popup.popup-show {
        right: 140px;
        overflow-y: auto;
    }
}

@media screen and (width < 1400px) {
    .component-popup.popup-show {
        right: 120px;
        overflow-y: auto;
    }
}

@media screen and (width < 1360px) {
    .component-popup.popup-show {
        right: 100px;
        overflow-y: auto;
    }
}

@media screen and (width < 1330px) {
    .component-popup.popup-show {
        right: 70px;
        overflow-y: auto;
    }
}

@media screen and (width < 1265px) {
    .box-left-img {
        width: 35%;
    }

    .box-right-info {
        width: 65%;
        padding: 18px 15px 18px 25px;
    }

    .inf-left-box div,
    .inf-right-box div {
        font-size: 13px;
    }

    .fs-span-luckey {
        font-size: 13px;
    }

    .component-popup.popup-show {
        right: 60px;
    }
}

@media screen and (width < 1210px) {
    .component-popup.popup-show {
        right: 0px;
        left: 0px;
        overflow-y: auto;
    }
}

@media screen and (width < 1000px) {
    .component-popup.popup-show {
        right: 0px;
        overflow-y: auto;
    }
}

@media(width < 480px) {
    .component-popup.popup-show {

        overflow-y: auto;
    }
}

@media(width < 450px) {
    .ticket-content {
        padding-top: 0;
    }

    .component-popup.popup-show {

        overflow-y: auto;
    }
}

@media screen and (width < 438px) {
    .upcom-ticket {
        margin-left: 20px;
        width: 90%;
    }

    .used-ticket {
        margin-left: 20px;
        width: 90%;
    }

    .account-ticket-1 {
        margin-left: 15px;
    }

    .items .title-item {
        font-size: 13px;
    }

    .note-ticket-his {
        font-size: 13px;
    }
}

@media screen and (width < 395px) {
    .items .title-item {
        font-size: 11px;
    }

    .note-ticket-his {
        font-size: 11px;
    }
}

@media screen and (max-width: 768px) {
    .ticket_acc_his {
        min-height: calc(100vh - 200px);
    }

    .component-popup {
        max-height: 100vh; /* Full height on mobile */
        top: 0;
        transform: none;
        width: 100%;
    }
    
    .component-popup.popup-show {
        right: 0;
    }
}

@media screen and (max-width: 480px) {
    .header-profile {
        padding-bottom: 40px;
    }
}

.no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    width: 100%;
}

.no-data-content {
    text-align: center;
}

.no-data-icon {
    width: 80px;
    height: 80px;
    /* margin-bottom: 20px; */
    opacity: 0.5;
}

.no-data-text {
    color: #DE5D0A;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .no-data-container {
        min-height: 300px;
    }

    .no-data-icon {
        width: 60px;
        height: 60px;
    }

    .no-data-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 480px) {
    .no-data-container {
        min-height: 200px;
    }

    .no-data-icon {
        width: 50px;
        height: 50px;
    }

    .no-data-text {
        font-size: 14px;
    }
}

/* Add these new styles */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent black */
    z-index: 999;
    /* just below the popup */
}

.overlay-active {
    overflow: hidden;
}

.ticket-history-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.box-ticket-his {
    width: calc(50% - 10px); /* Two cards per row by default */
    margin-bottom: 15px;
    transition: all 0.3s ease;
}

/* Tablet breakpoint */
@media screen and (max-width: 991px) {
    .box-ticket-his {
        width: 100%; /* One card per row */
        margin-left: 0;
        margin-right: 0;
    }
    
    .ticket-history-grid {
        padding: 0 15px;
    }
}

/* Mobile breakpoint */
@media screen and (max-width: 576px) {
    .box-ticket-his {
        width: 100%;
        /* margin: 0 0 15px 0; */
    }

    .ticket-history-grid {
        padding: 0 10px;
    }

    /* Adjust inner content spacing for mobile */
    .items {
        width: 100% !important;
        margin-bottom: 8px;
    }

    .d-flex.align-center.mt-5 {
        /* flex-direction: column; */
        align-items: flex-start;
    }

    .d-flex.align-center.mt-5 > div {
        margin-bottom: 10px;
    }

    .titile-span {
        font-size: 14px;
    }

    .title-item {
        font-size: 13px;
    }
}

/* Improve touch targets on mobile */
@media (hover: none) {
    .box-ticket-his {
        cursor: pointer;
        padding: 15px;
    }
}

/* Add smooth scrolling to the popup content */
.component-popup {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.component-popup::-webkit-scrollbar {
    width: 6px;
}

.component-popup::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.component-popup::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.component-popup::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
