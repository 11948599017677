<template>
    <div class="explore-trend">
        <div class="containers">
            <p class="explare">{{ $t('message.exporeTrendingPlace') }}</p>
            <div class="row g-4">
                <div class="col-md-6" v-for="(dataBlogScroll, i) in sortedPlaces" :key="i">
                    <div class="destination-card">
                        <img :src="dataBlogScroll.image" :alt="$t(dataBlogScroll.place)">
                        <div class="text-name">{{ $t(dataBlogScroll.place) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataBlogScroll: [
                {
                    image: require('@/assets/explore/vireak-buntham-cambodia.jpg'),
                    place: 'message.cambodia',
                },
                {
                    image: require('@/assets/explore/vireak-buntham-thailand.jpg'),
                    place: 'message.thailand',
                },
                {
                    image: require('@/assets/explore/vireak-buntham-vietnam.jpg'),
                    place: 'message.vietnam',
                    title: '',
                    orderNumber: '',
                },
                {
                    image: require('@/assets/explore/vireak-buntham-laos.jpg'),
                    place: 'message.laos',
                },
            ]
        }
    },
    computed: {
        sortedPlaces() {
            return [...this.dataBlogScroll].sort((a, b) => {
                return this.$t(a.place).localeCompare(this.$t(b.place))
            })
        }
    }
}
</script>

<style scoped>
.explore-trend {
    padding: 60px 0;
    background: #F2F2F2;
}

.explare {
    color: #1D1D1D;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 20px;
}

.destination-card {
    position: relative;
    width: 100%;
    height: 320px;
    border-radius: 12px;
    overflow: hidden;
}

.destination-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-name {
    position: absolute;
    top: 20px;
    left: 25px;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Responsive */
@media(max-width: 768px) {
    
    .text-name {
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    .explore-trend {
        display: none;
    }
}

@media (min-width: 1024px) {
    .destination-card {
        height: 320px; /* laptop */
    }
}

@media (min-width: 1200px) {
    .destination-card {
        height: 340px; /* desktop */
    }
}
</style>