export default {
    message: {
        vireakButhamExpress: 'Vireak Buntham Express',
        home: '首页',
        aboutUs: '关于我们',
        speedBoat: '快艇',
        resort: '度假酒店',
        rentalService: '车辆出租',
        subsidiary: '子公司',
        gallery: '相册',
        blogs: '博客',
        journeyguideincambodia: '柬埔寨旅游指南',
        contact: '联系',
        english: 'English',
        khmer: 'Khmer',
        chinese: 'Chinese',
        login: '登录',
        register: '注册',
        signinaccount: '登录帐户',
        fullName: '全名',
        registerAccount: '註冊帳戶',
        loginRegister: '登入 / 注册',
        profileAccount: '账号档案',
        travelPackageHistory: '旅游套餐记录',
        ticketHistory: '票记录',
        logout: '退出',
        cambodian: '柬埔寨',
        cambodia: '柬埔寨',
        nonCambodian: '费柬埔寨',
        thailand: '泰国',
        vietnam: '越南',
        laos: '寮國',
        departingFrom: '出发点',
        goingTo: '前往',
        returnDate: '返回日期',
        findNow: '咨询',
        information: '資訊',
        pleaseSelectDeparture: '請選擇出發地',
        ok: '好的',
        pleaseSelectGoingTo: '請選擇前往',
        flashSale: '闪售',
        applyPeriod: '申请期限',
        to: '至',
        from: '从',
        modify: '調整',
        book: '预订',
        bookmore: 'Book more',
        incaseyoustillhavenotreceivedyoureticketpleasecontactusvia: '如果您仍未收到电子邮件。 请通过以下方式联系我们',
        fullnameRequired: '请输入全名',
        pleaseSelectNationality: 'Please Select Nationality',
        
        PaymentKHQR: 'Payment KHQR',
        username: '用户名称',
        name: '姓名',
        enterPassword1: '密码',
        code: 'Code',
        phoneNumber: '电话号码',
        summaryPayment: '总付款',
        creditDebitCard: 'CREDIT CARD/DEBIT CARD',
        privacy: '隐私',
        policy:'政策',
        copy: '复制',
        copied: '已複製',
        pleasechooseaphoto: '请选择一张照片',
        useraccount: '用户帐号',
        downstair: '下层',
        upstair: '上层',
        captain: '船长',
        toilet: '洗手间',
        hostess: '女主人',
        door: '门',
        // hostess: '女主人',
        // capitain: '船长',
        
       
        booked: '已预订',
        whyYouChooseUs: '为何选择我们',
        DesWhyYouChooseUs: '我们的班车时刻表覆盖了柬埔寨的主要目的地，也运营前往曼谷的班车和胡志明',
        complimentary: '免费',
        desComplimentary: '我们在车上提供一瓶饮用水',
        liabilityInsurance: '责任保险',
        desLiabilityInsurance: '我们所有的车队都投保了国内和国际车路线的责任保险。',
        powerUsbCharger: '电源和USB充电器',
        desPowerUsbCharger: '我们所有的车队都配备独立电源插座或 USB 充电器，以确保您的设备电池电量充足旅途中已充满电。',
        customerService: '客服热线',
        desCustomerService: '我们24 小时客服中心代表已准备好回答您与您的旅行相关的所有问题（高棉语）＆ 英语会话。',
        airConditioned: '空调',
        desAirConditioned: '柬埔寨是个炎热的国家，空调总是由我们专业的室内人员检查机械师确认我们不会影响您的旅行。',
        paymentTicket: '付款及票',
        desPaymentTicket: '您可以通过多种支付方式购买我们的车票并实时支付，我们的电子车票将发送到您的邮件。',
        comfortableSeat: '舒适的座椅',
        desComfortableSeat: '一般来说，巴士最多配备 17 个座位，但我们希望您拥有更多空间，我们拆除了 6 个座位，以获得足够的空间并可倾斜。',
        gpsTrackingSystem: 'GPS追踪系统',
        desGpsTrackingSystem: '為了確保我們的司機按照限速駕駛巴士，我們的全職追蹤人員正在監控我們所有的車隊。',
        schedule: '日程',
        desSchedule: '我们在柬埔寨处于领先地位，各个城市的日程安排多种多样巴士。柬埔寨、曼谷、胡志明，方便您规划行程。',
        ourDriver: '我们的驾驶员',
        desOurDriver: '我们的司机是由我们的人力资源团队专业招募和培训的，以确保我们的司机状态很好，可以安全驾驶。',
        exporeTrendingPlace: '探索热门地点',
        localRoute: '本地路线-柬埔寨',
        internationalRoute: '国际路线-泰国和越南',
        hqAddress: '总部地址',
        email: '邮件',
        tel: '电话',
        followUs: '关注我们',
        weAccepted: '我们已接受',
        termCondition: '条款和政策',
        privacyPolicy: '隐私政策',
        theSubsidiaryof: '旗下公司',
        vireakbunthamgroup: 'VET 集团之',
        safeSpeedySpeedboatService: '安全、可靠快艇服务 ',
        LuxuryTravelTopAmenitiesService: '奢华之旅，卓越的设施与服务',
        LuxuryResortsPremiumExperiences: '豪华度假村，尽享极致体验',
        EasyOnlineShopping25Provinces: '轻松网购，配送覆盖25省份',
        chooselanguage: '语言选择',
        experience: '经验',
        vetExpressMobileApp: 'VET Express 移动应用程序',
        or: '或者',

        // travel package
        travelPackage: '旅游套餐',
        desTravelPackage: '只需一次性付款即可无限次行程。我们的行程套餐提供豪华、安全、多样化的目的地、可定制的选项以及令人难忘的优质服务。',
        buyNow: '立即购买',
        vehicleRental: '车辆租赁',
        desVehicleRental: 'VET 汽车租赁公司在柬埔寨提供优质的汽车租赁和包车服务，并提供 VIP 签证协助和专业司机以确保无缝旅行。',
        bookNow: '现在预订',
        please_p : '请上传清晰的图片',
        pleaseinputyourname: '请输入您的姓名',
        emailaddressrequired: '请务必输入电话号码',

        galleryVetExpress: 'VET快递相册',
        hotelBus: '酒店巴士',
        luxuryCoaster: '豪华中巴',
        luxuryHotelBus: '豪华酒店巴士',
        luxuryVanH350: '豪华小巴H350',
        luxuryVanHiace: '豪华小巴海狮',
        emailRequired: 'Email address is required',
        phoneNumberMustStartWithZero: 'Phone number must start with 0',
        phoneNumberMustBe9or10Digits: 'Phone number must be 9 or 10 digits',
        pleaseProvideEmailOrPhone: 'Please provide email or phone number',


        tubeResort: '管式度假酒店（TUBE RESORT)',
        desTubeResort: '管式度假酒店（Tube Resort）面向海滨，提供位于高龙撒冷岛（Koh Rong Sanloem）的四星级住宿，配有室外游泳池、花园和餐厅。这家四星级酒店的每间住宿均享有花园景，客人可以使用酒吧和餐厅。 住宿提供24小时前台、机场交通服务、客房服务以及免费WiFi。 度假酒店的空调客房配有书桌、水壶、冰箱、迷你吧、保险箱、平板电视、露台以及带淋浴的私人浴室。 管式度假酒店的部分客房享有海景，客房配有阳台。 这家住宿的每间客房都配有床上用品和毛巾。',
        sandyBeachBungalows: '闪迪平房度假酒店（Sandy Beach Bungalows)',
        desSandyBeachBungalows: '闪迪平房度假酒店（Sandy Beach Bungalows） 是一家安静的海滨酒店，位于 高龙撒冷岛，距离西哈努克城 20 公里。度假酒店拥有私人海滩区和水上运动设施。',
        kohSdachResort: '王岛度假酒店',
        desKohSdachResort: `王岛度假酒店（Koh Sdach Resort）位于王岛，配有花园、露台、餐厅和酒吧。
        这家四星级酒店提供私人海滩区和礼宾服务。
        住宿为客人提供客房服务、24小时前台和外币兑换服务。`,
        edenBeachResort: '伊登沙滩度假酒店（Eden Beach Resort）',
        desEdenBeachResort: `伊登沙滩度假酒店（Eden Beach Resort）面向海滨，提供位于高龙撒冷岛（Koh Rong Sanloem）的四星级住宿，设有室外游泳池、花园和餐厅。
        这家四星级酒店提供免费WiFi、客房服务和24小时前台。
        住宿为客人提供礼宾服务、旅游咨询台和外币兑换服务。 酒店为客人提供空调客房，配有书桌、保险箱、平板电视、露台以及带淋浴的私人浴室。伊登沙滩度假酒店的部分客房享有海景，每间客房都配备带阳台，住宿的客房均配有休息区。`,
        sarasea: '沙拉海滩度假酒店（Sarasea Resort)',
        desSarasea: `沙拉海滩度假酒店（SARASEA Koh Rong Samloem）面向海滨，位于高龙撒冷岛（Koh Rong Sanloem），提供三星级住宿，配有花园、露台和酒吧。这家三星级度假酒店拥有私人海滩区，提供带免费WiFi的空调客房。 住宿为客人提供24小时前台、礼宾服务和外币兑换服务。这家度假酒店的每间客房都配有庭院。沙拉度假酒店的部分客房享有海景。客房配有阳台。 这家住宿的每间客房都配备床上用品和毛巾。`,
        bodegaResort: '宝迪佳度假酒店（One Beach Resort)',
        desBodegaResort: `如果您想在热带海滩度过轻松的时光，那么宝迪佳度假酒店可以满足您的一切需求！我们度假酒店的前面是美丽而幽静的沙滩。在这里，您可以在绵延 1.2 公里的沙滩上享受放松。 海滩俯瞰大海，酒店后面是未受破坏的丛林。`,
        pidomaResort: '比多玛度假酒店（PIDOMA RESORT)',
        desPidomaResort: `比多玛度假酒店（Pidoma Resort）位于森莫诺隆（Sen Monorom），拥有室外游泳池、健身中心、花园和露台。这家五星级度假村拥有餐厅，提供带免费WiFi和私人浴室的空调客房。住宿提供客房服务，比多玛度假酒店的客房配有24小时前台、外币兑换服务、书桌、床单、山景阳台、带坐浴盆和免费洗浴用品的共用浴室以及带坐浴盆的共用浴室。 享有城市景观。客房为客人提供衣柜和水壶。`,
        romdoulKiriromResort: '隆都基里隆度假酒店（ROMDOUL KIRIROM RESORT)',
        desRomdoulKiriromResort: `隆都基里隆度假酒店（Romdoul Kirirom） 是基里隆国家公园内新建的四星级山顶度假酒店。该地区海拔 800-850m，提供新鲜而独特的体验。独立式平房隐秘地坐落在小豆蔻山脉的壮丽景色中。 松树林和洁净的山间空气让您的感官焕发活力。度假酒店设施包括带阳光甲板和日光浴床的大型游泳池、供应鸡尾酒和小吃的池畔酒吧。度假酒店还设有大型山景餐厅，供应亚洲和国际美食和饮料。
        酒店内还设有咖啡店、中国茶店、面包店和迷你超市。 有扩建计划，其中包括健身中心、树屋空中酒吧和会议室。`,
        kohApikjunResort: '贵人岛度假酒店（Koh Apikjun resort）',
        desKohApikjunResort: `一个宏伟的岛屿避风港，隐藏在令人惊叹的自然环境中。位于戈公省以南 8 公里处，就是您的目的地。贵人岛度假酒店是一个完美的世外桃源，我们提供码头边的水上别墅，配有私人游泳池和特殊的特写可以欣赏到生动的碧绿大海。我们还拥有豪华、宽敞的陆上住宿，是与家人一起享受私人度假的理想选择。`,


        service: '服务',
        vehicleRentalInCambodiaThailandVietnam: '车辆租赁：柬埔寨，越南，泰国',
        desVehicleRentalInCambodiaThailandVietnamLess: `VET航空巴士租赁在全国范围内提供优质的车队租赁服务，并根据行程要求提供安全可靠的服务。我们还提供从柬埔寨到曼谷和胡志明市的包车服务。车队有多种类型，如豪华的小巴、中巴、大巴。`,
        desVehicleRentalInCambodiaThailandVietnamMore: `VET航空巴士租赁在全国范围内提供优质的车队租赁服务，并根据行程要求提供安全可靠的服务。我们还提供从柬埔寨到曼谷和胡志明市的包机服务。车队有多种类型，如豪华小巴、中巴和小巴。泰国和越南边境流程都比较方便，我们在边境有工作人员办理出入境签证盖章的 VIP 服务，包含在包车费用中。`,
        seeLess: '简阅',
        seeMore: '查看更多',
        askInfoOrBookVia: '询问信息或通过以下方式预订',
        hyundaiUniverseNoble: '现代宇宙',
        premiumHyundaiSolatiH350: '高级现代索拉蒂 H350',
        classicHyundaiSolatiH350: '经典现代索拉蒂 H350',
        luxuryHyundaiH1: '豪华现代H1',
        classicHyundaiH1: '经典现代 H1',
        hyundaiCounty: '现代中巴',
        seatingCapcity: '座位数：最多 41 名乘客',
        seatPlan: '座位安排：2 + 2',
        model: 'Model: Hyundai Universe Noble',
        production: 'Production Year: 2010',
        gpsTracking: 'GPS Tracking System: Yes',
        restroom: 'Restroom: Yes',
        refrigeator: 'Refrigerator: Yes',
        powerOutlet: 'Power Outlet: Yes',
        wifiVR: 'Wi-Fi: Yes',
        soundSystem: 'Sound System: Yes',
        drivingLicense: 'Driving License: D4',
        insuranceVR: 'Insurance: Passenger Liability',
        usbCharger: 'USB Charger: Yes',
        reservationNow: '立即预订...',
        Phonenumber: '电话号码',
        fareSummary: '票价汇总',
        totalAmount: '总金额',
        showless: '显示较少',
        serviceFee: '服务费',

        bookingDetail: '预订内容',
        // fullName: '全姓名',
        telephoneNumber: '电话号码',
        journeyType: '行程类型',
        oneWay: '单程',
        roundTrip: '往返程',
        trip: '旅游',
        destination: '目的地',
        selectDeparture: '选择出发地',
        selectArrival: '选择目的地',
        departureDate: '出发日期',
        selectDepartureDate: '选择出发地日期',
        selectReturnDate: '选择返回日期',
        amountOfCar: '车辆数量',
        remark: '备注',
        save: '保存',
        vehicleTyle: '车辆类型',
        skip: '跳过',
        companyProfile: '公司简介',
        enabling: '实现',
        KeySuccess: '关键成功',
        vetBackground: 'VET背景',
        desVetBackground1: `Vireak Buntham Express Co., Ltd (VET) 是一家领先的客运和物流公司，在柬埔寨各地设有多个办事处，由 Neak Oknha Suo Vireak 于 2004 年创立，并于 2006 年在戈公省注册为公司。 VET 提供穿越柬埔寨、泰国和越南的客运和物流。VET 利用现代技术提供卓越的服务。`,
        desVetBackground2: `VET 从戈公省的几辆卧铺巴士和货车开始，只有很少的工作人员，然后 VET 投资了豪华酒店巴士、用于客运的丰田海狮和货车，用于物流以定制客户的需求。`,
        desVetBackground3: `2020 年和 2021 年是 VET 历史性的扩张年，作为柬埔寨顶级领先的物流公司，即使 VET 面临新冠肺炎 (covid 19) 疫情，其转型也非常迅速。我们相信客户的成功将帮助我们取得成功。我们的价值观反映了 高层管理人员的想法就是将客户的利益置于我们自己的利益之上。`,
        desVetBackground4: `随着客运服务在大流行后恢复，2022 年标志着 VET Travel 的凯旋。VET 继续其雄心勃勃的扩张，扩大了分支机构网络，并投资建设了宏伟的新总部，以确保员工的舒适和福祉。`,
        desVetBackground5: `致力于可持续发展，VET 已于 2024 年开始推出电动卡车，以降低运营成本。展望未来，该公司的目标是将金边和暹粒与老挝连接起来，扩大其区域覆盖范围，并进一步巩固其作为交通先驱的传统 和物流。`,
        readMore: '阅读更多',
        neakOknhaSuoVireak: 'Neak Oknha Suo Vireak',
        founderChairmanCEO: '创始人、董事长兼首席执行官',

        vetMilestone: '职业教育里程碑',
        desVetMilestone: 'Vireak Bustham Express Co., Ltd (VET) 是一家领先的物流和客运公司，柬埔寨的运输公司已卓有成效地发展为以下期间',
        vetMilestone2024: '2024年',
        vetMilestone2024List1: '2024年',
        vetMilestone2024List2: '开始使用电动卡车',
        vetMilestone2023: '2023年',
        vetMilestone2023List1: '支行强化之年',
        vetMilestone2023List2: '扩大特许经营权之年',
        vetMilestone2022: '2022年-特许经营年',
        vetMilestone2022List1: '向代理机构出售特许经营权',
        vetMilestone2022List2: '启动新的 VET 航空巴士',
        vetMilestone2022List3: 'VET 车辆出租服务',
        vetMilestone2021: '2021年——历史性的一年——D2D之年',
        vetMilestone2021List1: '分支机构和代理机构扩张',
        vetMilestone2021List2: '卡车投资',
        vetMilestone2021List3: '改进B2B、D2D、COD',
        vetMilestone2020: '2020 年 - Covid 19 主机年',
        vetMilestone2020List1: '区、乡设立货物转运机构',
        vetMilestone2020List2: '新服务：D2D、B2B 和 COD',
        vetMilestone2019: '2019年-应用在线系统',
        vetMilestone2019List1: '票务及物流在线系统',
        vetMilestone2019List2: 'covid 19 之年',
        vetMilestone2018: '2018年——扩张之年',
        vetMilestone2018List1: '客运投资',
        vetMilestone2018List2: '酒店巴士投资',
        vetMilestone2018List3: '卡车投资',
        vetMilestone2017: '2017年——扩张之年',
        vetMilestone2017List1: '客运投资',
        vetMilestone2017List2: '酒店巴士投资',
        vetMilestone2017List3: '卡车投资',
        vetMilestone2016: '2016年',
        vetMilestone2016List1: '软件开发年份（离线系统）',
        vetMilestone2016List2: '开发物流软件和票务软件',
        vetMilestone2015: '2015年',
        vetMilestone2015List1: '物流之年',
        vetMilestone2015List2: '开始物流',
        vetMilestone2015List3: '卡车投资',
        vetMilestone2015List4: '储藏投资',
        vetMilestone2014: '2014年',
        vetMilestone2014List1: '白天客运投资',
        vetMilestone2014List2: '丰田海狮',
        vetMilestone2014List3: '卧铺巴士',
        vetMilestone2014List4: '酒店巴士',
        vetMilestone2014List5: '豪华巴士',
        vetMilestone2010: '2010年',
        vetMilestone2010List1: '夜班巴士',
        vetMilestone2010List2: '从 VCD 租巴士',
        vetMilestone2010List3: '前往金边直达路线',
        vetMilestone2010List4: '西港，暹粒，马德望，波贝',
        vetMilestone2006: '2006年',
        vetMilestone2006List1: '商业登记',
        vetMilestone2006List2: '6车辆',
        vetMilestone2006List3: '戈公省-西哈努克',
        vetMilestone2004: '2004年',
        vetMilestone2004List1: '生意开始了',
        vetMilestone2004List2: '戈公省',
        vetMilestone2004List3: '3车辆',


        visionMission: '愿景和使命',
        vetVisionMission: 'VET愿景和使命',
        desVisionMision: '基于我们与王国政府共同参与交通运输领域的坚定愿景，我们致力于提供安全、可靠、高效、环保、让用户和运营商都满意的出行和物流服务的最佳交通系统。',
        ourSlogan: '我们的口号',
        ourSloganList1: '任何时间、任何地点都更安全、更快捷！',
        ourSloganList2: '您的安全是我们的生命，您的财产是我们的财富！',
        ourSloganList3: '您业务的成长是我们的承诺！',
        vision: '通过运用洞察力、服务质量和创新，成为亚洲最好的运输公司，为企业和社会创造可持续增长。',
        mission: '将人们、企业和社区联系起来，共创更美好的未来，物流与出行',
        coreValue1: '链接的',
        coreValue2: '坚定的',
        coreValue3: '有创造力的',
        coreValue4: '相信',

        messageFromChairmanCeo: '董事长兼首席执行官致辞',
        messageFrom: '消息来自',
        chairmanCeo: '董事长兼首席执行官',
        desMessageFromChairmanCeo: 'Vireak Buntham Express 的成立是基于提供最高品质的定制、创新、端到端物流和旅行解决方案的独特愿景。正是怀着这一愿景，VET 团队的每位成员都致力于日常工作。',
        desMessageFromChairmanCeoP: `Vireak Buntham Express 的成立是基于提供最高品质的定制、创新、端到端物流和旅行解决方案的独特愿景。正是怀着这一愿景，VET 团队的每位成员都致力于日常工作。<br/> 我为分配给我们的每一个项目感到自豪，并支持我们团队的贡献，为您的所有成功提供支持。 我坚信，正是我们超越客户期望的热情，使我们成为了当今世界排名的物流服务提供商。<br /> 在我们多年的发展过程中，VET Express充分利用先进技术， 在陆路运输、仓储和供应链管理服务方面的丰富经验使 VET Express 从我们的竞争对手中脱颖而出。这些特点使我们在提供‘一流’集成供应链解决方案以及无缝和透明运营方面具有显着的竞争优势。`,
        desMessageFromChairmanCeoP1: `有了 VET Express 作为您的合作伙伴，您可以放心，即使是最复杂的物流计划也能交到良好的手中 - 相信我们将通过解决方案积极处理最复杂的情况，确保您的包裹安全准时到达目的地。<br />感谢所有信任我们的人。 我相信我们的美好未来充满机遇和挑战，VET Express将不断兑现我们的承诺，努力以诚信和专业的态度行事。 您可以随时随地期待我们提供始终如一的高质量物流解决方案。`,
        desMessageFromChairmanCeoP2: '此致，',
        desMessageFromChairmanCeoP3: 'Neak Oknha. Suo Vireak',
        desMessageFromChairmanCeoP4: '董事长兼首席执行官',

        vetCorporationCoreValues: 'VET公司核心价值观',
        coreValues: '核心价值',
        desVetCorporationCoreValues: `VET 的核心价值观是关于其愿景、使命和目标的明确规定的原则。
        这样，每个员工都围绕一个指导理念来服务、客户和更广泛的社区。`,
        vetCorporationCoreValuesConnected: `互联：`,
        vetCorporationCoreValuesConnectedP: `我们投资于洞察力，以了解客户挑战的核心。我们的工作方式公开透明。`,
        vetCorporationCoreValuesCommitted: '承诺：',
        vetCorporationCoreValuesCommittedP: `深入参与建立关系，我们所做的一切都着眼于长远。我们对质量的奉献是我们成功的基石，我们把每一个细节都做好。`,
        vetCorporationCoreValuesCreative: '创意：',
        vetCorporationCoreValuesCreativeP: `我们不断开发更好的工作方式。如果我们找到更好的方式，我们就会采取行动并分享。`,
        vetCorporationCoreValuesTrust: `信任：`,
        vetCorporationCoreValuesTrustP: `信任就是一切，我们知道它有多么珍贵，也有多么脆弱。因此，我们采取的每一项行动都是为了建立和加强客户对我们的信任。我们相信维持客户信任的方法 就是在每一次互动中超出他们的预期。`,

        vetProductServices: '职业教育与培训产品和服务',
        desVetProductServices: '从 2004 年至今，Vireak BunTham Express Co., Ltd 每年都在扩大其服务范围，并针对市场需求和寻求长远的管理愿景取得了许多成果，今天我们提供的所有服务包括：',
        vetTravelPassengerTransprtationService: '1. VET 旅行：客运服务',
        luxuryHotelBusPageService: '豪华酒店巴士：',
        luxuryHotelBusPageServiceP: `是每晚发车的夜间巴士。有双人床，单人床、枕头、毯子、充电器和卫生间”`,
        vipVanToyotaHiace: 'VIP 丰田海狮、现代 H350、郡县、H1：',
        vipVanToyotaHiaceP: `有日间小巴士 配有宽大的沙发座位，从 07 座到 17 座。`,
        vetAirBusPageService: 'VET航空巴士：',
        vetAirBusPageServiceP: `豪华巴士，有沙发座位，有wifi、零食、咖啡机、里面有私人平板电脑和厕所。`,
        busRentalService: '巴士租赁服务：',
        busRentalServiceP: `VET 提供租赁服务，例如旅游和任何旅行，费用合理。`,
        allPassengerAre: '所有乘客都是我们的责任，他们都得到照顾和保险。',
        AllpassengerAreOur: '所有乘客都是我们的责任，他们都得到照顾和保险。',
        whereCaWeBuyATicket: '我们在哪里可以买票？',
        theNearbyBranche: '附近的分行',
        HotlinePhoneNumber: '订购热线081911911 或者',
        hotline: '联系热线',
        appBookingName: '应用程序预订：VET Express，或者',
        websiteName: '前往 www.vireakbuntham.com',
        vetLogistic: '2. VET快递',
        vetExpressServiceToanChet: 'VET EXPRESS SERVICES（闪送服务）：',
        vetExpressServiceToanChetP: `通过以下方式提供货物转运服务 全国25个省、市、区/县、镇、农村不超过12小时。`,
        vetNormalService: 'VET 正常服务：',
        vetNormalServiceP: `全国各地的货物转运服务：全国25个省市、区/县、镇、农村不超过24小时。`,
        goodsTransferFreezerTruck: '冷冻车中的货物转运：',
        goodsTransferFreezerTruckP: '帮助保持蔬菜、鱼、肉、海鲜的质量，以保持良好的质量。',
        deliveryService: 'D2D、D2B 和 B2B 送貨服務：',
        deliveryServiceP: '送貨員會從您所在的地點提貨，並運送給您跨越25個省市的客戶或合作夥伴，其中也包括泰國。',
        cashOnDelivery: '货到付款（COD）：',
        cashOnDeliveryP: '送货员和工作人员将代替您将货物送到您的客户所在地并支付费用，然后在不超过 2 天的时间内将款项转回给您。',
        insuredGoodTransfer: '保价货物运输：',
        insuredGoodTransferP: '所有保价货物转运，如有损坏或丢失，均承担赔偿责任。本公司有额外100%的保险服务，发生损坏或丢失时，按货物价格的1%收取费用。',
        GoodsTransferCrossingBorder: '跨境货物转运：',
        GoodsTransferCrossingBorderP: '货物从泰国和越南转运至柬埔寨25个省市不超过48小时。',

        vetMembershipCard: 'VET会员卡',
        vetMembershipCard1: '注册成为会员卡可享受相互折扣率。 （例如：5%、10%）',
        vetMembershipCard2: '注册成为会员卡可享受接送套餐5%至20%的折扣',
        vetMembershipCard3: '注册会员卡可享折扣，10个包裹，免费1个包裹。',
        note: '注明',
        noted: '已注明',
        vetMembershipNote1: `第一和第二：折扣金额将是您账户中的积蓄，折扣金额将在月底计算并返还至您的账户。
        下个月的转账将从您的账户中扣除，直至余额不足。
        这个储蓄账户也可以支付巴士票和船票。`,
        vetMembershipNote2: '享受VIP服务，获得特别关注',
        howRegisterMembership: '如何注册成为会员？',
        howRegisterMembership1: '请联系电话：010 522 522',
        howRegisterMembership2: 'Facebook 页面：Virak Buntham Express Tour & Travel',
        howRegisterMembership3: '您每月快递件的包裹超过300个，我们的客服中心将直接与您联系。',
        howRegisterMembership4: '您每月快递件的包裹超过300个，我们的客服中心将直接与您联系。',

        whyPartnerWithUs: '为什么与我们合作？',
        saveTime: '省时间、省钱，不超过12小时',
        saveTime1: '客户服务',
        saveTime2: '24/7 服务',
        saveTime3: '准时响应',
        saveTime4: '准时交货',
        saveTime5: '会员资格',
        saveTime6: '折扣率',
        competitiveRate: '具有竞争力的价格和更低的成本',
        competitiveRate1: '创新',
        competitiveRate2: '质量保证',
        competitiveRate3: '持续改进',
        provincialCity25: '25个省市、854个分支机构遍布柬埔寨',
        provincialCity25List1: '灵活性：电话预订、送货、COD、D2D',
        provincialCity25List2: '付费/未付费',
        provincialCity25List3: '随时随地',
        safetyCerification: '安全认证、保险、赔偿',
        safetyCerificationList1: '技术：每笔交易都在技术上、按时进行',
        safetyCerificationList2: '随时随地通过手机准时跟踪您的转账',

        vetVehicletype: 'VET车辆类型',
        vetVehicletype1: '巴士',
        vetVehicletype2: '卡车',
        vetVehicletype3: '快艇',

        vetSubsidiaries: 'VET分店',
        vetSubsidiaries1: '1. Neak Oknha Suo Vireak创立的度假酒店',
        vetSubsidiaries2: '2. Neak Oknha Suo Vireak 创立的其他公司',


        crossBorderInformationOrocedure: '跨境信息和程序',
        crossBorderTitle1: '从柬埔寨进入越南：',
        crossBorderText1: `如果签证没有打印出来，申请越南电子签证的乘客将需要额外支付1美元；这笔费用包括在移民局打印的费用。
        额外收费导致乘客感到困惑，但我们向您保证这不是骗局。 支付 1 美元费用的顾客可以享用午餐、放松或看电影。 或者，他们也可以与我们的员工一起完成交易。`,
        
        crossBorderTitle2: 'VIP跨境服务流程：',
        crossBorderText2: `我们的 VIP 服务确保快速的入境流程。利用我们与海关官员建立的关系，我们保证提供优先服务，提高效率。虽然我们无法绕过队列，但我们确保为乘客提供简化的流程。`,
        
        crossBorderTitle3: '過境',
        crossBorderText3: `申请签证和过境可能会让人感到困惑，尤其是对于初次旅行者而言。乘坐 VET Air Express 旅行的主要好处之一就是让您的旅行无忧无虑。当然，过境也有其自身的纠葛。
        为了让您的旅行毫无压力，当您在巴士上放松或看电影或在巴士上享用我们的午餐套餐时，我们的工作人员会在幕后工作，以确保您顺利过境。`,

        crossBorderTitle4: 'Phnom Penh Capital to Ho Chi Minh City and Vice Versa Trip Procedure',
        crossBorderText4: `在出发站，乘客的护照将被扫描并发送给边境的 VET Air Bus Express 工作人员，以预先填写出入境表格。两国之间的入境过境点位于 Bavet / Moc Bai。许多国籍须在进入越南和柬埔寨之前申请签证，部分欧洲和亚洲国家除外（见下文附录）。一些护照持有者在抵达时收到签证。乘客有责任在预订旅程之前进行检查 。`,
        crossBorderText41: `在到达边境之前，工作人员将收集旅客的护照，并与海关官员一起办理出境签证盖章和入境盖章。`,

        crossBorderTitle5: '边境流程',
        crossBorderText5: `巴士到达边境后，乘客需要下车并跟随工作人员前往移民局。VET Air Bus Express 票价包含 VIP 服务，确保乘客尽快通过移民局。过境时间变更取决于过境的人数。`,
        crossBorderText51: `旅客须先经原籍国移民局出境，然后乘坐大巴短途到达目的国海关正式入境。
        乘客将被要求将行李从巴士上取下，由目的地国家的海关官员扫描，然后再返回巴士。`,

        crossBorderTitle6: '金边首都至曼谷市及反之行程程序',
        crossBorderText6: `在出发站，乘客的护照将被扫描并发送给边境的 VET Air Bus Express 工作人员，以预先填写出入境表格。两国之间入境的过境点位于波贝/亚兰。大部分旅客除东盟国家外，所有国籍的人士均须在进入柬埔寨之前申请签证。所有护照持有者均可在抵达时获得签证。
        对于进入泰国，大多数国家都是免签证的，但某些国家在进入泰国之前需要申请签证（见下面的附录）。
        乘客有责任在预订旅程之前进行检查。`,
        crossBorderText61: `在到达边境之前，工作人员将收集旅客的护照，并与海关官员一起办理出境签证盖章和入境盖章。`,

        crossBorderTitle7: '边境流程',
        crossBorderText7: `巴士到达边境后，乘客需要下车并跟随工作人员前往移民局。VET Air Bus Express 票价包含 VIP 服务，确保乘客尽快通过移民局。过境时间变更取决于过境的人数。`,
        crossBorderText71: `旅客须先经原籍国移民局出境，然后乘坐大巴短途到达目的国海关正式入境。
        乘客将被要求将行李从巴士上取下，由目的地国家的海关官员扫描，然后再返回巴士。`,

        crossBorderTitle8: '仅适用于从越南进入柬埔寨',
        crossBorderText8: `想要办理落地签证的乘客需要额外支付10美元，可以在巴士上放松或看电影或吃午餐/晚餐。否则，他们可以跟随工作人员自行办理手续。`,

        crossBorderTitle9: '仅限从泰国进入柬埔寨',
        crossBorderText9: `希望办理落地签证的乘客需额外支付200泰铢，可以在巴士上放松或看电影或享用午餐/晚餐。否则，他们可以跟随工作人员自行办理交易，需要3张照片4 x6 和另一个支持文件。`,

        crossBorderTitle10: '*备注',
        crossBorderText10: `额外费用引起了乘客对 10 美元/200泰铢费用的质疑。我们想让乘客放心，这不是一个骗局。
        请注意，支付 US$10/200泰铢的顾客可以享受午餐或看电影或放松。 或者，他们也可以与我们的员工一起去自己进行交易。`,

        crossBorderTitle11: '附录：',
        crossBorderTitle111: '持有越南免签证的国家：',
        crossBorderText11: `A：13个国家的公民可享受在越南停留15天的免旅游签证，包括德国、法国、意大利、西班牙、英国、北爱尔兰、俄罗斯、日本、韩国、丹麦、瑞典、挪威、 芬兰和白俄罗斯。”`,
        crossBorderText111: `B. 10个东盟国家可免签证在越南停留1个月：柬埔寨、老挝、马来西亚、印度尼西亚、菲律宾、泰国、文莱达鲁萨兰国、新加坡、东帝汶和缅甸。`,

        crossBorderTitle12: '需要泰国签证的国籍：',
        crossBorderText12: '18个国家需要签证才能进入泰国，如保加利亚、不丹、中国、塞浦路斯、斐济、格鲁吉亚、印度、哈萨克斯坦、马耳他、墨西哥、瑙鲁、巴布亚新几内亚、罗马尼亚、沙特阿拉伯、台湾、乌兹别克斯坦、瓦努阿图和缅甸 。',
        crossBorderText121: `除了缅甸需要在上车前获得签证外，所有国籍都可以获得落地签证。`,


        loginAccount: '登录账户',
        pleaseEnterYourCredentialToLogin: '请输入您的凭据进行登录！',
        pleaseEnterYourCredentialToRegister: '请输入您的凭据进行注册',
        emailTelephoneNumber: '邮件/电话号码',
        enterPassword: '输入密码',
        forgotPassword: '忘记密码？',
        donotHaveAccountRegister: `还没有账号？`,
        newPassword: '新密码',
        confirmPassword: '确认密码',
        enterFullName: '输入全名',
        nationality: '国籍',
        selectNationality: '选择国籍',
        enterTelephoneNumber: '输入电话号码',
        gender: '性别',
        selectGender: '选择性别',
        male: '男',
        Female: '女',
        genderisRequired: '性别是必填的',
        pleaseEnterYourEmailTelephoneNumber: '请输入邮箱/电话号码',
        myProfile: '个人档案',
        personalInformation: '个人信息',
        emailAddress: '邮件地址',
        emailInvalid: '邮件地址不正确',
        edit: '编辑',
        address: '地址',
        dateOfBirth: '出生日期',
        accountTicket: '账户票',
        generalAccount: '普通账户',
        condition: '状况',
        editProfile: '編輯個人資料',
        optional: '选项',
        paymentMethods: '付款方式',
        arrivaldate: '到达日期',
        departurename: '出发地名称',
        arrivaladdress: '到达地址',
        departure: '出发点',
        duration: '期间',
        arrival: '到达',
        boarding: '登车',
        boardingPoint: '登车地点',
        tripInfo: '旅行明细',
        dropOff: '还车地址',
        dropOffPoint: '还车地点',
        seat: '座位',
        seats: '座位',
        direct: '直达',
        borderTransit: '边境过境',
        road: '公路',
        roadNo_: '路号',
        expressWay: '高速',
        roadNumber: '路号',
        moreInformationAboutBus: '关于更多巴士信息',
        amenities: '便利设施',
        crewAttendant: '乘务员',
        dinkingWater: '饮用水',
        gps: 'GPS',
        insurance: '保险',
        lifeJacket: '救生衣',
        
        trainedCaptains: '训练有素的船长',
        trainedDrivers: '训练有素的驾驶员',
        wifi: 'WiFi',
        description: '描述',
        left: '已出发',
        full: '满',
        

        seatAvailable: '空位',
        seatSelected: '已选座位',
        seatUnavilable: '没有座位',
        downStair: '楼下',
        upStair: '楼上',
        vehicleType: '车型',
        price: '价格',
        selectedSeat: '选择座位',
        seatNumber: '座位号',
        totalFare: '总票价',
        continue: '继续',
        next: '下一步',
        accommodation:'住宿地址',
        buvaseacambodai: '布瓦西-快艇',
        airbus: 'VET航空巴士',
        vtenh: 'V订',
        vetgallery: 'VET 相册',
        

        passengerDetail: '乘客明细',
        enterYourfullName: '输入全名',
        location: '定位',
        billingDetail: '结算明细',
        yourBookingDetailticketWillBeSent: '您的预订详情车票将发送至您的电子邮件地址',
        attention: '注意',
        desAttention: '有关卧铺巴士床位的信息是为两人设计的。如果您的床位有性别差异，我们深表歉意，我们会进行更改。',
        desBoardingPointNight: '请确保您选择了正确的登车点，因为有些时刻表的价格不一样。如果您选择了价格较低的登车点而到达价格较高的登车点，我们将收取额外费用。',
        fareDetail: '票价详情',
        direction: '方向',
        departureTime: '出发时间',
        arrivalTime: '到达时间',
        grandTotal: '累计',
        subTotal: '累计',
        discount: '优惠',
        tripsummary: '行程总结',
        free1: '免费1',
        total: '合计',
        totalSummary: '总摘要',
        luckyTicket: '幸运券',
        luckyTicketTitle: '准备好获胜了吗？ 立即获取您的幸运券！',
        desLuckyTicket: '如果您感到幸运，可以通过我们令人兴奋的幸运券将这种运气转化为真正的奖励，请勾选！',
        subDesLuckyTicketAdd: '额外',
        desLuckyTicketSpanBlod:'只需 0.25 美元',
        subDesLuckyTicket: '除了车票价格外，还有机会赢得每月大奖。 访问我们的',
        subDesLuckyTicket1: '了解详情。',
        desLuckyTicketSpan: '脸书页面',
        desLuckyTicket1: '了解详情。',
        applyTravelPackageCode: '申请旅行套餐代码',
        applyCouponCode: '应用优惠券代码',
        counponCode: '优惠券代码',
        enterYourCouponCode: '输入您的优惠券代码',
        apply: '申请',
        desTermCoditionPrivacyPolicy: `点击‘处理付款’即表示您同意`,
        desTermCoditionPrivacyPolicyAnd: '和',
        processPayment: '处理付款',
        travelPackageTitle: '使用行程套餐代码',
      
        close: '关闭',
        codetravel: '代码旅行',
        issuedate: '签发日期',
        exprieddate: "失效日期",
        registerinformation: '注册信息',
        packageinformation: '包装详情',
        confirmPayment: '确认付款',
        uploadphoto: '上传照片',
        camera: '相机',
        informationcustomer: '信息客户',
        pleaseselectgender: '请选择性别',
        Pleaseselectboardingpoint: '请选择乘车地点',
        VETAirBusExpress: 'VET航空巴士',
        VireakBunthamExpressTravel: 'VET巴士',
        areyousureyouwanttoclose: '您确定要关闭吗？',
        selectpoardingpoing: '选择登机点',
        takeasurvey: '反馈意见',
        transationID :'水单编号',
        bookeddate: '订票日期',
        seatno: '座号',
        doyouwanttocancelbooking: '您是否需要取消预订？',


        cancelBooking: '取消预订',
        orderId: '订单号',
        completeYourPaymentbeforetimeout: '在超时之前完成付款',
        numberOfPassenger: '乘客人数',
        paymentSummary: '付款摘要',
        choosePaymentMethod: '选择付款方式',
        paymentMethod: '付款方式',
        payNow: '现在付款',

        abakhqr: 'ABA KHQR',
        desabakhqr: '使用会员银行APP扫码支付',

        wingBank: 'WING银行',
        desWingBank: '通过 Wing Bank 安全支付',

        whyChooseTravelPackage: '为什么选择旅行套餐？',
        desWhyChooseTravelPackageLess: `您是脚部总是痒痒的旅行爱好者吗？别再犹豫了！我们的旅行套餐提供了无与伦比的机会，让您获得难忘的体验。让我们解释一下为什么我们的套餐是您下一次冒险的完美选择：`,
        desWhyChooseTravelPackageMore: `您是脚部总是痒痒的旅行爱好者吗？别再犹豫了！我们的旅行套餐为您提供无与伦比的难忘体验机会。让我们解释一下为什么我们的套餐是您下一次冒险的完美选择：<br /><br /> 性价比高：
        物超所值，提供最优惠的价格。 <br />目的地多样：柬埔寨25个省份、泰国、越南
        <br />放心：确保安全和舒适。<br />定制：在套餐有效期内的任何日期，根据您的喜好定制航空巴士、贵宾厢型车、豪华巴士、租车和快艇。 难忘的经历：持续的记忆一生。<br /><br />立即预订，开始您的梦想冒险！”`,

        bookingId: '预订ID',
        bookingDate: '预订日期',
        paymentType: '付款方式',
        tripDetail: '行程详情',
        ticketCode: '车票代码',
        destinationFrom: '目的地来自',
        travelDate: '旅行日期',
        destinationTo: '目的地至',
        departureAddress: '出发地址',
        dropOffAddress: '还车地址',
        viewMap: '查看地图',
        desResendEmail: `如果您没有通过电子邮件收到电子车票，请点击按钮重新发送您的电子邮件。`,
        rendEmail: '重发电子邮件',
        thaxUrPayment: `谢谢您，您的付款已成功。 确认电子邮件已发送至`,
        paymentComplete: '付款成功',

        yourTicketHasBooked: 'Your Ticket Has Been Booked',
        desYourTicketHasBooked: `Please arrives the boarding station 30 minutes before your booking time. Please check your your booking ticket in option ticketing history`,
        showTicket: 'Show Ticket',

        registerInfo: '注册信息',
        confirmPackage: '确认套餐',
        addImage: '添加图片',
        buyPackage: '购买套餐',
        cancellationPolicy: '取消政策',
        plsShowQRCodeToBranchStaffScan: '请出示二维码给分行工作人员扫描',
        viewQRCode: '查看二维码',
        search: '查询',
        available: '空位',
        selected: '已选座位',
        unavailable: '没有座位',

        // require, not found
        dataNotFound: '未找到数据',
        noSchedule: '无时间表',
        pleaseSelectSeat: '请选择座位',
        yourTelephoneNumberInvalid: '您的电话号码无效',
        yourTelephonNumberRequire: '您的电话号码务必填项',
        yourEmailInvalid: '您的电子邮件无效',
        yourEmailRequire: '您的邮件务必填项',
        yourEmailTelephoneNumberInvalid: '您的电子邮件/电话号码无效',
        yourEmailTelephoneNumberRequired: '您的电子邮件/电话号码为必填项',
        yourGenderInvalid: '您的性别无效',
        yourGenderRequired: '您的性别务必填项',
        yourNationalityInvalid: 'Your nationality is invalid',
        yourNationalityRequired: '您的国籍务必填项',
        yourFullNameRequired: '您的全名务必填项',
        yourPasswordInvalid: '您的密码无效',
        yourPasswordRequired: '您的密码务必填项',
        yourConfirmPasswordNotMatch: '您的确认密码不匹配',
        yourConfirmPasswordRequired: '需要您的确认密码',
        yourEmailOrPasswordIsInvalid: 'Your email or password invalid',
        yourPasswordHaveDigit: '您的密码必须是 4 位数字',
        plsShowUrETicket: '请在售票柜台向我们的工作人员出示您的电子车票以获取实体幸运券',
        codeInvalid: '代码无效',
        desCodeInvalid: '请再次检查优惠券代码！',
        plsSelectDepartingFrom: '请选择出发地',
        plsSlectGoingTo: '请选择前往目的地',

        // terms and condition
        TermCon:"条款和条件",
        tc1:'到达时间',
        tcd1:' 乘客必须在预定出发时间前15分钟到达登车点办理登车手续。',
        tcd2:' 乘客要确保提前或按时到达登车站。如果未能按时到达预定时间或者迟到，恕不退款而已经购买的车票将在巴士出发后失效。',
        tc2: '票务政策',
        tcd3:'已购买的车票不可退款，但可以转换为未来行程的购票券。',
        tcd4:'购票券退款可在出发时间前的最多12小时内进行处理。优惠券的有效期为三个月，且仅可用于同一路线和车辆类型的重新预订一次。',
        tc3: '婴儿、儿童、孕妇和残疾人政策',
        tcd5:'3岁以下的婴儿无需购票，但须由成人陪同并坐在成人大腿上并不打扰邻座乘客。对于婴儿在旅行期间发生的任何伤害，公司不承担任何责任。',
        tcd6:'3岁及以上或身高超过1米的儿童需全价购买自己的座位',
        tcd7:'对于卧铺巴士，婴儿需要购买全价票。',
        tcd8:'27周以上的孕妇或者残疾人士在购票前需提前告知并获得公司的批准，以确保自身安全。本公司对旅行期间发生的任何并发症不承担责任。',
        tc4: '过境政策',
        tcd9:'对于跨境行程，Vireak Buntham Express Travel不对签证入境负责。',
        tcd10:'如果在边境遇到移民拒绝入境的情况下，乘客将不会有资格获得车票退款。',
        tc5: '预订与座位选择',
        tcd11:'乘客可以通过以下方式选择自己喜欢的座位并购买实时车票：',
        tcd12:'预订热线：+855 81 911 911',
        tcd13:'手机App：VET Express',
        tcd14:'网站：www.vireakbuntham.com',
        tcd15:'任何VET车站',
        tc6: '行李政策',
        tcd16:'乘客最多可带 25 公斤的行李和一件随身包。',
        tcd17:'超重行李将根据当地配送费用收取额外费用。',
        tc7: ' 禁带物品',
        tcd18:'以下物品严格禁止携带上车：',
        tcd19:'强烈气味的食物（如榴莲）',
        tcd20:'宠物及其他活体动物',
        tcd21:'各类枪支 ',
        tcd22:'各种类型的毒品、吸烟、电子烟及药物滥用行为',
        tcd23:'走私或非法物品',
        tcd24:'危险、易燃或爆炸物品',
        tc8: '乘客责任',
        tcd25:'乘客对个人物品和贵重物品的安全负责',
        tcd26:'VET 对任何个人物品的丢失或损坏不承担责任。',
        tc9: '酒精政策',
        tcd27:'禁止将酒精带入车辆或在车内饮酒。',
        tcd28:'公司保留在乘客醉酒、不卫生或表现出不当行为、扰乱其他乘客的情况下，要求其下车的权利，并且此类乘客将',
        tc10: '电子票发放',
        tcd29:' 如果您在完成付款后未收到电子票，请点击“重新发送邮件”按钮。',
        tcd30:' 请检查您的垃圾邮件箱。 ',
        tcd31:' 如果仍未收到电子票，请通过电子邮件联系 support@vireakbuntham.com 或拨打我们的热线电话 (+855) 81 911 911。',
        tcd32:'如果未收到电子票，乘客将无法申请车票退款。',


        // privacyPolicy: '隐私政策',
        p1:'Vireak Buntham 完全尊重您的隐私，并认识到需要保护您与我们分享的个人身份信息（任何可以识别您身份的信息，例如姓名、地址和电话号码）。我们向您保证在保护您在我们网站上的隐私时，我们遵循适当的标准。',
        p2:'我们的隐私政策可能随时更改，恕不另行通知。为确保您了解任何更改，请定期查看本政策。访问本网站即表示您同意受本隐私政策的条款和条件的约束。 如果您不同意，请不要使用或访问我们的网站。仅使用本网站，即表示您明确同意我们根据本隐私政策使用和披露您的个人信息。本隐私政策已纳入使用条款并受其约束。',
        p3:'未经用户或客户同意（除非传票、 搜查令或其他法律程序或在用户或其他人即将遭受人身伤害的情况下）',

    }
}