<template>
    <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else class="profile_account">
        <div class="container-fluid" style="">
            <div class="containers">
                <div class="row header-profile dis-screen-lg" style="margin: 0; padding-left: 6px;">
                    <div class="mb-6 header-mypf" style="margin-top: 15px;">
                        <h2 style="color: #253250 !important;">{{ $t('message.myProfile') }}</h2>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 20px;">
                        <div class="row">
                            <!-- <div class="col-lg-1 col-md-1 col-sm-1">

                            </div> -->
                            <div class="col-lg-8 col-md-8 col-sm-8">
                                <div class="row align-center">
                                    <div>
                                        <!-- src="@/assets/profile/profile.svg" -->
                                        <img v-if="profile.filename" :src="getProfileImageUrl(profile.filename)"
                                            alt="Profile Image" class="img_edit">
                                        <img v-else src="@/assets/profile/default-profile.svg" alt="Default Profile"
                                            class="img_edit">
                                    </div>
                                    <div class="ml-3">
                                        <h2>{{ profile.name }}</h2>
                                    </div>
                                </div>
                                <div class="row pt-3 pb-3 align-center justify-space-between">
                                    <div>
                                        <h4>{{ $t('message.personalInformation') }}</h4>
                                    </div>
                                    <div class="d-flex align-center cursor-pointer" @click="EditProfile()">
                                        <div>
                                            <img src="@/assets/profile/edit.svg" alt="">
                                        </div>
                                        <div class="ml-1">
                                            <h3 style="color: #969EAE;"> {{ $t('message.edit') }}</h3>
                                        </div>

                                    </div>
                                </div>
                                <!-- <div class="row header-title">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <p class="header-account">Full Name</p>
                                        <p class="value-row">{{ profile.name }}</p>
                                        <p class="header-account">Nationality</p>
                                        <p class="value-row">{{ profile.nationality }}</p>
                                        <p class="header-account">Telephone Number</p>
                                        <p class="value-row">{{ profile.telephone }}</p>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                        <p class="header-account">Gender</p>
                                        <p class="value-row">{{ profile.gender == 1 ? "Male" : profile.gender == 2 ? "Female" : null }}</p>
                                        <p class="header-account">Email address</p>
                                        <p class="value-row">{{ profile.email }}</p>
                                        <p class="header-account">Date of Birth</p>
                                        <p class="value-row">{{ profile.dob == '' ? "YYYY/MM/DD" : profile.dob  }}</p>
                                       
                                    </div>
                                </div> -->
                                <div class="row header-title">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <template v-if="profile.name">
                                            <p class="header-account">{{ $t('message.fullName') }}</p>
                                            <p class="value-row">{{ profile.name }}</p>
                                        </template>

                                        <template v-if="profile.nationality">
                                            <p class="header-account">{{ $t('message.nationality') }}</p>
                                            <p class="value-row">{{ profile.nationality }}</p>
                                        </template>

                                        <template v-if="profile.telephone">
                                            <p class="header-account">{{ $t('message.telephoneNumber') }}</p>
                                            <p class="value-row">{{ profile.telephone }}</p>
                                        </template>
                                    </div>

                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                        <template v-if="profile.gender">
                                            <p class="header-account">{{ $t('message.gender') }}</p>
                                            <p class="value-row">{{ profile.gender == 1 ? "Male" : profile.gender == 2 ?
                                                "Female" : null }}</p>
                                        </template>

                                        <template v-if="profile.email">
                                            <p class="header-account">{{ $t('message.emailAddress') }}</p>
                                            <p class="value-row">{{ profile.email }}</p>
                                        </template>

                                        <template v-if="profile.dob">
                                            <p class="header-account">{{ $t('message.dateOfBirth') }}</p>
                                            <p class="value-row">{{ profile.dob == '' ? "YYYY/MM/DD" : profile.dob }}
                                            </p>
                                        </template>
                                    </div>
                                </div>

                                <div class="row header-title" style="margin-top: -20px;">
                                    <div class="col-lg-7 col-md-7 col-sm-7" v-if="profile.address">
                                        <p class="header-account">{{ $t('message.address') }}</p>
                                        <span class="value-row" style="font-size: 13px;">
                                            {{ profile.address }}
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="mobile-screen header-profile">
                    <div class="mb-6" style="margin-left: 30px;">
                        <h2 style="color: #253250 !important;">{{ $t('message.myProfile') }}</h2>
                    </div>
                    <div class="d-flex align-center justify-center" style="position: relative;">
                        <img v-if="selectedFile" :src="profile.filename" alt="" class="img_edit"
                            style="width: 140px; height: 140px; border-radius: 50%;">
                        <img v-else src="@/assets/profile/default-profile.svg" alt="" class="img_edit"
                            style="width: 140px; height: 140px; border-radius: 50%;">
                        <div class="edit_icon_image" @click="EditProfile()">
                            <img src="@/assets/profile/edit_icon.svg" alt="">
                        </div>
                    </div>
                    <div>
                        <div class="col-12">
                            <div class="mb-4">
                                <h3>{{ $t('message.personalInformation') }}</h3>
                            </div>
                            <div class="mb-2" v-if="profile.name">
                                <span class="header-account">{{ $t('message.fullName') }}</span>
                            </div>
                            <div class="mb-4" v-if="profile.name">
                                <span class="value-row">{{ profile.name }}</span>
                            </div>
                            <div class="mb-2" v-if="profile.gender">
                                <span class="header-account">{{ $t('message.gender') }}</span>
                            </div>
                            <div class="mb-4" v-if="profile.gender">
                                <span class="value-row">{{ profile.gender == 1 ? "Male" : profile.gender == 2 ? "Female"
                                    : null
                                    }}</span>
                            </div>
                            <div class="mb-2" v-if="profile.nationality">
                                <span class="header-account">{{ $t('message.nationality') }}</span>
                            </div>
                            <div class="mb-4" v-if="profile.nationality">
                                <span class="value-row">{{ profile.nationality }}</span>
                            </div>
                            <div class="mb-2" v-if="profile.email">
                                <span class="header-account">{{ $t('message.emailAddress') }}</span>
                            </div>
                            <div class="mb-4" v-if="profile.email">
                                <span class="value-row">{{ profile.email }}</span>
                            </div>
                            <div class="mb-2" v-if="profile.telephone">
                                <span class="header-account">{{ $t('message.telephoneNumber') }}</span>
                            </div>
                            <div class="mb-4" v-if="profile.telephone">
                                <span class="value-row">{{ profile.telephone }}</span>
                            </div>
                            <div class="mb-2" v-if="profile.dob">
                                <span class="header-account">{{ $t('message.dateOfBirth') }}</span>
                            </div>
                            <div class="mb-4" v-if="profile.dob">
                                <span class="value-row">{{ profile.dob == '' ? "YYYY/MM/DD" : profile.dob }}</span>
                            </div>
                            <div class="mb-2" v-if="profile.address">
                                <span class="header-account">{{ $t('message.address') }}</span>
                            </div>
                            <div class="mb-4" v-if="profile.address">
                                <span class="value-row">{{ profile.address }}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-row justify="center">
            <v-dialog v-model="dialogEditpf" persistent max-width="600px"
                style="z-index: 1002; overflow: hidden; background-color: #FFFFFF !important; border-radius: 10px !important;">
                <v-card style="background-color: #FFFFFF !important; border-radius: 10px !important;">
                    <div class=" container" style="display: block;">
                        <!-- <div class="row d-flex justify-center align-center" style="margin-top:8px; padding-bottom: 7px; position: relative; border-bottom: 2px solid #C6C6C6;">
                            <span class="editpf">Edit Profile</span>
                            <div class="close-x" @click="CloseDialogPf">
                                <img src="@/assets/profile/x.svg" alt="">
                            </div>
                        </div>
                        <div class="row d-flex justify-center align-center" style="margin-top: 35px; position: relative;">
                            <img v-if="selectedFile" :src="imageUrl"   alt=""  class="img_edit">
                            <img v-else src="@/assets/profile/profile.svg" alt="" class="img_edit">
                            <div class="edit_icon"  @click="openFileInput">
                                <input ref="fileInput" type="file" style="display: none;" @change="handleFileChange">
                                <img src="@/assets/profile/edit_icon.svg" alt="">
                            </div>
                        </div> -->
                        <div class="row d-flex justify-center align-center"
                            style="margin-top:8px; padding-bottom: 7px; position: relative; border-bottom: 2px solid #C6C6C6;">
                            <span class="editpf">{{ $t('message.editProfile') }}</span>
                            <div class="close-x" @click="CloseDialogPf">
                                <!-- <img src="@/assets/profile/x.svg" alt="Close"> -->
                                <i class="fas fa-times"></i>
                            </div>
                        </div>

                        <div class="row d-flex justify-center align-center"
                            style="margin-top: 55px; position: relative;">


                            <img :src="profileImage || filename" alt="Profile" class="img_edit" />


                            <div class="edit_icon" @click="openFileInput">
                                <input ref="fileInput" type="file" style="display: none;" @change="handleFileChange"
                                    accept="image/*" />
                                <img src="@/assets/profile/edit_icon.svg" alt="Edit Icon">
                            </div>
                        </div>





                        <div class="d-flex justify-center mt-8" style="padding-bottom: 50px">
                            <div class="set-width-80">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div>
                                            <input type="text" v-model="name" class="custom-input equals_input"
                                                :placeholder="$t('message.fullName')">
                                        </div>
                                        <div class="mt-3">
                                            <!-- <input type="text"  class="custom-input equals_input" placeholder="Nationality" v-model="nationality"> -->
                                            <div class="group-form">
                                                <div class="equals_input" style="position: relative;">
                                                    <i @click="selectNation()"
                                                        class="fa-sharp fa-solid fa-caret-down absoluteicon"
                                                        style="position: absolute; right: 9px; color: #6E6E73;"></i>
                                                    <input @click="selectNation()" @input="filterNations" type="text"
                                                        v-model="nationality" class="custom-input equals_input"
                                                        :placeholder="$t('message.selectNationality')" />

                                                </div>
                                                <div class="equals_input">
                                                    <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px; height: 200px; overflow: auto"
                                                        v-if="showListDataNation" v-click-outside="hidePopupNation">
                                                        <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px; "
                                                            class="hover-color"
                                                            v-for="(nation, i) in filteredNations || listnation"
                                                            :key="i" @click="setNational(nation.id, nation.name)">{{
                                                                nation.name }}</div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <input type="email" class="custom-input-email equals_input-email"
                                                placeholder="Email" v-model="email">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 margin-mobile">
                                        <div>
                                            <div class="group-form">
                                                <div class="equals_input" style="position: relative;">
                                                    <div style="position: relative;">
                                                        <input type="text" @click="selectGender()"
                                                            v-model="selectedOptionGender" readonly class="custom-input"
                                                            placeholder="Select gender" style="cursor: pointer;">
                                                        <i @click="selectGender()"
                                                            style="position: absolute;top: 34%;right: 9px; color: #6E6E73;"
                                                            class="fa-sharp fa-solid fa-caret-down"></i>
                                                    </div>

                                                    <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px;"
                                                        v-if="showListDataGen" v-click-outside="hidePopupGen">
                                                        <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;"
                                                            class="hover-color" @click="setGender(1)">{{ $t('message.male') }}</div>
                                                        <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;"
                                                            class="hover-color" @click="setGender(2)">{{ $t('message.Female') }}</div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <!-- <div class="mx-input-wrapper mt-3">

                                            <date-picker v-model="date" :value-type="'format'" format="YYYY-MM-DD"
                                                placeholder="Date of Birth" class="date-picker-custom"
                                                :popup-style="{ position: 'absolute', top: '410px', zIndex: 9999 }"></date-picker>

                                        </div> -->
                                        <div class="mt-3">
                                            <input type="tel" class="custom-input-phone equals_input-phone"
                                                placeholder="Phone number" v-model="telephone" @input="handlePhoneInput"
                                                maxlength="12">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="d-flex justify-center mt-3">
                            <div class="set-width-80">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div>
                                            <textarea name="" class="custom-input-textarea" id="" placeholder="Address"
                                                v-model="address"></textarea>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div> -->
                        <div class="d-flex justify-center mt-4">
                            <div class="set-width-80">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div @click="updateProfileInfo">
                                            <v-btn style="border-radius: 8px; width:  100%; outline: none;"
                                                color="#DE5D0A" height="40px"
                                                class="text-capitalize btn-width btn-width-mobile reposo-height">
                                                <span style="color: #FFF; font-size: 15px !important;"> {{ $t('message.save') }} </span>
                                            </v-btn>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <v-spacer></v-spacer>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import axios from 'axios';
export default {
    data() {
        return {
            dialogEditpf: false,
            imageUrl: '',
            selectedFile: null,
            profile: [],
            loading: false,
            index: 0,
            showListDataGen: false,
            showListDataNation: false,
            listnation: [],
            filename: '',
            name: '',
            nataionlity: '',
            nationalityid: '',
            filteredNations: [],
            email: '',
            selectedOptionGender: '',
            selectedOptionGenderid: '',
            date: '',
            menu: false,
            telephone: '',
            address: '',
        }
    },
    // components: { DatePicker },
    computed: {
        profileImage() {
            if (this.selectedFile) {
                return URL.createObjectURL(this.selectedFile);
            }
            if (this.filename) {
                return this.filename;
            }
            if (this.imageUrl !== '') {
                return this.imageUrl;
            }
            return require('@/assets/profile/default-profile.svg');

        },
    },

    methods: {

        openDatePicker() {
            this.menu = true; // Open the date picker
            this.$nextTick(() => {
                this.$refs.focusDate.focus(); // Focus on the input after clicking
            });
        },
        closeDatePicker() {
            this.menu = false; // Close the date picker when clicked outside
        },

        openFileInput() {
            this.$refs.fileInput.click();
        },
        async handleFileChange(event) {
            const file = event.target.files[0]; // Get the selected file
            if (file) {
                this.selectedFile = file; // Store the selected file
                await this.uploadFile(); // Trigger the upload
            }
        },

        async uploadFile() {
            const url = this.$upload + 'uploads/uploadPhotoUserProfile';
            let formData = new FormData();
            formData.append('photo', this.selectedFile);
            formData.append('token', this.$token);

            try {
                const response = await axios.post(url, formData, {
                    headers: {
                        ...this.$header,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response) {
                    this.imageUrl = response.data.img;
                    this.filename = this.$UrlImg + response.data.img; // Save uploaded filename
                } else {
                    alert('Upload failed. Please try again.');
                }
                // let showImage= this.$UrlImg +this.imageUrl
            } catch (error) {
                console.error('Upload Error:', error);
                alert('Error uploading file.');
            }
        },


        validatePhoneNumber(phone) {
            // Remove all non-digit characters
            const cleaned = phone.replace(/\D/g, '');
            // Check if the input contains any plus sign (international code)
            if (phone.includes('+')) {
                return false;
            }
            // Check if length is between 8 and 12 digits
            return cleaned.length >= 8 && cleaned.length <= 12;
        },

        handlePhoneInput(event) {
            // Format the phone number as user types
            this.telephone = this.formatPhoneNumber(event.target.value);
        },


        async updateProfileInfo() {
            let session = localStorage.getItem('session_start_login');
            if (session === '') {
                alert('User does not login!');
                return;
            }

            if (this.name === '') {
                alert("Please input your name");
                return;
            }

            // Remove telephone validation
            // if (!this.telephone) {
            //     alert("Please input telephone number");
            //     return;
            // }

            // Only validate phone number if one is provided
            if (this.telephone && !this.validatePhoneNumber(this.telephone)) {
                alert("Please enter a valid phone number");
                return;
            }

            // Extract just the filename from the full URL path
            const fileName = this.imageUrl ? this.imageUrl.split('/').pop() : '';

            const data = {
                'session': session,
                'firstName': this.name,
                'gender': parseInt(this.selectedOptionGenderid),
                'telephone': this.telephone ? this.telephone.replace(/\D/g, '') : '', // Make telephone optional
                'email': this.email,
                'dob': this.date,
                'fileName': fileName,
                'nationalityId': this.nationalityid ? parseInt(this.nationalityid) : this.profile.nationalityId,
                'address': this.address
            };

            const url = this.$url + `user-register/update`;
            let config = {
                method: "POST",
                headers: {
                    ...this.$header,
                    "Content-Type": "multipart/form-data"
                },

                data: data,
                url,
            };

            try {
                const response = await axios(config);
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        if (response.data.body.message == "Success") {
                            await this.getMyprofile();
                            this.dialogEditpf = false; // Close the popup
                            // Optionally add a success message
                            // alert("Profile updated successfully!");
                        }
                    } else {
                        alert("Update failed. Please check your input.");
                    }
                }
            } catch (error) {
                console.error('Update error:', error);
                alert("An error occurred while updating your profile.");
            }
        },

        setGender(value) {
            this.index = value;
            if (this.index == 0) {
                this.selectedOptionGender = this.$t('message.gender');
                this.selectedOptionGenderid = 0;
            } else if (this.index == 1) {
                this.selectedOptionGender = this.$t('message.male');
                this.selectedOptionGenderid = 1;
            } else {
                this.selectedOptionGender = this.$t('message.Female');
                this.selectedOptionGenderid = 2;
            }

            this.showListDataGen = false;
        },
        selectGender() {
            this.showListDataGen = true;
        },
        filterNations() {
            if (!this.nationality) {
                this.filteredNations = [...this.listnation];
            } else {
                const searchTerm = this.nationality.toLowerCase();
                this.filteredNations = this.listnation.filter(nation =>
                    nation.name.toLowerCase().includes(searchTerm)
                );
            }
            this.showListDataNation = true;
        },

        hidePopupGen() {
            this.showListDataGen = false;

        },
        setNational(id, name) {
            this.nationality = name;
            this.nationalityid = id;
            this.showListDataNation = false;
            this.filteredNations = [...this.listnation];
        },

        async selectNation() {
            try {
                if (!this.listnation || this.listnation.length === 0) {
                    await this.getNationality();
                }
                this.filteredNations = [...this.listnation];
                this.showListDataNation = !this.showListDataNation;
            } catch (error) {
                console.error('Error loading nationalities:', error);
            }
        },
        hidePopupNation() {
            this.showListDataNation = false;
            this.filteredNations = [...this.listnation]
        },
        async getNationality() {
            const url = this.$url + `travel-package/nationalityList`;

            let config = {
                method: "POST",
                headers: this.$header,
                url,
            };

            axios(config).then((response) => {
                if (response.status == 200) {

                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        if (response.data.body.status == true || response.data.body.message == 'Success') {
                            this.listnation = response.data.body.data;
                            if (this.nationalityid == '' && this.nataionlity == '') {
                                for (var i in this.listnation) {
                                    if (localStorage.getItem('nationalityIdProfile') == this.listnation[i].name) {
                                        this.nataionlity = this.listnation[i].name;
                                        this.nationalityid = this.listnation[i].id;
                                        this.showListDataNation = false;
                                    }
                                }
                            }
                        } else {
                            alert(response.data.body.message)
                        }

                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        async getMyprofile() {
            const url = this.$url + `user-register/me`;
            const data = {
                'session': localStorage.getItem('session_start_login'),
            }
            let config = {
                method: "POST",
                headers: {
                    ...this.$header,
                    "Content-Type": "multipart/form-data"
                },
                data: data,
                url,
            };
            this.loading = true;

            axios(config).then((response) => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.loading = false;
                        this.profile = response.data.body;
                        this.name = this.profile.name;
                        this.nationality = this.profile.nationality;
                        this.email = this.profile.email;
                        this.date = this.profile.dob;
                        this.telephone = this.profile.telephone;
                        this.address = this.profile.address;
                        this.imageUrl = this.profile.filename;

                        localStorage.setItem('nameProfile', response.data.body.name);
                        localStorage.setItem('usernameProfile', response.data.body.username);
                        localStorage.setItem('emailProfile', response.data.body.email);
                        localStorage.setItem('dobProfile', response.data.body.dob);
                        localStorage.setItem('telephoneProfile', response.data.body.telephone);
                        localStorage.setItem('nationalityIdProfile', response.data.body.nationality);
                        localStorage.setItem('genderProfile', response.data.body.gender);
                        localStorage.setItem('addressProfile', response.data.body.address);
                        localStorage.setItem('imageProfile', response.data.body.filename);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },


        CloseDialogPf() {
            this.dialogEditpf = false;
        },
        handleKeydown(event) {
            if (event.key === 'Enter') {
                this.updateProfileInfo();
            }
        },
        EditProfile() {
            this.dialogEditpf = true;
            this.setGender(localStorage.getItem('genderProfile'));
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getProfileImageUrl(filename) {
            // If filename is a full URL, return it as is
            if (filename.startsWith('http')) {
                return filename;
            }
            // Otherwise, prepend your API base URL
            return this.$UrlImg + filename;
        },
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    // async created() {
    //         try {
    //             await this.getNationality();
    //             this.filteredNations = [...this.listnation];
    //             await this.getMyprofile();
    //             this.scrollToTop();
    //         } catch (error) {
    //             console.error('Error in created hook:', error);
    //         }
    //     }
    created() {
        //  this.selectNation();
        this.getMyprofile();
        this.scrollToTop();
        this.getNationality();
        this.filteredNations = [...this.listnation];
    }
}
</script>
<style scoped>
/* Ensure the date picker popup is positioned relative to the input */
.date-picker-custom .mx-datepicker-main.mx-datepicker-popup {
    position: absolute;
    /* Position the popup absolutely */
    top: 100%;
    /* Place it directly below the input box */
    left: 0;
    /* Align it to the left of the input box */
    z-index: 1000;
    /* Ensure the popup is above other elements */
    margin-top: 5px;
    /* Add some space between the input and the popup */
}

/* Ensure the input has relative positioning to contain the popup */
.date-picker-custom {
    position: relative;
    /* Set the container to relative */
    display: inline-block;
    /* Make sure it fits around the input */
}

.profile_account {
    /* min-height: 80vh; */
    display: flex;
    flex-direction: column;
    /* background: #f2f2f2; */
}

.container-fluid {
    flex: 1;
    padding-top: 55px;
    padding-bottom: 50px;
    /* background: #f2f2f2; */
}

.header-profile {
    /* background: #f2f2f2; */
    padding: 35px 0;
    /* margin-bottom: 30px; */
}

.absoluteicon {
    position: absolute;
    top: 52%;
    /* left: 200px; */
    right: 15%;
    transform: translateY(-50%);

}

.editeprofile {
    /* display: flex; */
    /* flex-direction: column;
    flex-wrap: wrap; */
    /* justify-content: center; */
    align-items: start;
    gap: 20;
    padding-bottom: 50px;
}

.img_edit {
    width: 105px;
    height: 105px;
    border-radius: 50%;
    object-fit: contain;
    border: 1px solid #6A6969;
}

.dis-screen-lg {
    display: block;
}

.mobile-screen {
    display: none;
}

.set-width-80 {
    width: 90%;
    display: flex;
    justify-content: center;
}

.v-dialog {
    margin-top: 5% !important;
}

.hover-color:hover {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    color: #ffffff;
    background: #DE5D0A;
}


/* .mx-datepicker-popup{
        top: 599px !important;
    } */
.mx-datepicker {
    width: 100%;
}

::v-deep .mx-input-wrapper .mx-input {
    background-color: #ffffff;
    border-radius: 8px;
}

.mx-datepicker-body {
    position: relative;
}

::v-deep .mx-datepicker-main .mx-datepicker-popup {
    position: absolute !important;
    top: 370px !important;
    height: 100vh;
}

.custom-input-textarea {
    padding-left: 12px;
    padding-top: 10px;
    border-radius: 10px;
    width: 100%;
    height: 80px;
    outline: none;
    border: 1px solid #C6C6C6;
}

.cursor-pointer {
    cursor: pointer;
}

.editpf {
    color: #1D1D1D !important;
    font-weight: 600;
    font-size: 20px;
}

.close-x {
    position: absolute;
    right: 24px;
    font-size: x-large;
    /* top: 4px; */
    cursor: pointer;
}

.custom-input {
    /* cursor: pointer; */
    padding-left: 12px;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #C6C6C6;
}

.custom-input-email {
    /* cursor: pointer; */
    padding-left: 12px;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #C6C6C6;
}

.custom-input-phone {
    /* cursor: pointer; */
    padding-left: 12px;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #C6C6C6;
}

.edit_icon {
    position: absolute;
    right: 38%;
    bottom: 12%;
    cursor: pointer;
}

.edit_icon_image {
    position: absolute;
    right: 38%;
    bottom: 12%;
    cursor: pointer;
}

.header-title {
    margin-left: -25px !important;
    margin-top: 0px;
    padding-top: 15px;
}

.header-account {
    color: #969EAE;
}

.header-title p {
    margin-bottom: 3px !important;
}

.value-row {
    color: #424245;
}

.custom-input,
.equals_input {
    pointer-events: auto;
    /* Ensure pointer events are enabled */
    z-index: 999999;
    /* Make sure it's on top if needed */
    position: relative;
    /* Make sure it's properly positioned */
}

.custom-input-date,
.equals_input-date {
    pointer-events: auto;
    /* Ensure pointer events are enabled */
    position: relative;
    /* Make sure it's properly positioned */
}

.custom-input,
.equals_input-email {
    pointer-events: auto;
    /* Ensure pointer events are enabled */
    position: relative;
    /* Make sure it's properly positioned */
}

.custom-input-phone,
.equals_input-phone {
    pointer-events: auto;
    /* Ensure pointer events are enabled */
    position: relative;
    /* Make sure it's properly positioned */
}


@media screen and (width < 950px) {

    /* .profile_account{
            margin-top:-35px;
        } */
    .header-mypf {
        margin-left: 70px;
    }
}

@media screen and (width < 620px) {

    /* .profile_account{
            margin-top: -35px;
        } */
    .edit_icon {
        right: 37%;
    }

    .edit_icon_image {
        right: 37%;
    }
}

@media screen and (width < 600px) {

    /* .profile_account{
            margin-top: -35px;
        }    */
    .edit_icon {
        right: 37%;

    }

    .edit_icon_image {
        right: 37%;
    }

    .margin-mobile {
        margin-top: -10px;
    }

    .dis-screen-lg {
        display: none;
    }

    .mobile-screen {
        display: block;
    }
}

@media screen and (width < 560px) {
    .edit_icon {
        right: 35%;
    }

    .edit_icon_image {
        right: 35%;
    }
}

@media screen and (width < 530px) {
    .edit_icon {
        right: 34%;
    }

    .edit_icon_image {
        right: 34%;
    }
}

@media screen and (width < 500px) {
    /* .profile_account{
            margin-top: -35px;
        }    */
    /* .edit_icon{
            right: 148px;
        } */
}

@media(width < 480px) {
    .edit_icon {
        right: 33%;
    }

    .edit_icon_image {
        right: 33%;
    }
}

@media(width < 450px) {
    /* .profile_account{
            margin-top: -35px;
        } */
}

@media screen and (width < 438px) {

    /* .profile_account{
            margin-top: -35px;
        } */
    .edit_icon {
        right: 32%;
    }

    .edit_icon_image {
        right: 32%;
    }

}

@media screen and (width < 395px) {
    .edit_icon {
        right: 28%;
    }

    .edit_icon_image {
        right: 28%;
    }
}
</style>