<template>
    <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div class="shedule-list" v-else>
        <div class="containers">
            <div v-if="falseSale" class="show-form">
                <form-card />
            </div>
            <div v-else>
                <div class="sort" v-if="direction">
                    <div class="row ipad-screen-hide">
                        <!-- <div class="col-lg-5 col-md-5 col-sm-5 text-center">
                            <div class="text-des">Trip: {{getDestinationFrom()}} to {{getdestinationToName()}}</div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-5 text-center">
                            <div class="text-des">Departure Date: {{getDateFrom()}}</div>
                        </div> -->
                        <div class="col-lg-2 col-md-2 col-sm-2 pl-10">
                            <div class="text-des">
                                <span style="font-size: 14px !important;">{{ $t('message.from') }}</span> <br>
                                {{ getDestinationFrom() }}
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 text-center">
                            <div class="text-des" style="margin-top: 12px;">
                                <img src="@/assets/arrows/arrow-right-point.svg" alt="">
                            </div>

                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2">
                            <div class="text-des">
                                <span style="font-size: 14px !important;">{{ $t('message.to') }}</span> <br>
                                {{ getdestinationToName() }}
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1">
                            <div class="text-des">

                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="text-des">
                                <span style="font-size: 14px !important;">{{ $t('message.departure') }}</span> <br>
                                {{ getDateFrom() }}
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 text-center" style="padding-right: 50px;">
                            <div class="text-des" @click="showFormBook" style="margin-top: 2px;">
                                <!-- <img src="@/assets/icon_bars/adjust.svg" alt=""> -->
                                <v-btn style="border-radius: 4px; width: 118%;" color="#DE5D0A" height="39.02px"
                                    class="text-capitalize reposo-height">
                                    <span style="color: #FFF; font-size: 15px !important;">{{ $t('message.modify')
                                    }}</span>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="ipad-screen">
                        <div style="display: flex;align-items: center;">
                            <div style="width: 70%;">
                                <span style="font-size: 20px;">{{ $t('message.trip') }}: {{ getDestinationFrom() }} {{
                                    $t('message.to') }} {{ getdestinationToName() }}</span><br>
                                <span style="font-size: 20px;">{{ $t('message.departureDate') }}:
                                    {{ getDateFrom() }}</span>
                            </div>
                            <div style="width: 30%;display: flex;justify-content: end;align-items: center;"
                                @click="showFormBook">
                                <!-- <img src="@/assets/icon_bars/adjust.svg" alt=""> -->
                                <v-btn style="border-radius: 4px; width: 80%;" color="#DE5D0A" height="39.02px"
                                    class="text-capitalize reposo-height">
                                    <span style="color: #FFF">{{ $t('message.modify') }}</span>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showForm" class="show-form">
                    <form-card />
                </div>
            </div>
        </div>
        <div class="containers">
            <div v-if="noResult" style="text-align: center;padding-top: 50px;padding-bottom: 10px;" class="set-heights">
                <div style="height: 300px;display: flex;align-items: center;justify-content: center;">
                    <div>
                        <img style="border-radius: 8px;" src="@/assets/icon-social-media/no-schedule.png" alt=""><br>
                        <span style="font-size: 20px;">{{ $t('message.noSchedule') }}</span>
                    </div>
                </div>
            </div>
            <div v-else class="padd-list" style="padding-left: 10px;padding-right: 10px;height: 100%;">
                <div v-if="heightFalse" class="hei-set">
                    <div class="row" v-for="(item, i) in info" :key="i">
                        <div class="col-lg-12 card-top">
                            <div class="row pding-12">
                                <div class="col-lg-4 col-md-4 col-sm-12 hide-col">

                                    <div class="row pt-2">
                                        <div class="width-img">
                                            <div class="busImg"
                                                @click="modalGallery(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs, item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress, item.boardingPointList, item.dropOffPointList)">
                                                <img :src="item.transportationPhoto != '' ? item.transportationPhoto : require('@/assets/placeholder-image.jpeg')"
                                                    alt="">
                                            </div>
                                        </div>
                                        <div class="width-bus-logo-type pt-3">
                                            <div class="bus-logo">
                                                <img v-if="item.scheduleType == 1" src="@/assets/vireak-buntham.png"
                                                    alt="">
                                                <img v-if="item.scheduleType == 3" src="@/assets/airbus.png" alt=""
                                                    width="48">
                                                <img v-if="item.scheduleType == 4" src="@/assets/vet-laos.svg" alt=""
                                                    height="48">
                                            </div>
                                            <div class="text-air-bus">
                                                <span class="title-bus">{{ item.transportationType }}</span><br>
                                                <small style="font-weight: 500;font-size: 12px;color: #848486;">{{
                                                    item.transportRouteDisplay }} - {{ item.nationRoad }}</small>
                                                <!-- <small style="font-weight: 500;font-size: 12px;color: #848486;  flex-wrap: wrap;"> Direct Trip - {{ item.nationRoad }}</small> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="departure-duration-arrival pt-2">
                                        <div class="departure incl-style">
                                            <div>
                                                <div class="tittle_departure">{{ $t('message.departure') }}</div>
                                                <div class="time_depa">{{ convertTimes(item.departure) }}</div>
                                            </div>
                                            <div>
                                                <div class="line-left-img"><img src="@/assets/line_sche/line-left.svg"
                                                        alt=""></div>
                                            </div>
                                        </div>
                                        <div class="duration incl-style">
                                            <div>
                                                <div class="tittle_departure">{{ $t('message.duration') }}</div>
                                                <div class="time_depa" style="color:#312783">{{
                                                    convertTime(item.duration) }}</div>
                                            </div>
                                            <div>
                                                <div class="line-right-img"><img src="@/assets/line_sche/line-rigt.svg"
                                                        alt=""></div>
                                            </div>
                                        </div>
                                        <div class="arrival incl-style">
                                            <div>
                                                <div class="tittle_departure">{{ $t('message.arrival') }}</div>
                                                <div class="time_depa">{{ convertTimes(item.arrival) }}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-12">
                                    <div class="number_seat">
                                        <div v-if="item.status == 2">

                                        </div>
                                        <div v-else-if="item.status == 1">
                                            <span style="color: #DE5D0A;">{{ item.totalSeat - item.seatAvailable
                                            }}</span>/{{ item.totalSeat }} {{ $t('message.seats') }}
                                        </div>
                                        <div v-else>
                                            <div v-if="item.status == 3">
                                                <span style="color: #DE5D0A;">{{ item.totalSeat }}</span>/{{
                                                    item.totalSeat }} {{ $t('message.seats') }}
                                            </div>
                                            <div v-else>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="price_seat">
                                        <div v-if="selectNational">
                                            ${{ item.price }}
                                            <span v-if="item.priceOriginal == ''"
                                                style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;"></span>
                                            <span v-else
                                                style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;">${{
                                                    item.priceOriginal }}</span>
                                        </div>
                                        <div v-else>
                                            <span v-if="item.priceOriginal != ''">${{ item.price }} </span>
                                            <span v-else>${{ item.price }} </span>

                                            <span v-if="item.priceOriginal == ''"
                                                style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;"></span>
                                            <span v-else
                                                style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;">${{
                                                    item.priceOriginal }}</span>
                                        </div>
                                    </div>
                                    <!-- <div>
                                        <div v-if="selectNational">
                                            <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1" @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay,item.discount,item.disPercent)">
                                                {{$t('message.bookNow')}}
                                            </div>
                                            <div class="btn-book-air" v-if="item.status == 1 && item.scheduleType == 3" @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay,item.discount,item.disPercent)">
                                                {{$t('message.bookNow')}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div v-if="item.priceOriginal != ''">
                                                <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1" @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.price, item.transportRouteDisplay,item.discount,item.disPercent)">
                                                    {{$t('message.bookNow')}}
                                                </div>
                                                <div class="btn-book-air" v-if="item.status == 1 && item.scheduleType == 3" @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.price, item.transportRouteDisplay,item.discount,item.disPercent)">
                                                    {{$t('message.bookNow')}}
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1" @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay,item.discount,item.disPercent)">
                                                    {{$t('message.bookNow')}}
                                                </div>
                                                <div class="btn-book-air" v-if="item.status == 1 && item.scheduleType == 3" @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay,item.discount,item.disPercent)">
                                                    {{$t('message.bookNow')}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-leaving" v-if="item.status == 2">
                                        {{$t('message.left')}}
                                    </div>
                                    <div class="btn-leaving" v-if="item.status == 3">
                                        {{$t('message.full')}}
                                    </div> -->
                                </div>
                            </div>
                            <div class="row" style="border-top: 1px solid #666B8040;">
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div style="display: flex;">
                                        <div class="incl-flex">
                                            <!-- <div  @click="boaringPointMap(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs,item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress)">Boarding: <span @click="boaringPointMap(item.description, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs,item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress)" style="color: #DE5D0A;" v-for="(boardingPoint, i) in item.boardingPointList" :key="i">{{ boardingPoint.name }} </span></div> -->
                                            <div class="text-style"
                                                @click="boaringPointMap(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs, item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress, item.boardingPointList, item.dropOffPointList)"
                                                style="display: flex;">
                                                <div style="display: flex;">
                                                    <div>
                                                        <img src="@/assets/icon_bars/place_map.svg" alt="">
                                                    </div>
                                                    <div style="white-space: nowrap;">
                                                        {{ $t('message.boarding') }}:
                                                    </div>
                                                </div>
                                                <div>
                                                    <!-- <span style="color: #DE5D0A;" v-for="(boardingPoint, i) in item.boardingPointList" :key="i">{{ boardingPoint.name }} {{ i ==  item.boardingPointList.length-1 ? "": " | " }}  </span> -->
                                                    <div style="padding-left: 4px;"
                                                        v-for="(boardingPoint, i) in item.boardingPointList" :key="i">
                                                        <span style="color: #DE5D0A;">-</span> {{ boardingPoint.name }}
                                                        {{ convertTimes(boardingPoint.time) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div style="display: flex;">
                                        <div class="incl-flex">
                                            <!-- <div  @click="boaringPointMap(item.description, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs,item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress)">Boarding: <span @click="boaringPointMap(item.description, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs,item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress)" style="color: #DE5D0A;" v-for="(boardingPoint, i) in item.boardingPointList" :key="i">{{ boardingPoint.name }} </span></div> -->
                                            <div class="text-style"
                                                @click="dropOffPointMap(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointLats, item.boardingPointLongs, item.boardingPointAddress, item.dropOffPointAddress, item.boardingPointList, item.dropOffPointList)"
                                                style="display: flex;">
                                                <div style="display: flex;">
                                                    <div>
                                                        <img src="@/assets/icon_bars/place_map.svg" alt="">
                                                    </div>
                                                    <div style="white-space: nowrap;">
                                                        {{ $t('message.dropOff') }}:
                                                    </div>
                                                </div>
                                                <div>
                                                    <!-- <span style="color: #DE5D0A;" v-for="(boardingPoint, i) in item.boardingPointList" :key="i">{{ boardingPoint.name }} {{ i ==  item.boardingPointList.length-1 ? "": " | " }}  </span> -->
                                                    <div style="padding-left: 4px;"
                                                        v-for="(dropOffPoint, i) in item.dropOffPointList" :key="i">
                                                        <span style="color: #DE5D0A;">-</span> {{ dropOffPoint.name }}
                                                        {{ convertTimes(dropOffPoint.time) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-12">
                                    <div class="incl-flex">
                                        <img src="@/assets/icon_bars/info.svg" alt="">
                                        <div class="text-style"
                                            @click="modalTripInfo(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointLats, item.boardingPointLongs, item.boardingPointAddress, item.dropOffPointAddress, item.boardingPointList, item.dropOffPointList)">
                                            {{ $t('message.tripInfo') }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-12">
                                    <div>
                                        <div v-if="selectNational">
                                            <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1"
                                                @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                {{ $t('message.bookNow') }}
                                            </div>
                                            <div class="btn-book-air" v-if="item.status == 1 && item.scheduleType == 3"
                                                @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                {{ $t('message.bookNow') }}
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div v-if="item.priceOriginal != ''">
                                                <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1"
                                                    @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.price, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                    {{ $t('message.bookNow') }}
                                                </div>
                                                <div class="btn-book-air"
                                                    v-if="item.status == 1 && item.scheduleType == 3"
                                                    @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.price, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                    {{ $t('message.bookNow') }}
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1"
                                                    @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                    {{ $t('message.bookNow') }}
                                                </div>
                                                <div class="btn-book-air"
                                                    v-if="item.status == 1 && item.scheduleType == 3"
                                                    @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                    {{ $t('message.bookNow') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="btn-leaving" v-if="item.status == 2">
                                        {{ $t('message.left') }}
                                    </div>
                                    <div class="btn-leaving" v-if="item.status == 3">
                                        {{ $t('message.full') }}
                                    </div>
                                    <div class="btn-leaving" v-if="item.status == 4">
                                        {{ $t('message.unavailable') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row" v-for="(item, i) in info" :key="i">
                        <div class="col-lg-12 card-top">
                            <div class="row pding-12">
                                <div class="col-lg-4 col-md-4 col-sm-12 hide-col">
                                    <div class="row pt-2" st>
                                        <div class="width-img">
                                            <div class="busImg"
                                                @click="modalGallery(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs, item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress, item.boardingPointList, item.dropOffPointList)">
                                                <img :src="item.transportationPhoto != '' ? item.transportationPhoto : require('@/assets/placeholder-image.jpeg')"
                                                    alt="">
                                            </div>
                                        </div>
                                        <div class="width-bus-logo-type pt-3">
                                            <div class="bus-logo">
                                                <img v-if="item.scheduleType == 1" src="@/assets/vireak-buntham.png"
                                                    alt="">
                                                <img v-if="item.scheduleType == 3" src="@/assets/airbus.png" alt="">
                                            </div>
                                            <div class="text-air-bus">
                                                <span class="title-bus">{{ item.transportationType }}</span><br>
                                                <small style="font-weight: 500;font-size: 12px;color: #848486;">{{
                                                    item.transportRouteDisplay }} - {{ item.nationRoad }}</small>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="departure-duration-arrival pt-2">
                                        <div class="departure incl-style">
                                            <div>
                                                <div class="tittle_departure">{{ $t('message.departure') }}</div>
                                                <div class="time_depa">{{ convertTimes(item.departure) }}</div>
                                            </div>
                                            <div>
                                                <div class="line-left-img"><img src="@/assets/line_sche/line-left.svg"
                                                        alt=""></div>
                                            </div>
                                        </div>
                                        <div class="duration incl-style">
                                            <div>
                                                <div class="tittle_departure">{{ $t('message.duration') }}</div>
                                                <div class="time_depa">{{ convertTime(item.duration) }}</div>
                                            </div>
                                            <div>
                                                <div class="line-right-img"><img src="@/assets/line_sche/line-rigt.svg"
                                                        alt=""></div>
                                            </div>
                                        </div>
                                        <div class="arrival incl-style">
                                            <div>
                                                <div class="tittle_departure">{{ $t('message.arrival') }}</div>
                                                <div class="time_depa">{{ convertTimes(item.arrival) }}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-12">
                                    <div class="number_seat">
                                        <div v-if="item.status == 2">

                                        </div>
                                        <div v-else-if="item.status == 1">
                                            <span style="color: #DE5D0A;">{{ item.totalSeat - item.seatAvailable
                                            }}</span>/{{ item.totalSeat }} {{ $t('message.seats') }}
                                        </div>
                                        <div v-else>
                                            <div v-if="item.status == 3">
                                                <span style="color: #DE5D0A;">{{ item.totalSeat }}</span>/{{
                                                    item.totalSeat }} {{ $t('message.seats') }}
                                            </div>
                                            <div v-else>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="price_seat">
                                        <div v-if="selectNational">
                                            ${{ item.price }}
                                            <span v-if="item.priceOriginal == ''"
                                                style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;"></span>
                                            <span v-else
                                                style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;">${{
                                                    item.priceOriginal }}</span>
                                        </div>
                                        <div v-else>
                                            <span v-if="item.priceOriginal != ''">${{ item.price }} </span>
                                            <span v-else>${{ item.price }} </span>

                                            <span v-if="item.priceOriginal == ''"
                                                style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;"></span>
                                            <span v-else
                                                style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;">${{
                                                    item.priceOriginal }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="border-top: 1px solid #666B8040;">
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div style="display: flex;">
                                        <div class="incl-flex">
                                            <!-- <div  @click="boaringPointMap(item.description, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs,item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress)">Boarding: <span @click="boaringPointMap(item.description, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs,item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress)" style="color: #DE5D0A;" v-for="(boardingPoint, i) in item.boardingPointList" :key="i">{{ boardingPoint.name }} </span></div> -->
                                            <div class="text-style"
                                                @click="boaringPointMap(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs, item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress, item.boardingPointList, item.dropOffPointList)"
                                                style="display: flex;">
                                                <div style="display: flex;">
                                                    <div>
                                                        <img src="@/assets/icon_bars/place_map.svg" alt="">
                                                    </div>
                                                    <div style="white-space: nowrap;">
                                                        {{ $t('message.boarding') }}:
                                                    </div>
                                                </div>
                                                <div>
                                                    <!-- <span style="color: #DE5D0A;" v-for="(boardingPoint, i) in item.boardingPointList" :key="i">{{ boardingPoint.name }} {{ i ==  item.boardingPointList.length-1 ? "": " | " }}  </span> -->
                                                    <div style="padding-left: 4px;"
                                                        v-for="(boardingPoint, i) in item.boardingPointList" :key="i">
                                                        <span style="color: #DE5D0A;">-</span> {{ boardingPoint.name }}
                                                        {{ convertTimes(boardingPoint.time) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div style="display: flex;">
                                        <div class="incl-flex">
                                            <!-- <div  @click="boaringPointMap(item.description, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs,item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress)">Boarding: <span @click="boaringPointMap(item.description, item.slidePhoto, item.transportationPhoto, item.amenities, item.boardingPointLats, item.boardingPointLongs,item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointAddress, item.dropOffPointAddress)" style="color: #DE5D0A;" v-for="(boardingPoint, i) in item.boardingPointList" :key="i">{{ boardingPoint.name }} </span></div> -->
                                            <div class="text-style"
                                                @click="dropOffPointMap(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointLats, item.boardingPointLongs, item.boardingPointAddress, item.dropOffPointAddress, item.boardingPointList, item.dropOffPointList)"
                                                style="display: flex;">
                                                <div style="display: flex;">
                                                    <div>
                                                        <img src="@/assets/icon_bars/place_map.svg" alt="">
                                                    </div>
                                                    <div style="white-space: nowrap;">
                                                        {{ $t('message.dropOff') }}:
                                                    </div>
                                                </div>
                                                <div>
                                                    <!-- <span style="color: #DE5D0A;" v-for="(boardingPoint, i) in item.boardingPointList" :key="i">{{ boardingPoint.name }} {{ i ==  item.boardingPointList.length-1 ? "": " | " }}  </span> -->
                                                    <div style="padding-left: 4px;"
                                                        v-for="(dropOffPoint, i) in item.dropOffPointList" :key="i">
                                                        <span style="color: #DE5D0A;">-</span> {{ dropOffPoint.name }}
                                                        {{ convertTimes(dropOffPoint.time) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-12">
                                    <div class="incl-flex">
                                        <img src="@/assets/icon_bars/info.svg" alt="">
                                        <div class="text-style"
                                            @click="modalTripInfo(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointLats, item.boardingPointLongs, item.boardingPointAddress, item.dropOffPointAddress, item.boardingPointList, item.dropOffPointList)">
                                            {{ $t('message.tripInfo') }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-12">
                                    <div>
                                        <div v-if="selectNational">
                                            <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1"
                                                @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                {{ $t('message.bookNow') }}
                                            </div>
                                            <div class="btn-book-air" v-if="item.status == 1 && item.scheduleType == 3"
                                                @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                {{ $t('message.bookNow') }}
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div v-if="item.priceOriginal != ''">
                                                <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1"
                                                    @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.price, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                    {{ $t('message.bookNow') }}
                                                </div>
                                                <div class="btn-book-air"
                                                    v-if="item.status == 1 && item.scheduleType == 3"
                                                    @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.price, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                    {{ $t('message.bookNow') }}
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1"
                                                    @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                    {{ $t('message.bookNow') }}
                                                </div>
                                                <div class="btn-book-air"
                                                    v-if="item.status == 1 && item.scheduleType == 3"
                                                    @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.transportationType, item.priceOriginal, item.transportRouteDisplay, item.discount, item.disPercent)">
                                                    {{ $t('message.bookNow') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="btn-leaving" v-if="item.status == 2">
                                        {{ $t('message.left') }}
                                    </div>
                                    <div class="btn-leaving" v-if="item.status == 3">
                                        {{ $t('message.full') }}
                                    </div>
                                    <div class="btn-leaving" v-if="item.status == 4">
                                        {{ $t('message.unavailable') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <!-- Popup trip info -->
        <v-row justify="center">
            <v-dialog v-model="dialogInfo" persistent max-width="1120px" style="z-index: 1002;">
                <v-card>
                    <div class="close--more--info"
                        style="position: sticky; top: 0%; z-index: 2000; background-color: #ffffff;">
                        <div class="more-title">{{ $t('message.moreInformationAboutBus') }}</div>
                        <div class="icon--close" @click="dialogInfo = false; removefixscroll()"><i
                                style="color: #6E6E73;" class="fa-solid fa-xmark"></i></div>
                    </div>
                    <v-tabs vertical v-model="selecttion" style="height: 582px;">
                        <v-tab class="text-capitalize gray" style="color: #6E6E73;z-index: 10;">
                            {{ $t('message.tripInfo') }}
                        </v-tab>
                        <v-tab class="text-capitalize" style="color: #6E6E73;z-index: 10;" @click="setBorad">
                            {{ $t('message.boarding') }}
                        </v-tab>
                        <v-tab class="text-capitalize" style="color: #6E6E73;z-index: 10;" @click="setDrop">
                            {{ $t('message.dropOff') }}
                        </v-tab>
                        <v-tab class="text-capitalize" style="color: #6E6E73;z-index: 10;">
                            {{ $t('message.gallery') }}
                        </v-tab>
                        <v-tab class="text-capitalize" style="color: #6E6E73;z-index: 10;">
                            {{ $t('message.amenities') }}
                        </v-tab>

                        <v-tab-item>
                            <v-card flat>
                                <v-card-text class="content-wrapper">
                                    <div class="set-w-h" style="overflow-y: auto;">
                                        <!-- Title -->
                                        <p class="description">{{ $t('message.description') }}</p>

                                        <!-- Paragraphs (Content Section) -->
                                        <div class="text" v-for="(paragraph, index) in description" :key="index"
                                            v-html="paragraph"></div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="set-w-h" style="border: 1px solid #6E6E73;overflow-y: scroll;">
                                        <div style="padding: 10px;width: 100%;">
                                            <b>{{ getDestinationFrom() }} - {{ getdestinationToName() }} </b>
                                            <div>
                                                <!-- <ul style="list-style-type: none; display: flex;">
                                                    <li style="padding-left: 10px;"  v-for="(boardinglist,i) in boardingPointList" :key="i"><a href="">{{ boardinglist.name }}</a></li>
                                                    
                                                </ul> -->
                                            </div>
                                            <ul>
                                                <li v-for="(boardinglist, i) in boardingPointList" :key="i"
                                                    style="padding-bottom: 14px;">
                                                    {{ boardinglist.address }}
                                                    <div class="boarding" ref="mapboading"
                                                        v-html="generateMapUrl(boardinglist.lats, boardinglist.longs)"
                                                        style="padding-top: 10px;">

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- <div class="boarding" ref="mapboading" v-html="linkMapB" style="padding-top: 10px;">
                                                        
                                        </div> -->

                                        <!-- <GmapMap
                                            :center="{lat: parseFloat(boardingPointLats), lng: parseFloat(boardingPointLongs) }"
                                            :zoom="zoom"
                                            style="width: 100%; height: 400px"
                                        >
                                          
                                        <GmapMarker
                                            v-for="(boardinglist, i) in boardingPointList"
                                            :key="i"
                                            :position="{  lat: parseFloat(boardinglist.lats), lng: parseFloat(boardinglist.longs) }"
                                            :clickable="true"
                                            @click="openGoogleMaps(boardinglist.lats, boardinglist.longs)"
                                            :title="boardinglist.name"
                                            
                                        />
                                           
                                        </GmapMap> -->
                                        <!-- <iframe :src="linkMapB" id="boarding" style="width: 100%;height: 100%;margin-top: 10px;" allowfullscreen></iframe> -->
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="set-w-h" style="border: 1px solid #6E6E73;overflow-y: scroll;">
                                        <div style="padding: 10px;width: 100%;">
                                            <b>{{ getDestinationFrom() }} - {{ getdestinationToName() }} </b>
                                            <ul>
                                                <li v-for="(dropOffPoint, i) in dropOffPointList" :key="i"
                                                    style="padding-bottom: 14px;">
                                                    {{ dropOffPoint.address }}
                                                    <div class="dropoff" ref="mapdropoff"
                                                        v-html="generateMapUrl(dropOffPoint.lats, dropOffPoint.longs)"
                                                        style="padding-top: 10px;">

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- <div class="dropoff" ref="mapdropoff" v-html="linkMapD">
                                            
                                        </div> -->
                                        <!-- <iframe :src="linkMapD" id="dropoff" style="width: 100%;height: 100%;" allowfullscreen></iframe> -->
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="set-w-h" style="border-radius: 12px;">
                                        <div v-if="slide" class="noSide">
                                            <img :src="transportationPhoto == '' ? require('@/assets/placeholder-image.jpeg') : transportationPhoto"
                                                alt="">
                                        </div>
                                        <div v-else class="carousel-container">
                                            <vueper-slides fractions arrow="true" height="100%"
                                                style="height: 100%;border-radius: 12px; position: relative;">
                                                <vueper-slide style="height: 100%;border-radius: 12px;"
                                                    v-for="(slide, i) in slides" :key="i" :image="slide.photo" />
                                                <template #arrow-left>
                                                    <div class="arrow left-arrow d-flex justify-center align-center">
                                                        <img src="@/assets/travel_package/left-chevron.svg"
                                                            alt="Left Arrow" width="30px" />
                                                    </div>
                                                </template>
                                                <template #arrow-right>
                                                    <div class="arrow right-arrow d-flex justify-center align-center">
                                                        <img src="@/assets/travel_package/right-chevron.svg"
                                                            alt="Right Arrow" width="30px" />
                                                    </div>
                                                </template>
                                            </vueper-slides>
                                        </div>
                                    </div>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="set-w-h">
                                        <div class="amenities">
                                            <div class="air_bus" v-for="(amenitie, i) in amenities" :key="i">
                                                <img :src="amenitie.icon" style="width: 45px;height: 45px;" alt="">
                                                <div class="text">{{ amenitie.name }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- Popup select seats number -->
        <v-row justify="center">
            <v-dialog class="dialog-seat" v-model="dialogSeat" persistent :max-width="dialogWidth"
                style="z-index: 1002;">
                <v-card>
                    <div class="departing_icon"
                        style="position: sticky; top: 0%; z-index: 2000; background-color: #ffffff;">
                        <div class="departing">
                            {{ getDestinationFrom() }} - {{ getdestinationToName() }} ({{ transportRouteDisplay }})
                        </div>
                        <div class="icon">
                            <div class="icon--close" @click="closeIconDislog"><i style="color: #6E6E73;"
                                    class="fa-solid fa-xmark"></i></div>
                        </div>
                    </div>
                    <div class="rows -p">
                        <div class="seat">
                            <div class="choose-seat">
                                <div class="seat-color">
                                    <div class="seat-colors-gray"></div>
                                    <div class="text">{{ $t('message.seatAvailable') }}</div>
                                </div>
                                <div class="seat-color">
                                    <div class="seat-colors-blue"></div>
                                    <div class="text">{{ $t('message.seatSelected') }}</div>
                                </div>
                                <div class="seat-color">
                                    <div class="seat-colors-green"></div>
                                    <div class="text">{{ $t('message.seatUnavilable') }}</div>
                                </div>
                            </div>
                            <div class="seat_num">

                                <v-progress-circular v-if="loading1" indeterminate color="#de5d0a"
                                    style="width: 33%;position: absolute;top: 45%;"></v-progress-circular>
                                <div class="container-table" v-else
                                    style="width: 297px;padding-top: 10px;padding-bottom: 10px;margin-top: 20px;border: 1px solid #6A6969;border-radius: 12px;">
                                    <table class="table_reposive">
                                        <tr v-for="(colunm, index) in colunms" :key="index">
                                            <td v-for="(row, index1) in arraydata[index]['col'].length" :key="index1"
                                                style="text-align: center;width: 20px;padding-top: 5px;">
                                                <div v-if="arraydata[index]['col'][index1]['label'] != ''"
                                                    style="margin: 0px;padding: 0;width: 100%">
                                                    <div style="margin: 0;padding: 0;"
                                                        v-if="!(arraydata[index]['col'][index1]['label'] == 'Down Stair' || arraydata[index]['col'][index1]['label'] == 'Up Stair' || arraydata[index]['col'][index1]['label'] == 'Capitain' || arraydata[index]['col'][index1]['label'] == 'Toilet' || arraydata[index]['col'][index1]['label'] == 'Hostess' || arraydata[index]['col'][index1]['label'] == 'Door')">
                                                        <div style="margin: 0;padding: 0;"
                                                            v-if="!(getUnva(arraydata[index]['col'][index1]['value']))">
                                                            <img v-if="!selectedSeat || !(getSeat(arraydata[index]['col'][index1]['value']))"
                                                                @click="addClick(arraydata[index]['col'][index1]['value'], arraydata[index]['col'][index1]['label'])"
                                                                :src="seatType == 2 ? require('@/assets/seats/bus_night_available.png') : require('@/assets/seats/available.svg')"
                                                                style="width:40px" alt="">
                                                        </div>
                                                    </div>
                                                    <img v-if="getUnva(arraydata[index]['col'][index1]['value'])"
                                                        :src="seatType == 2 ? require('@/assets/seats/bus_night_not_available.png') : require('@/assets/seats/not_available.svg')"
                                                        style="width:40px" alt="">
                                                    <img v-if="selectedSeat && getSeat(arraydata[index]['col'][index1]['value'])"
                                                        @click="deleteSeat(arraydata[index]['col'][index1]['value'], arraydata[index]['col'][index1]['label'])"
                                                        :src="seatType == 2 ? require('@/assets/seats/bus_night_selected.png') : require('@/assets/seats/selected.svg')"
                                                        style="width:40px" alt="">
                                                </div>
                                                <img v-if="arraydata[index]['col'][index1]['label'] == ''"
                                                    :src="require('@/assets/seats/invisible.svg')" style="width:20px;"
                                                    alt="">
                                                <p v-if="!(arraydata[index]['col'][index1]['label'] == 'Hostess' || arraydata[index]['col'][index1]['label'] == 'Capitain')"
                                                    style="font-size: 12px;font-weight: bold;margin-top: -3px;margin-bottom: 0;">
                                                    {{ arraydata[index]['col'][index1]['label'] == 'Down Stair' ?
                                                        $t('message.downstair') :
                                                        arraydata[index]['col'][index1]['label'] == 'Up Stair' ?
                                                            $t('message.upstair') :
                                                            arraydata[index]['col'][index1]['label'] == 'Toilet' ?
                                                                $t('message.toilet')
                                                    : arraydata[index]['col'][index1]['label'] == 'Door' ?
                                                    $t('message.door') :
                                                    arraydata[index]['col'][index1]['label'] }}

                                                    <span>{{ getGender(arraydata[index]['col'][index1]['value'])
                                                    }}</span>
                                                </p>
                                                <img v-if="arraydata[index]['col'][index1]['label'] == 'Capitain'"
                                                    :src="require('@/assets/seats/captain.png')" style="width:30px;"
                                                    alt="">
                                                <img v-if="arraydata[index]['col'][index1]['label'] == 'Hostess'"
                                                    :src="require('@/assets/seats/hostess.png')" style="width:30px;"
                                                    alt="">
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="prices">
                            <div class="info-data">
                                <div style="display: flex;padding-bottom: 12px;">
                                    <div class="font-gray">{{ $t('message.vehicleType') }}: </div>
                                    <div class="font-black">{{ transportationType }}</div>
                                </div>
                                <div style="display: flex;padding-bottom: 12px;">
                                    <div class="font-gray">{{ $t('message.departure') }}: </div>
                                    <div class="font-black">{{ getDateFrom() }} ({{ getDeparture() }})</div>
                                </div>
                                <div style="display: flex;padding-bottom: 12px;">
                                    <div class="font-gray">{{ $t('message.price') }}: </div>
                                    <div class="font-black">$ {{ getPrice() }}</div>
                                </div>
                                <div style="display: flex;padding-bottom: 12px;">
                                    <div class="font-gray">{{ $t('message.selectedSeat') }}: </div>
                                    <div class="font-black">{{ seats.length }}</div>
                                </div>
                                <div style="display: flex;padding-bottom: 90px;">
                                    <div class="font-gray">{{ $t('message.seatNumber') }}: </div>
                                    <div class="font-black">{{ seatLabel }}</div>
                                </div>

                                <div class="btn-total">
                                    <span style="padding-right: 10px; font-size: 16px;">{{ $t('message.totalFare') }}:
                                    </span>
                                    <span class="total_price">${{ resultTotal }}</span>
                                </div>
                                <div class="btn-continue" @click="continueDetail">
                                    {{ $t('message.continue') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>

import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import axios from 'axios';
import qs from 'qs';
import FormCard from '../froms/FormCard.vue';
export default {
    components: { VueperSlides, VueperSlide, FormCard },
    data() {
        return {
            heightFalse: false,
            noResult: false,
            selectNational: false,
            falseSale: false,
            direction: true,
            showForm: false,
            loading: true,
            loading1: false,
            info: [],
            description: "",
            transportationType: "",
            transportRouteDisplay: "",
            transportationPhoto: "",
            boardingPointAddress: "",
            dropOffPointAddress: "",
            dialogInfo: false,
            slide: false,
            linkMapD: "",
            linkMapB: "",
            selecttion: 0,
            slides: [],
            amenities: [],
            dialogSeat: false,
            infoSeat: [],
            errored: false,
            rows: 0,
            colunms: 0,
            arraydata: [],
            unavailables: [],
            gender: [],
            seats: [],
            seatname: [],
            resultTotal: 0,
            selectedSeat: false,
            unava: false,
            total: 0,
            seatLabel: '',
            seatType: '',
            // center: { lat: parseFloat(localStorage.getItem("boardingPointLats")), lng: parseFloat(localStorage.getItem("boardingPointLongs")) },
            // zoom: 10,
            // boardingPointLats:'',
            // boardingPointLongs:'',
            boardingPointList: [],
            dropOffPointList: [],
            dialogWidth: '75%',
            lang: ''
        }
    },
    computed: {

    },
    mounted() {

        this.getDestinationFrom();
        this.getdestinationToName();
        this.handleDialogResize();
        window.addEventListener('resize', this.handleDialogResize);
    },
    watch: {
        '$i18n.locale': {
            handler(newLang) {
                this.lang = newLang;
                this.departureFrom = this.getLocalizedName(this.departureFrom);
                this.destinationTo = this.getLocalizedName(this.destinationTo);
            },
            immediate: true
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleDialogResize);
    },
    methods: {
        generateMapUrl(latitude, longitude) {
            // console.log(latitude);
            return `<iframe src = "https://maps.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&amp;output=embed" style="border: none; height: 400px; width: 100%;"></iframe>`;
        },
        getParagraphs(description) {
            // Split the description into paragraphs using \n as delimiter
            return description.split('\n').map(paragraph => paragraph.trim());
        },
        // Start Get Data
        getDestinationFrom() {
            if (this.lang == 'en') {
                return localStorage.getItem('departureFromName');
            } else if (this.lang == 'kh') {
                return localStorage.getItem('departureFromNameKh');
            } else if (this.lang == 'ch') {
                return localStorage.getItem('departureFromNameCn');
            }
        },
        getDestinationFromId() { return localStorage.getItem('departureFromId'); },
        getdestinationToName() {
            if (this.lang == 'en') {
                return localStorage.getItem('destinationToName');
            } else if (this.lang == 'kh') {
                return localStorage.getItem('destinationToNameKh');
            } else if (this.lang == 'ch') {
                return localStorage.getItem('destinationToNameCn');
            }
        },
        getdestinationToId() { return localStorage.getItem('destinationToId'); },
        getDateFrom() { return localStorage.getItem('dateFrom'); },
        getDeparture() {
            if (localStorage.getItem('departure')) {
                const [hours, minutes] = localStorage.getItem('departure').split(':');
                return `${hours}:${minutes}`;
            }
        },
        getPrice() { return localStorage.getItem('price'); },
        // End Get Data
        convertTime(time) {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}h`;
        },
        convertTimes(time) {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}`;
        },
        showFormBook() {
            this.showForm = true;
            this.direction = false;
            localStorage.setItem("book-again", 1);
        },

        // Start action modal
        closeTripInfo() {
            localStorage.removeItem('boardingPointLats');
            localStorage.removeItem('boardingPointLongs');
            localStorage.removeItem('dropOffPointLats');
            localStorage.removeItem('dropOffPointLongs');
            // this.selectedID = 0;
            this.dialogInfo = false;
        },
        fixscrollshow() {
            const htmlElement = document.querySelector('html');
            htmlElement.classList.add('fix-scroll');
        },
        removefixscroll() {
            const htmlElement = document.querySelector('html');
            htmlElement.classList.remove('fix-scroll');
        },
        closeIconDislog() {
            this.seats = []
            this.seatname = [];
            this.unavailables = [];
            this.gender = [];
            this.resultTotal = 0;
            this.seatLabel = '';
            this.selectedSeat = false;
            this.removefixscroll();
            localStorage.removeItem("seat");
            localStorage.removeItem("seatlabel");
            this.dialogSeat = false;
        },
        // openGoogleMaps(lat, lng) {
        //     const url = `https://maps.google.com/maps?q=${lat},${lng}&hl=es;z=14&amp;output=embed`;
        //     window.open(url, '_blank');
        // },
        modalTripInfo(description, slides, transportationPhoto, amenities, dropOffPointLats, dropOffPointLongs, boardingPointLats, boardingPointLongs, boardingPointAddress, dropOffPointAddress, boardingPointList, dropOffPointList) {
            if (slides != 0) {
                this.slide = false;
                this.slides = slides;
                this.selecttion = 3;
                this.dialogInfo = true;
            } else {
                this.slide = true;
                this.selecttion = 3;
                this.dialogInfo = true;
                this.transportationPhoto = transportationPhoto;
            }
            this.description = this.getParagraphs(description);
            this.amenities = amenities;
            localStorage.setItem('boardingPointLats', boardingPointLats);
            localStorage.setItem('boardingPointLongs', boardingPointLongs);
            localStorage.setItem('dropOffPointLats', dropOffPointLats);
            localStorage.setItem('dropOffPointLongs', dropOffPointLongs);
            this.selecttion = 0;
            this.boardingPointAddress = boardingPointAddress;
            this.dropOffPointAddress = dropOffPointAddress;
            this.boardingPointList = boardingPointList;
            this.dropOffPointList = dropOffPointList;
            this.dialogInfo = true;
            this.fixscrollshow();

        },
        boaringPointMap(description, slides, transportationPhoto, amenities, boardingPointLats, boardingPointLongs, dropOffPointLats, dropOffPointLongs, boardingPointAddress, dropOffPointAddress, boardingPointList, dropOffPointList) {
            if (slides != 0) {
                this.slide = false;
                this.slides = slides;
                this.selecttion = 3;
                this.dialogInfo = true;
            } else {
                this.slide = true;
                this.selecttion = 3;
                this.dialogInfo = true;
                this.transportationPhoto = transportationPhoto;
            }
            this.description = this.getParagraphs(description);
            this.amenities = amenities;
            // this.boardingPointLats = boardingPointLats;
            // this.boardingPointLongs = boardingPointLongs;
            localStorage.setItem('boardingPointLats', boardingPointLats);
            localStorage.setItem('boardingPointLongs', boardingPointLongs);
            localStorage.setItem('dropOffPointLats', dropOffPointLats);
            localStorage.setItem('dropOffPointLongs', dropOffPointLongs);
            this.selecttion = 1;
            // this.linkMapB = `<iframe src = "https://maps.google.com/maps?q=${boardingPointLats},${boardingPointLongs}&hl=es;z=14&amp;output=embed" style="border: none; height: 400px; width: 100%;"></iframe>`;
            // this.linkMapB = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.6053871463396!2d104.91812631494577!3d11.580121247010268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTHCsDM0JzQ4LjQiTiAxMDTCsDU1JzEzLjEiRQ!5e0!3m2!1sen!2sin!4v1552721202513@" + boardingPointLats + ',' + boardingPointLongs;
            this.boardingPointAddress = boardingPointAddress;
            this.dropOffPointAddress = dropOffPointAddress;
            this.boardingPointList = boardingPointList;
            this.dropOffPointList = dropOffPointList;
            // console.log(this.boardingPointList);
            this.dialogInfo = true;
            this.fixscrollshow();

        },
        setBorad() {
            let boardingPointLats = localStorage.getItem('boardingPointLats');
            let boardingPointLongs = localStorage.getItem('boardingPointLongs');
            this.linkMapB = `<iframe src = "https://maps.google.com/maps?q=${boardingPointLats},${boardingPointLongs}&hl=es;z=14&amp;output=embed" style="border: none; height: 400px; width: 100%;"></iframe>`;
            // this.$nextTick(() => {
            //     // Access the element and set its innerHTML
            //     this.$refs.mapboading.innerHTML = this.linkMapB;
            // });
            // this.linkMapB = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.6053871463396!2d104.91812631494577!3d11.580121247010268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTHCsDM0JzQ4LjQiTiAxMDTCsDU1JzEzLjEiRQ!5e0!3m2!1sen!2sin!4v1552721202513@" + localStorage.getItem('boardingPointLats') + ',' + localStorage.getItem('boardingPointLongs');
        },
        dropOffPointMap(description, slides, transportationPhoto, amenities, dropOffPointLats, dropOffPointLongs, boardingPointLats, boardingPointLongs, boardingPointAddress, dropOffPointAddress, boardingPointList, dropOffPointList) {

            if (slides != 0) {
                this.slide = false;
                this.slides = slides;
                this.selecttion = 3;
                this.dialogInfo = true;
            } else {
                this.slide = true;
                this.selecttion = 3;
                this.dialogInfo = true;
                this.transportationPhoto = transportationPhoto;
            }
            this.description = this.getParagraphs(description);
            this.amenities = amenities;
            localStorage.setItem('dropOffPointLats', dropOffPointLats);
            localStorage.setItem('dropOffPointLongs', dropOffPointLongs);
            localStorage.setItem('boardingPointLats', boardingPointLats);
            localStorage.setItem('boardingPointLongs', boardingPointLongs);
            this.selecttion = 2;
            // this.linkMapD = `<iframe src = "https://maps.google.com/maps?q=${dropOffPointLats},${dropOffPointLongs}&hl=es;z=14&amp;output=embed" style="border: none; height: 400px; width: 100%;"></iframe>`;
            // this.$nextTick(() => {
            //     // Access the element and set its innerHTML
            //     this.$refs.mapdropoff.innerHTML = this.linkMapD;
            // });

            // this.linkMap = `https://www.google.com/maps/search/${dropOffPointLats},${dropOffPointLongs}/@${dropOffPointLats},${dropOffPointLongs},14z/data=!3m1!4b1?hl=es;z%3D14&entry=ttu`;
            // this.linkMapD = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.6053871463396!2d104.91812631494577!3d11.580121247010268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTHCsDM0JzQ4LjQiTiAxMDTCsDU1JzEzLjEiRQ!5e0!3m2!1sen!2sin!4v1552721202513@" + dropOffPointLats + ',' + dropOffPointLongs;
            this.boardingPointAddress = boardingPointAddress;
            this.dropOffPointAddress = dropOffPointAddress;
            this.dropOffPointList = dropOffPointList;
            this.boardingPointList = boardingPointList;
            this.dialogInfo = true;
            this.fixscrollshow();
        },
        setDrop() {
            let dropOffPointLats = localStorage.getItem('dropOffPointLats');
            let dropOffPointLongs = localStorage.getItem('dropOffPointLongs');
            this.linkMapD = `<iframe src = "https://maps.google.com/maps?q=${dropOffPointLats},${dropOffPointLongs}&hl=es;z=14&amp;output=embed" style="border: none; height: 400px; width: 100%;"></iframe>`;
            // this.$nextTick(() => {
            //     // Access the element and set its innerHTML
            //     this.$refs.mapdropoff.innerHTML = this.linkMapD;
            // });
            // this.linkMapD = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.6053871463396!2d104.91812631494577!3d11.580121247010268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTHCsDM0JzQ4LjQiTiAxMDTCsDU1JzEzLjEiRQ!5e0!3m2!1sen!2sin!4v1552721202513@" + localStorage.getItem('dropOffPointLats') + ',' + localStorage.getItem('dropOffPointLongs');
        },
        modalGallery(description, slides, transportationPhoto, amenities, boardingPointLats, boardingPointLongs, dropOffPointLats, dropOffPointLongs, boardingPointAddress, dropOffPointAddress, boardingPointList, dropOffPointList) {
            this.description = this.getParagraphs(description);
            this.amenities = amenities;
            localStorage.setItem('dropOffPointLats', dropOffPointLats);
            localStorage.setItem('dropOffPointLongs', dropOffPointLongs);
            localStorage.setItem('boardingPointLats', boardingPointLats);
            localStorage.setItem('boardingPointLongs', boardingPointLongs);
            if (slides != 0) {
                this.slide = false;
                this.slides = slides;
                this.selecttion = 3;
                this.dialogInfo = true;
            } else {
                this.slide = true;
                this.selecttion = 3;
                this.dialogInfo = true;
                this.transportationPhoto = transportationPhoto;
                this.dropOffPointAddress = dropOffPointAddress;
            }
            this.boardingPointAddress = boardingPointAddress;
            this.dropOffPointList = dropOffPointList;
            this.boardingPointList = boardingPointList;
            this.fixscrollshow();
        },
        // End action modal

        // Start List schedule from api
        async getDataSchedule() {
            const data = {
                date: localStorage.getItem('dateFrom'),
                destinationFrom: localStorage.getItem('departureFromId'),
                destinationTo: localStorage.getItem('destinationToId'),
                nationally: localStorage.getItem('selectNational'),
                type: localStorage.getItem('types'),
                session: localStorage.getItem('session_start_login'),
            };
            console.log(data);
            const url = this.$url + `schedule/listByDate`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url,
            };
            this.loading = true;

            axios(config).then((response) => {
                // console.log(response);
                this.loading = false;
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.info = response.data.body;
                        if (this.info.length == 1) {
                            this.heightFalse = true;
                        } else {
                            this.heightFalse = false;
                        }

                        if (this.info == 0) {
                            this.noResult = true;
                        } else {
                            this.noResult = false;
                        }
                    } else {
                        this.errored = true;
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        // End List shcedule from api
        // Start process select to seat
        async bookSeat(id, price, departure, duration, arrival, transportationType, priceOriginal, transportRouteDisplay, discount, disPercent) {
            this.transportationType = transportationType;
            this.transportRouteDisplay = transportRouteDisplay;
            localStorage.setItem('journeyid', id);
            localStorage.setItem('price', price);
            localStorage.setItem('departure', departure);
            localStorage.setItem('arrival', arrival);
            localStorage.setItem('duration', duration);
            localStorage.setItem('priceOriginal', priceOriginal);
            localStorage.setItem('discount', discount);
            localStorage.setItem('disPercent', disPercent);

            this.dialogSeat = true;
            this.fixscrollshow();
            await this.getDateLayout();
        },
        // End process select to seat

        // Start action seat
        addClick(value, label) {
            this.seats.push(value);
            this.seatname.push(label);
            localStorage.setItem('seat', this.seats);
            localStorage.setItem('seatlabel', this.seatname);
            this.selectedSeat = true;

            if (localStorage.getItem('seatlabel') != '') {
                let size = localStorage.getItem('seatlabel');
                let seatArray = size.split(",");
                let lblSeat = "";
                for (let i = 0; i < seatArray.length; i++) {
                    if (seatArray[i] != ",") {
                        if (i > 0) {
                            lblSeat += ", ";
                        }
                        lblSeat += seatArray[i];
                    }
                }
                this.seatLabel = lblSeat;
            }


            let calTotal = this.seats.length;
            let seatP = localStorage.getItem('price');
            let totalAmount = parseFloat(calTotal * seatP);
            this.resultTotal = totalAmount;
            localStorage.setItem('resultTotal', this.resultTotal);

        },
        getUnva(item) {
            let check = true;
            for (let j = 0; j < this.unavailables.length; j++) {

                if (item == this.unavailables[j]) {
                    check = true
                    break;
                } else {
                    check = false;
                }
            }
            if (this.unavailables.length == 0) {
                return false;
            }
            return check;
        },
        getSeat(item) {
            let check = true;

            for (let j = 0; j < this.seats.length; j++) {

                if (item == this.seats[j]) {
                    check = true
                    break;
                } else {
                    check = false;
                }
            }

            return check;
        },
        deleteSeat(value, label) {

            if (this.seats.length == 1) {
                this.selectedSeat = false;
            }

            for (let j = 0; j < this.seats.length; j++) {
                if (this.seats[j] == value) {
                    this.seats.splice(j, 1);
                    let calTotal = this.seats.length;
                    let seatP = localStorage.getItem('price');
                    let totalAmount = parseFloat(calTotal * seatP);
                    this.resultTotal = totalAmount;
                    localStorage.setItem('resultTotal', this.resultTotal);
                    localStorage.setItem('seat', this.seats);
                }
            }

            for (let j = 0; j < this.seatname.length; j++) {
                if (this.seatname[j] == label) {
                    this.seatname.splice(j, 1);
                    localStorage.setItem('seatlabel', this.seatname);
                }
            }

            if (this.seats.length == 0) {
                this.selectedSeat = false;
            }

            if (this.seatname.length == 0) {
                this.selectedSeat = false;
            }

            if (localStorage.getItem('seatlabel') != '') {
                let size = localStorage.getItem('seatlabel');
                let seatArray = size.split(",");
                let lblSeat = "";
                for (let i = 0; i < seatArray.length; i++) {
                    if (seatArray[i] != ",") {
                        if (i > 0) {
                            lblSeat += ", ";
                        }
                        lblSeat += seatArray[i];
                    }
                }
                this.seatLabel = lblSeat;
            } else {
                this.seatLabel = '';
            }
        },
        // Start action seat

        // continue detail
        continueDetail() {
            if (localStorage.getItem('seat') != null && localStorage.getItem('seatlabel') != null) {
                if (localStorage.getItem('dateTo') != null) {
                    this.removefixscroll();
                    this.$router.push("bus-list-back");
                } else {
                    if (localStorage.getItem('seat') == "" && localStorage.getItem('seatlabel') == "") {
                        alert(this.$t('message.pleaseSelectSeat'));
                    } else {
                        this.removefixscroll();
                        this.$router.push("/passenger-info");

                    }
                }
            } else {
                alert(this.$t('message.pleaseSelectSeat'));
            }
        },

        // Start Get Data From Api Layout Seat
        async getDateLayout() {
            const data = { 'date': localStorage.getItem('dateFrom'), 'journey': localStorage.getItem('journeyid') };

            const url = this.$url + `seat/layout`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };

            this.loading1 = true;
            axios(config).then(response => {

                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.loading1 = false;
                        this.infoSeat = response.data.body;

                        let layout = JSON.parse(this.infoSeat[0]['layout']);

                        this.arraydata = JSON.parse(this.infoSeat[0]['layout']);
                        this.colunms = layout.length;

                        this.seatType = this.infoSeat[0]['seatType'];

                        let colspan = [];
                        for (let i = 0; i < this.arraydata[0]['col'].length; i++) {

                            colspan.push(this.arraydata[0]['col'][i]);
                            if (this.arraydata[0]['col'][i]['attr']['colspan'] != '') {

                                for (let j = 0; j < this.arraydata[0]['col'][i]['attr']['colspan'] - 1; j++) {
                                    colspan.push({
                                        attr: {
                                            colspan: '',
                                            rowspan: '',
                                        },
                                        value: '',
                                        label: ''
                                    });
                                }
                            }
                        }

                        this.arraydata.splice(0, 1, { col: colspan, row: '' });

                        this.rows = layout[0]['col'].length;

                        this.getDataUnavailable();
                    } else {
                        this.errored = true;
                    }
                }
            }).catch(error => {
                console.error('API call failed:', error); // Log any API errors
            })
        },
        // End Get Data From Api Layout Seat
        getGender(item) {
            let genStr = "";
            for (let j = 0; j < this.unavailables.length; j++) {
                if (item == this.unavailables[j]) {
                    if (this.gender[j] == "1") {
                        genStr = "(M)";
                    } else {
                        genStr = "(F)";
                    }
                    break;
                } else {
                    genStr = "";
                }
            }
            if (this.unavailables.length == 0) {
                return genStr;
            }
            return genStr;
        },

        limitStr(string, limit) {
            let str = string;

            if (typeof str === 'string' && str.length > limit) {
                str = str.slice(0, limit) + '...';
            }

            return str;
        },

        // Get Data From Api Unavailable
        async getDataUnavailable() {
            const data = { 'date': localStorage.getItem('dateFrom'), 'journey': localStorage.getItem('journeyid') };
            const url = this.$url + `seat/unavailable`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };

            axios(config).then(response => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        let data = response.data.body;
                        let size = response.data.body.length;

                        if (response.data.body != 0) {
                            for (let i = 0; i < size; i++) {
                                this.unavailables.push(data[i]['seatNumber']);
                                this.gender.push(data[i]['gender']);
                            }
                        }

                        if (localStorage.getItem('seat') != null) {

                            let arrayNew = localStorage.getItem('seat');
                            let seatArray = arrayNew.split(",");

                            for (let i = 0; i < seatArray.length; i++) {

                                if (seatArray[i] != ',') {
                                    this.seats.push(seatArray[i]);
                                }

                            }
                            this.selectedSeat = true;
                        }

                        if (localStorage.getItem('seatlabel') != null) {

                            let arrayNew = localStorage.getItem('seatlabel');
                            let seatArray = arrayNew.split(",");

                            for (let i = 0; i < seatArray.length; i++) {

                                if (seatArray[i] != ',') {
                                    this.seatname.push(seatArray[i]);
                                }

                            }
                            this.selectedSeat = true;
                        }

                    } else {
                        this.errored = true
                    }

                }

            }).catch(error => {
                console.log(error);
            })
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        handleDialogResize() {
            const width = window.innerWidth;

            if (width >= 1900) {
                this.dialogWidth = '65%';
            } else if (width >= 1200) {
                this.dialogWidth = '75%';
            } else if (width >= 768) {
                this.dialogWidth = '85%';
            } else {
                this.dialogWidth = '95%';
            }
        }
    },
    created() {
        this.lang = localStorage.getItem('lang');
        this.scrollToTop();
        localStorage.setItem("handleBrowser", 1);
        localStorage.setItem('selectNationalCam', 'true');
        if (localStorage.getItem('flashSale') == 1) {
            this.falseSale = true;
        } else {
            this.falseSale = false;
        }
        if (localStorage.getItem('selectNational') == 1) {
            this.selectNational = true;
        } else {
            this.selectNational = false;
        }
        if (localStorage.getItem("seat") == null && localStorage.getItem("seatlabel") == null) {
            this.selectedSeat = false;
        }
        setTimeout(() => {
            this.getDataSchedule();
        }, 1000);
    },
}
</script>

<style scoped>
.description {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.row .pt-2 {
    flex-wrap: nowrap;
    align-items: start;
}

.text {
    /* margin-top: 15px; */
    line-height: 1.6;
    font-size: 16px;
}

.shedule-list {
    padding-top: 100px;
    padding-bottom: 50px;
    background: #f2f2f2;
}

.sort {
    /* border: 1px solid #C6C6C6; */
    border-top: 1px solid #C6C6C6;
    border-bottom: 1px solid #C6C6C6;
    /* border-radius: 8px; */
    background: #FFFFFF;
}

.carousel-arrows {
    position: absolute;
    top: 48%;
    transform: translateY(-48%);
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.right-arrow {
    position: absolute;
    right: 2% !important;
}

.left-arrow {
    position: absolute;
    left: 2% !important;
}

.arrow {
    font-size: 24px;
    color: white;
    background-color: #FD7A23;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.width-img {
    width: 50%;
}

.width-bus-logo-type {
    /* width: 35%; */
    padding-left: 6px;
}

.arrow:hover {
    background-color: #FD7A23;
    ;
}

@media(width < 960px) {
    .pding-12 {
        padding: 12px;
    }

    /* .hide-col {
        display: none;
    } */

    .show-form {
        padding-top: 120px;
        height: 300px;
    }
}

@media(width < 950px) {
    .show-form {
        padding-top: 0px;
        margin-bottom: 40px;
        height: 300px;
    }
}

.text-des {
    font-weight: 400;
    font-size: 15px;
    color: #1D1D1D;
    padding-top: 15px;
    padding-bottom: 15px;
}

.text-des img {
    cursor: pointer;
}

.card-top {
    width: 100%;
    border: 1px solid #D2D2D7;
    border-radius: 12px;
    margin-top: 20px;
    background: #fff;
}

.padd-list {
    margin-top: 20px;
    margin-bottom: 20px;
}

.hei-set {
    height: 300px;
}

@media(min-width: 1740px) {
    .hei-set {
        padding-bottom: 0px;
        height: 500px;
    }
}

@media (min-width: 2649px) {
    .dialog-seat {
        width: 65% !important;
    }
}

.busImg {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.busImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.bus-logo {
    /* padding: 0px 0px 10px 6px; */
    width: 48px;
    height: 48px;
}

.bus-logo img {
    /* padding-right: 10px; */
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.carousel-container {
    width: 100%;
    height: 100%;
}

.text-air-bus .title-bus {
    font-weight: 600;
    font-size: 13px;
    color: #1D1D1D;
    line-height: 1;
    width: auto;
    padding-top: 6px;
}

.departure-duration-arrival {
    display: flex;
    align-items: center;
}

/* .departure, .duration, .arrival {
        width: 33.33%;
        
    } */
.departure {
    width: 33.33%;
    display: flex;

}

.duration {
    width: 34.33%;
    display: flex;
}

.arrival {
    width: 32.33%;
}

@media (min-width: 1550px) and (max-width: 2649px) {

    .departure,
    .duration {
        justify-content: space-evenly;

    }
}

.line-left-img {
    margin-top: 38px;
}

.line-right-img {
    margin-top: 39px;
    margin-left: 5px;
}

.incl-style .tittle_departure {
    font-size: 14px;
    font-weight: 600;
    color: #424245;
    padding-bottom: 10px;
}

.incl-style .time_depa {
    font-size: 24px;
    font-weight: 600;
    color: #1D1D1D;
    padding-bottom: 10px;
}

.boarding-no-drop-off-trpinfo {
    display: flex;
    /* align-items: center; */
}

.boarding-no-drop-off-trpinfo .four {
    width: 25%;
}

.boarding-no-drop-off-trpinfo .four1 {
    width: 50%;
}

.incl-flex {
    display: flex;
    /* align-items: end; */
}

.incl-flex img {
    padding-right: 5px;
}

.text-style {
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    color: #6A6969;
}

.incl-flex :hover.text-style {
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    color: #4248e6;
}

.text-styleNo {
    font-weight: 600;
    font-size: 12px;
    color: #6A6969;
}

.number_seat {
    font-weight: 600;
    font-size: 15px;
    color: #1D1D1D;
}

.price_seat {
    font-weight: 600;
    font-size: 35px;
    color: #1D1D1D;
    padding-top: 13px;
    padding-bottom: 13px;
}

.btn-book {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    border-radius: 4px;
    background: #DE5D0A;
    line-height: 40px;
    text-align: center;
    border: 1px solid #DE5D0A;
}

.btn-book-air {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    border-radius: 4px;
    background: #662D91;
    line-height: 40px;
    text-align: center;
    border: 1px solid #662D91;
}

.btn-leaving {
    cursor: not-allowed;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    border-radius: 4px;
    background: #D1D0D0;
    line-height: 40px;
    text-align: center;
    border: 1px solid #D1D0D0;
}

/* Responsive */
/* Modal */
.close--more--info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    border-bottom: 2px solid #6E6E73;
}

.close--more--info .more-title {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
}

.close--more--info .icon--close {
    width: 100%;
    text-align: end;
    cursor: pointer;
}

.description {
    font-weight: 600;
    font-size: 16px;
    color: #1D1D1D;
    overflow-y: auto
}

.text {
    font-weight: 400;
    font-size: 14px;
}

.set-w-h {
    /* width: 855px; */
    height: 500px;
    margin-top: 20px;
}

@media(width < 1110px) {
    .set-w-h {
        width: 100%;
    }
}

.amenities {
    display: flex;
    flex-wrap: wrap;
}

.air_bus {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
}

.air_bus .text {
    font-weight: 600;
    font-size: 10px;
    color: #000000;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.slide {
    border: 1px solid yellow;
    /* width: 855px; */
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    transition: all .3s;
    border-radius: 12px;
}

.vueperslide__title {
    font-size: 36px;
    color: #fff;
    font-weight: 600;
    position: absolute;
    bottom: 15%;
}

.slide img {
    border: 1px solid green;
    border-radius: 12px;
    width: 100%;
    height: 420px;
}

.carousel-controls__button {
    cursor: pointer;
    background: tomato;
    border: 0;
    color: #fff;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 18px;
}

.carousel-controls {
    display: flex;
    justify-content: end;
}

.carousel-controls .nav-info {
    width: 60%;
    text-align: end;
    font-size: 14px;
    font-weight: 600;
    color: #312783;
}

.carousel-controls .img {
    width: 5%;
    cursor: pointer;
    text-align: end;
}

.carousel-controls img {
    width: 30px;
    height: 100%;
}

.departing_icon {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 20px 70px;
    border-bottom: 1px solid #6A6969;
    font-size: 16px;
    font-weight: 600;
}

.departing_icon .icon {
    cursor: pointer;
}

.theme--light.v-icon {
    color: #312783;
}

.rows {
    display: flex;
    justify-content: space-between;
    padding: 20px 70px;
}

.seat {
    width: 50%;
}

.choose-seat {
    width: 85%;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}

.choose-seat .text {
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    padding-left: 8px;
    padding-top: 5px;
}

.seat-color {
    width: 33.33%;
    display: flex;
}

.seat-colors-gray {
    width: 24px;
    height: 24px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background: #D9D9D9;
    padding-right: 10px;
    border: 1px solid #D9D9D9;
}

.seat-colors-blue {
    width: 24px;
    height: 24px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background: #312783;
    padding-right: 10px;
    border: 1px solid #312783;
}

.seat-colors-green {
    width: 24px;
    height: 24px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background: #FF0000;
    border: 1px solid #FF0000;
}

.prices {
    width: 50%;
}

.info-data {
    padding-left: 70px;
}

.font-gray {
    font-weight: 600;
    font-size: 14px;
    color: #424245;
    width: 30%;
}

/* .col-lg-3{
        flex: 0 0 20% !important;
        max-width: 20% !important;
       
    } */

.font-black {
    font-weight: 700;
    font-size: 14px;
    color: #1D1D1D;
}

.btn-total {
    border: 1px solid #D9D9D9;
    padding: 10px 20px;
    border-radius: 8px;
    background: #D9D9D9;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 20px;
}

.total_price {
    color: #1D1D1D;
    font-size: 24px;
}

.btn-continue {
    cursor: pointer;
    padding: 15px 20px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    background: #DE5D0A;
    color: #fff;
    border-radius: 8px;
}

.table_reposive {
    width: 100%;
}

.table_reposive tr td img {
    cursor: pointer;
}

.noSide {
    font-size: 30px;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.noSide img {
    width: 100%;
    height: 100%;
}

@media(min-width: 1740px) {
    .set-heights {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
    }
}

.ipad-screen {
    display: none;
}

/* @media(width < 1110px) {
        .col-md-3{
            flex: 0 0 25% !important;
            max-width: 25% !important;
            object-fit: cover;
        }
    } */

@media(width < 1024px) {
    .ipad-screen-hide {
        display: none;
    }

    .ipad-screen {
        padding: 25px;
        display: block;
    }

    .-p {
        padding: 10px;
    }

    .info-data {
        padding-left: 0px;
    }

    .col-md-3 {
        flex: 0 0 25% !important;
        max-width: 25% !important;
        object-fit: cover;
    }
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .shedule-list {
        padding-top: 100px;
        padding-bottom: 30px;
    }

    .card-top {
        margin-top: 10px;
    }

    .departure-duration-arrival {
        flex-direction: column;
        align-items: flex-start;
    }

    .departure,
    .duration,
    .arrival {
        width: 100%;
        margin-bottom: 15px;
    }

    .boarding-no-drop-off-trpinfo {
        flex-direction: column;
    }

    .boarding-no-drop-off-trpinfo .four,
    .boarding-no-drop-off-trpinfo .four1 {
        width: 100%;
        margin-bottom: 15px;
    }

    .price_seat {
        font-size: 28px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .btn-book,
    .btn-book-air,
    .btn-leaving {
        height: 36px;
        line-height: 36px;
        font-size: 13px;
    }
}

/* Tablet Responsive Styles */
@media (min-width: 769px) and (max-width: 1024px) {
    .shedule-list {
        padding-top: 120px;
    }

    .departure {
        width: 30%;
    }

    .duration {
        width: 40%;
    }

    .arrival {
        width: 30%;
    }

    .boarding-no-drop-off-trpinfo .four {
        width: 33.33%;
    }

    .boarding-no-drop-off-trpinfo .four1 {
        width: 66.67%;
    }
}

/* Adjust seat selection modal for mobile */
@media (max-width: 768px) {
    .rows {
        flex-direction: column;
        padding: 15px;
    }

    .seat,
    .prices {
        width: 100%;
    }

    .choose-seat {
        width: 100%;
        flex-wrap: wrap;
    }

    .seat-color {
        width: 50%;
        margin-bottom: 10px;
    }

    .container-table {
        width: 100% !important;
        overflow-x: auto;
    }
}

.dialog-seat {
    transition: max-width 0.3s ease;
}

@media (max-width: 1200px) {
    .dialog-seat {
        max-width: 85% !important;
    }
}

@media (max-width: 768px) {
    .dialog-seat {
        max-width: 95% !important;
    }
}
</style>