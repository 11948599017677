<template>
    <div class="travel_his">
        <!-- <div class="container-fluid" style="background: #f2f2f2;"> -->
            <div class="containers">
                <div class="row header-profile">
                    <div class="d-flex"
                        style="border-bottom: 1px solid #C6C6C6; width: 100%; margin-left: 6px; margin-right: 6px;">
                        <!-- <div class="account-ticket account-ticket-1" :class="{ active: activeTab == '1' }" @click="toggleTab('1')">
                            Account Ticket
                        </div> -->
                        <div class="account-ticket" style="" :class="{ active: activeTab == '1' }"
                            @click="toggleTab('1')">
                            {{ $t('message.travelPackageHistory') }}
                        </div>
                    </div>
                    <div v-show="activeTab == 1" class="travel-package-history  mt-8"
                        :style="{ height: listHistory && listHistory.length === 0 ? 'auto' : 'auto', paddingBottom: listHistory && listHistory.length === 0 ? '0px' : '10vh' }">
                        <div v-if="listHistory && listHistory.length > 0">
                            <div class="history-record" v-for="(item, i) in listHistory" :key="i">
                                <div class="row">
                                    <!-- <h3 class="title-package">{{ item.packageName }}</h3> -->
                                    <h3 class="title-package">{{ lang == 'en' ? item.packageName : lang == 'kh' ? item.packageNameKh :
                                             lang == 'ch' ? item.packageNameCn :
                                             item.packageName }}</h3>
                                </div>
                                <div class="row mt-10">
                                    <div style="width: 160px; height: 160px; overflow: hidden;">
                                        <img :src="getImageUrl(item.photo)"
                                            :alt="item.packageName || 'Travel Package Photo'" width="160" height="160"
                                            @error="handleImageError" style="object-fit: cover; width: 100%; height: 100%; border-radius: 100%;">
                                    </div>
                                    <div class="information-travel" style=" margin-left: 20px;">
                                        <ul >
                                            <li>{{ item.name }}</li>
                                            <li v-if="item.telephone">{{ item.telephone }}</li>
                                            <li v-if="item.email">{{ item.email }}</li>
                                            <li >{{ $t('message.price') }} ${{ item.packagePrice }}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mt-8 inf-invoice">
                                    <div class="col-lg-10 col-md-10 col-sm-10 info-package-buy d-flex justify-content-start">
                                        <div class="img-car">
                                            <img src="@/assets/travel_package/truck.svg" alt="">
                                        </div>
                                        <div class="title-car ml-5 pl-2">
                                            <div class="">
                                                <span>{{ $t('message.codetravel') }}: {{ item.packageCode }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('message.issuedate') }}: {{ item.packageDate }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('message.exprieddate') }}: {{ item.packageExpired }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-6" style="padding: 0px;">
                                    <div class="col-lg-10 col-md-12 col-sm-12 mb-3" style="padding: 0px;">
                                        <span style="font-weight: bold; color: #312783;">{{ $t('message.termCondition')
                                            }}:</span>
                                        <div class="mt-2" style="color: #424245;">
                                            <ul style="padding-left: 0; list-style-type: none;">
                                                <!-- <li v-for="(paragraph, index) in getParagraphs(item.termCondition)"
                                                    :key="index" v-html="paragraph" style="list-style-type: none;"></li> -->
                                                <li v-for="(paragraph, index) in getParagraphs(
                                                    lang == 'en' ? item.termCondition :
                                                        lang == 'kh' ? item.termConditionKh :
                                                        lang == 'ch' ? item.termConditionCn :
                                                        item.termCondition
                                                )" :key="index" v-html="paragraph"></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="no-data">
                            <div class="text-center" style="margin-top: 10%;">
                                <img src="@/assets/travel_package/not-found.png" alt="">
                                <h3 style="color: #DE5D0A;">{{ $t('message.dataNotFound') }}</h3>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        <!-- </div> -->

    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            activeTab: 1,
            copyStatus: 'message.copy',
            listHistory: [],
            loading: false,
            lang: localStorage.getItem("lang"),
        }
    },
    computed: {

    },
    watch: {
        '$i18n.locale': {
            handler(newLang) {
                this.lang = newLang;
                this.getTravelPacakageHistory();
            },
            immediate: true
        }
    },
    methods: {
        getParagraphs(description) {
            // Split the description into paragraphs using \n as delimiter
            return description.split('\n').map(paragraph => paragraph.trim());
        },
        Copytitle(text) {
            this.copyStatus = 'message.copied';
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);

            // Select and copy the text from the textarea
            textarea.select();
            document.execCommand('message.copy');

            // Remove the textarea from the DOM
            setTimeout(() => {
                this.copyStatus = 'message.copy';
            }, 1000); // You can adjust the delay as needed
            document.body.removeChild(textarea);

        },
        async getTravelPacakageHistory() {
            try {
                this.loading = true;
                const url = this.$url + `travel-package/packageBuyList`;
                const data = {
                    'session': localStorage.getItem('session_start_login'),
                }

                const config = {
                    method: "POST",
                    headers: {
                        ...this.$header,
                        "Content-Type": "multipart/form-data"
                    },
                    data: data,
                    url,
                };

                const response = await axios(config);

                if (response.status === 200 && response.data.header.result && response.data.header.statusCode === 200) {
                    // Reverse the array to show newest items first
                    this.listHistory = response.data.body.reverse();

      
                }
            } catch (error) {
                console.error("Error fetching travel package history:", error);
            } finally {
                this.loading = false;
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getImageUrl(photo) {
            if (!photo) {
                return require('@/assets/profile/default-profile.svg');
            }

            // If the photo is a full URL
            if (photo.startsWith('http://') || photo.startsWith('https://')) {
                return photo;
            }

            // If it's a relative path, prepend your API base URL
            return this.$url + photo;
        },

        handleImageError(e) {
            e.target.src = require('@/assets/profile/default-profile.svg');
        },
    },

    created() {
        this.scrollToTop();
    }
}
</script>
<style scoped>
.travel_his {
    padding-top: 55px;
}

.header-profile {
    padding-top: 35px;
    box-sizing: border-box;

}

.inf-account .tel {
    color: #1D1D1D !important;
    font-size: 16px;
}

.inf-account .number {
    color: #312783 !important;
    font-size: 16px;
}

.account-ticket-history {
    margin-left: 65px;
}

.account-ticket-history ul li {
    padding-bottom: 4px;
}

.account-ticket.active {
    border-bottom: 3px solid #312783;
    margin-left: 30px;
}

.account-ticket:hover {
    color: #312783 !important;
}

.box-account {
    background: #FF0000;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 15px 7px 15px;
}

.count-ticket {
    position: relative;
}

.count-ticket div {
    position: absolute;
    left: 32%;
    top: 35%;
}

.title-car div {
    padding: 2px;
}

.account-ticket {
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    color: #000000;
    overflow-y: auto;

}

.travel-package-history {
    margin-left: 65px;
    width: 100%;
}

.travel-package-history .title-package {
    color: #312783;
}

.information-travel ul {
    padding-left: 20px;
    margin: 0;
    list-style-type: disc;
}

.information-travel ul li {
    margin-bottom: 8px;
    color: #6A6969;
    padding: 1px;
}

.info-package-buy {
    background-color: #312783;
    color: #FFFFFF;
    padding: 12px 10px 12px 25px;

}

.copy-past {
    cursor: pointer;
}

/* Add these responsive styles */
@media screen and (max-width: 768px) {
  .travel_his {
    padding-top: 30px;
  }

  .travel-package-history {
    margin-left: 15px;
    margin-right: 15px;
  }

  .header-profile {
    padding-top: 55px;
    box-sizing: border-box;

  }

  .account-ticket {
    font-size: 14px;
    padding: 8px;
  }
  .account-ticket.active {
    border-bottom: 3px solid #312783;
    margin-left: 8px;
}

  .history-record {
    padding: 8px;
    width: 100%;
  }

  .row.mt-10 {
    flex-direction: column;
  }

  .information-travel {
    margin-top: 15px !important;
    margin-left: 0 !important;
  }

  .info-package-buy {
    padding: 10px;
    /* flex-direction: column; */
  }

  .title-car {
    margin-left: 0 !important;
    margin-top: 10px;
  }

  .img-car {
    justify-content: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .travel-package-history {
    margin-left: 30px;
    margin-right: 30px;
  }

  .info-package-buy {
    padding: 15px;
  }
}

@media screen and (min-width: 1025px) {


  .history-record {
    max-width: 1200px;
  }
}

/* Add these base styles */
.history-record {
  /* background: #fff; */
  /* padding: 20px; */
  margin-bottom: 20px;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
}

/* Remove or comment out any styles that might be conflicting */
/* .col-10 ul {
  list-style-type: none !important;
  padding-left: 0;
} */

.title-car div {
  margin-bottom: 5px;
}

/* Fix image responsiveness */
.history-record img {
  max-width: 100%;
  height: auto;
}

/* Add these styles */
/* .col-10 ul {
  list-style-type: none !important;
  padding-left: 0;
} */

.col-10 ul li {
  margin-bottom: 8px;
}

/* Update existing styles */
.information-travel ul {
  padding-left: 20px;
  margin: 0;
  list-style-type: disc;
}

.information-travel ul li {
  margin-bottom: 8px;
}

</style>