<template>
    <div class="banners">
        <div class="banners_image">
            <div class="containers">
                <div class="row align-items-center" style="flex-wrap: nowrap;">
                    <div class="col-md-5" style="display: flex; justify-content: center; align-items: end; flex-direction: column; padding: 0%;">
                        <div class="mobile_preview_container">
                            <img src="@/assets/banners/app4.png" alt="Mobile App Preview" class="mobile_preview">
                        </div>
                    </div>
                    <div class="col-md-7" style="display: flex; justify-content: center; align-items: center; flex-direction: column; padding: 0%;">
                        <div class="download_section">
                            <h1 style="color: white;">{{ $t('message.experience') }}</h1>
                            <h2 style="color: white; padding-bottom: 2rem;">{{ $t('message.vetExpressMobileApp') }}</h2>
                            <div class="store_buttons">
                                <div class="d-flex flex-column">
                                    <a href="https://bit.ly/2IhMiZ8" target="_blank" rel="noopener noreferrer">
                                        <img src="@/assets/banners/google-play.png" alt="Play Store" class="store_button">
                                    </a>
                                    <a href="https://apple.co/2GG57Vq" target="_blank" rel="noopener noreferrer">
                                        <img src="@/assets/banners/app-store.png" alt="App Store" class="store_button">
                                    </a>
                                </div>
                                <div>
                                    <span class="or">{{ $t('message.or') }}</span>
                                </div>
                                <div>
                                    <img src="@/assets/banners/banner app-02.png" alt="QR Code" class="qr_code">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BannersImg'
}
</script>

<style scoped>
.banners {
    height: 450px;
}

.banners_image {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #DE5D0A 0%, #322783 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile_preview {
    height: 450px;
    object-fit: contain;
}

.download_section {
    color: white;
}

.store_buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.store_button {
    height: 60px;
    cursor: pointer;
    transition: transform 0.2s;
}

.store_button:hover {
    transform: scale(1.05);
}

.or {
    color: white;
    font-size: 18px;
}

.qr_code {
    height: 120px;
    margin-left: 20px;
}
@media (max-width: 1024px) {
    .mobile_preview {
        height: 370px;
    }
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 18px;
    }
    .col-md-5 {
        padding-left: 0px !important;
    }
    .store_button {
        height: 50px;
    }
    .qr_code  {
        height: 100px;
        margin-left: 0px;
    }

    .store_buttons {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .mobile_preview {
        height: 230px;
    }
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 18px;
    }
    .col-md-5 {
        padding-left: 0px !important;
    }
    .store_button {
        height: 30px;
    }
    .qr_code  {
        height: 100px;
        margin-left: 0px;
    }

    .store_buttons {
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .mobile_preview {
        height: 170px;
    }
    h1 {
        font-size: 18px;
    }
    h2 {
        font-size: 14px;
    }
    .col-md-5 {
        padding-left: 0px !important;
    }
    .store_button {
        height: 30px;
    }
    .qr_code  {
        height: 70px;
        margin-left: 0px;
    }
}
@media screen and (max-width: 450px) {
.banners {
    display: none;
}
}
</style>