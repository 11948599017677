<template>
    <div>
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div v-else>
            <BackGroImage />
            <FlashSale />
            <!-- <ListServices/> -->
            <AdsPopup />
            <!-- <CarouselSide /> -->
            <ChooseUs />
            <BannersImg />
            <ExploreTrend />
            <TopCambodia />
            <!-- <ListCompanySubsidiary /> -->
        </div>
    </div>
</template>

<script>
import BackGroImage from '@/components/backgroundImages/BackGroImage.vue';
import FlashSale from '@/components/FlashSale/FlashSale.vue';
// import CarouselSide from '@/components/carousel/carouselSide.vue';
import ChooseUs from '@/components/chooseus/ChooseUs.vue';
import BannersImg from '@/components/banners/BannersImg.vue';
import ExploreTrend from '@/components/explore/ExploreTrend.vue';
import TopCambodia from '@/components/explore/TopCambodia.vue';
import AdsPopup from '@/components/ads/AdsPopup.vue';
// import axios from 'axios';
// import qs from 'qs';
// import ListCompanySubsidiary from '@/components/subsidiary/ListCompanySubsidiary.vue';
export default {
    components: {
        BackGroImage,
        FlashSale,
        // CarouselSide,
        ChooseUs,
        BannersImg,
        ExploreTrend,
        TopCambodia,
        AdsPopup,
        // ListCompanySubsidiary,
        // ListServices 
    },
    data() {
        return {
            loading: true,
            reloadHome: "",
        }
    },
    mounted() {
        // Ensure clean history state
        window.history.replaceState(null, '', '/');
        
        // Clear any remaining popstate handlers
        window.onpopstate = null;
    },
    methods: {

        clearStorage() {
            localStorage.removeItem("departureFromName");
            localStorage.removeItem("departureFromId");
            localStorage.removeItem("destinationToName");
            localStorage.removeItem("destinationToId");
            localStorage.removeItem("dateFrom");
            localStorage.removeItem("dateTo");

            localStorage.removeItem("price");
            localStorage.removeItem("departure");
            localStorage.removeItem("arrival");
            localStorage.removeItem("duration");
            localStorage.removeItem("journeyid");
            localStorage.removeItem("priceOriginal");

            localStorage.removeItem("priceback");
            localStorage.removeItem("departureback");
            localStorage.removeItem("durationback");
            localStorage.removeItem("arrivalback");
            localStorage.removeItem("journeyidback");
            localStorage.removeItem("priceOriginalback");

            localStorage.removeItem("totalluckydraw");
            localStorage.removeItem("resultTotal");
            localStorage.removeItem("resultTotalBack");

            localStorage.removeItem("seat");
            localStorage.removeItem("seatlabel");
            localStorage.removeItem("seatback");
            localStorage.removeItem("seatlabelback");

            localStorage.removeItem("trips");
            localStorage.removeItem('book-again');

            localStorage.removeItem("email");
            localStorage.removeItem("phone");


            localStorage.removeItem('boardingPointLats');
            localStorage.removeItem('boardingPointLongs');
            localStorage.removeItem('dropOffPointLats');
            localStorage.removeItem('dropOffPointLongs');

            localStorage.removeItem('discount');
            localStorage.removeItem('disPercent');
            localStorage.removeItem('discountBack');
            localStorage.removeItem('disPercentBack');
            localStorage.removeItem("showNewContent");
            localStorage.removeItem("genderBack");


            // clear confirm booking
            localStorage.removeItem("boardingPointId");
            localStorage.removeItem("dropOffPointId");
            localStorage.removeItem("boardingPointIdBack");
            localStorage.removeItem("dropOffPointIdBack");
            localStorage.removeItem("gender");
            localStorage.removeItem("transactionId");

            localStorage.removeItem("flashSale");
            localStorage.removeItem("selection1");
            localStorage.removeItem("selection2");
            localStorage.removeItem("selection3");
            localStorage.removeItem("selection4");

            localStorage.removeItem('codeTravelpk');
            localStorage.removeItem('pricetravelPk');

            localStorage.removeItem('emailUser');
            // localStorage.removeItem('tokenUser');

        }
    },

    created() {
        this.clearStorage();
        // Set Reload All Page
        localStorage.setItem('reloadCompanyProfile', 'false');
        localStorage.setItem('reloadRestore', 'false');
        localStorage.setItem('reloadRetal', 'false');
        localStorage.setItem('reloadBlog', 'false');
        localStorage.setItem('reloadGallers', 'false');
        localStorage.setItem('reloadTerm', 'false');
        localStorage.setItem('reloadPrivacy', 'false');
        localStorage.removeItem("handleBrowser");

        // Clear browser history state when landing on home page
        window.history.replaceState(null, '', '/');
        
        localStorage.setItem('selectNational', 1);
        localStorage.setItem('selectNationalCam', 'false');

        this.reloadHome = localStorage.getItem('reloadHome');
        if (this.reloadHome != 'true') {
            localStorage.setItem('reloadHome', 'true');
        }
        if (localStorage.getItem('reloadHome') == 'true') {
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    },
}
</script>

<style></style>