<template>
    <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else>

        <div class="pay-success" style=" background-color: #F2F2F2;">
            <div class="containers">
                <div class="payment-success" v-if="noResult">
                    <div class="payment-completed">
                        <p>Payment Not Completed</p>


                    </div>
                </div>
                <div v-else class="payment-success">
                    <div class="payment-completed">
                        <p style=" font-weight: 600;">{{ $t('message.paymentComplete') }}</p>

                    </div>
                </div>
                <div class="thank-you">
                    <p style=" font-weight: 600; margin-bottom: 10px !important;">{{ $t('message.thaxUrPayment') }}
                        {{ info.email }}.</p>

                    <div class="resend-email">
                        <p style=" font-weight: 600;">{{ $t('message.desResendEmail') }}
                            <!-- <v-btn
                                class="ma-2"
                                outlined
                                color="#312783"
                                style="border-radius: 8px; text-transform: none !important;"
                                :disabled="disable"
                                @click="Resendemail()"
                                depressed
                            
                                >
                                <span style="color:#DE5D0A"> {{ $t(resendemailtitle) }}</span>
                            </v-btn> -->
                        </p>
                        <div class="d-flex" style="margin-top: -8px; margin-bottom: 8px; margin-left: -7px;">
                            <div>
                                <v-btn class="ma-2" color="#0086FF"
                                    style="border-radius: 2px; padding: 20px; text-transform: none !important;"
                                    :disabled="disable" @click="Resendemail()" depressed>
                                    <span style="color:#FFFFFF"> {{ $t(resendemailtitle) }}</span>
                                </v-btn>

                            </div>
                            <!-- //button download -->
                            <!-- <v-btn  class="ma-2" color="#DE5D0A" style="border-radius: 2px; padding: 20px; text-transform: none !important; color: #FFFFFF;"  :disabled="disable" @click="downloadFile">Download PDF</v-btn> -->
                            <div>
                                <!-- <v-btn
                                    class="ma-2"
                                    color="#DE5D0A"
                                    style="border-radius: 2px; padding: 20px; min-width: 100%; text-transform: none !important;"
                                    :disabled="disable"
                                    @click="Resendemail()"
                                    depressed
                                
                                    >
                                    <span style="color:#FFFFFF"> Download</span>
                                </v-btn> -->
                            </div>
                        </div>
                    </div>
                    <div class="resend-email">
                        <div style=" font-weight: 600; padding-bottom: 6px;"><span style="color: #FF0000;">*</span> {{ $t('message.incaseyoustillhavenotreceivedyoureticketpleasecontactusvia') }}: </div>
                        <div style=" font-weight: 600; padding-bottom: 6px;"> {{$t('message.email')}}: support@vireakbuntham.com</div>
                        <div style=" font-weight: 600; padding-bottom: 20px;"> {{$t('message.hotline')}}: (+855) 81 911 911</div>

                    </div>
                    <div class="booking-detail">
                        <div class="trip-info-details">
                            <div class="detail" style="padding: 16px 10px 0px 16px;">{{ $t('message.tripDetail') }}
                            </div>
                            <div class="dev-color-detail-trip" v-for="(ticket, index) in tickets" :key="index">
                                <div v-if="index > 0" style="padding: 10px 0px 5px 0px">
                                    <div style="border: 1px solid #666B80; opacity: 0.4;"></div>
                                </div>
                                <div class="booking d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{ $t('message.ticketCode') }}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%; color: #0086FF;">
                                        {{ ticket.ticketCode }}
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{ $t('message.destinationFrom') }}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%; ">
                                        {{ ticket.destinationFrom }}
                                        <span style="color: #0086FF;cursor: pointer;">
                                            <a target="_blank"
                                                :href="'https://maps.google.com/maps?q=' + ticket.boardingPointLats + ',' + ticket.boardingPointLongs">
                                                ({{ $t('message.viewMap') }})</a>
                                        </span>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{ $t('message.travelDate') }}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%;">
                                        {{ ticket.travelDate }} {{ ticket.departure }}
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{ $t('message.telephoneNumber') }}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%;">
                                        {{ ticket.boardingPointTelephone }}
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{$t('message.departure')}} {{$t('message.name')}}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%;">
                                        {{ ticket.boardingPoint }}
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{ $t('message.departureAddress') }}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%;">
                                        {{ ticket.boardingPointAddress }}
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{ $t('message.destinationTo') }}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%;">
                                        {{ ticket.destinationTo }}
                                        <span style="color: #0086FF;cursor: pointer;"> <a target="_blank"
                                                :href="'https://maps.google.com/maps?q=' + ticket.dropOffPointLats + ',' + ticket.dropOffPointLongs">
                                                ({{ $t('message.viewMap') }})</a></span>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" v-if="ticket.arrivalDate != null"
                                    style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{$t('message.arrivaldate')}}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%;">
                                        {{ ticket.arrivalDate }}
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{ $t('message.telephoneNumber') }}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%;">
                                        {{ ticket.dropOffPointTelephone }}
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{ $t('message.departurename') }}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%;">
                                        {{ ticket.dropOffPoint }}
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                    <div class="d-flex justify-space-between" style="width: 35%;">
                                        <div>
                                            {{ $t('message.arrivaladdress') }}
                                        </div>
                                        <div style="font-weight: 700;">
                                            :
                                        </div>
                                    </div>
                                    <div style="width: 60%;">
                                        {{ ticket.dropOffPointAddress }}
                                    </div>
                                </div>
                                <div class="detail" style="padding-top: 12px;">{{ $t('message.passengerDetail') }}</div>

                                <table style="width: 100%; padding: 10px 10px 10px 16px;">
                                    <thead>
                                        <tr>
                                            <th class="text-left">{{ $t('message.seat') }} </th>
                                            <th class="text-left">{{$t('message.name')}}</th>
                                            <th class="text-left">{{ $t('message.gender') }}</th>
                                            <th class="text-left">{{ $t('message.nationality') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(seat, i) in ticket.seat" :key="i">
                                            <td style="font-weight: 600;">#{{ seat.seatLabel }}</td>
                                            <td>{{ seat.name }}</td>
                                            <td>{{ seat.gender }}</td>
                                            <td>{{ seat.nationalityName }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="booking-info-details">
                            <div style="width: 100%; border: 1px solid #666B80; border-radius: 6px;">
                                <div class="detail" style="padding: 16px 10px 0px 16px;">{{ $t('message.bookingDetail')
                                    }}</div>
                                <div class="dev-color-detail-trip">
                                    <div class="booking d-flex justify-space-between"
                                        style="padding: 0px 10px 10px 16px">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div>
                                                {{ $t('message.bookingId') }}
                                            </div>
                                            <div style="font-weight: 700;">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            {{ info.transactionId }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div>
                                                {{ $t('message.bookingDate') }}
                                            </div>
                                            <div style="font-weight: 700;">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            {{ info.transactionDate }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div>
                                                {{ $t('message.email') }}
                                            </div>
                                            <div style="font-weight: 700;">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            {{ info.email }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div>
                                                {{ $t('message.telephoneNumber') }}
                                            </div>
                                            <div style="font-weight: 700;">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            {{ info.telephone == "" || info.telephone == null ? " " : info.telephone }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div>
                                                {{ $t('message.paymentType') }}
                                            </div>
                                            <div style="font-weight: 700;">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            {{ info.payment }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div>
                                                {{ $t('message.grandTotal') }}
                                            </div>
                                            <div style="font-weight: 700;">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            $ {{ formatPrice(info.totalAmount) }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px"
                                        v-if="info.totalDiscount !== '' || info.totalDiscount !== 0">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div class="d-flex">
                                                {{ $t('message.discount') }}
                                                <div v-if="!returnTrip" style="padding-left: 2px;">
                                                    ({{ getDiscountPercent() }}%)
                                                </div>
                                            </div>
                                            <div style="font-weight: 700;">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            $ {{ formatPrice(getDiscount()) }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between" v-if="info.luckyDrawFee != 0"
                                        style="padding: 0px 10px 10px 16px">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div>
                                                {{ $t('message.luckyTicket') }}
                                            </div>
                                            <div style="font-weight: 700; ">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            $ {{ formatPrice(info.luckyDrawFee) }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between"
                                        style="padding: 0px 10px 10px 16px; display: none !important;">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div>
                                                Service Fee (3%)
                                            </div>
                                            <div style="font-weight: 700;">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            $ 0.4
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between" style="padding: 0px 10px 10px 16px">
                                        <div class="d-flex justify-space-between" style="width: 35%;">
                                            <div>
                                                {{ $t('message.total') }}
                                            </div>
                                            <div style="font-weight: 700;">
                                                :
                                            </div>
                                        </div>
                                        <div style="width: 60%; color: #2F354D;">
                                            $ {{ formatPrice(totalPlus) }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between" v-if="info.luckyDrawFee != 0"
                                        style="padding: 0px 10px 10px 16px">
                                        <span class="titles"><span style="color: #FF0000;"> *</span> <span
                                                style="color: #0086FF;">{{ $t('message.plsShowUrETicket') }}</span>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="box-more">
                        <button @click="goHome"
                            style="margin-top: 20px;font-size: 20px;color: #0086FF;font-weight: 600;"><i
                                class="fa-solid fa-arrow-left"></i> {{$t('message.bookmore')}}</button>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
export default {
    data() {
        return {
            loading: false,
            info: [],
            totalPlus: 0,
            tickets: [],
            returnTrip: false,
            seats: 0,
            noResult: false,
            resendemailtitle: 'message.rendEmail',
            disable: false,
            showLine: false
        }
    },
    methods: {
        async checkTransaction() {
            this.loading = true;
            const data = {
                'transactionId': this.$route.query.transactionId,
            }
            const url = this.$url + `booking/checkTransaction`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };

            try {
                const response = await axios(config);
                this.loading = false;

                if (response.status == 200 &&
                    response.data.header.result == true &&
                    response.data.header.statusCode == 200) {

                    this.info = response.data.body;

                    if (response.data.body.data == 0) {
                        this.noResult = true;
                    } else {
                        this.noResult = false;
                        let subTotal = parseFloat(response.data.body.totalAmount) - parseFloat(response.data.body.totalDiscount);
                        let totalVat = parseFloat(response.data.body.totalVat);
                        let totalluckeydraw = parseFloat(response.data.body.luckyDrawFee);
                        let total = subTotal + totalVat + totalluckeydraw;
                        this.totalPlus = total;
                        this.tickets = response.data.body.ticket;

                        // Check if this is a round trip based on number of tickets
                        this.returnTrip = this.tickets.length > 1;
                    }
                }
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        async Resendemail() {
            this.disable = true;
            this.resendemailtitle = "Loading";
            const data = {
                'transactionId': this.$route.query.transactionId,
            }
            const url = this.$url + `booking/ticketSendEmail`;
            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };
            axios(config).then((response) => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.disable = false;
                        this.resendemailtitle = "message.rendEmail";
                        if (response.data.body.status == 1) {
                            alert(response.data.body.message);
                        } else {
                            alert(response.data.body.message);
                        }

                    }
                }
            }).catch((error) => {
                console.log(error);
            });

        },
        formatPrice(value) { let val = (value / 1).toFixed(2); return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); },
        goHome() {
            this.$router.push("/");
        },

        //file download PDF
        downloadFile() {
            const transactionId = this.$route.query.transactionId;
            const url = `${this.$url}payment-success?transactionId=${transactionId}`;

            // Create a hidden link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ticket.pdf');  // Optional: Set the filename for the download

            // Append the link to the document body and trigger the download
            document.body.appendChild(link);
            link.click();

            // Remove the link after triggering the download
            document.body.removeChild(link);
        },

        getDiscountPercent() {
            if (!this.info.totalDiscount || !this.info.totalAmount) return 0;
            const discountPercent = (this.info.totalDiscount / this.info.totalAmount) * 100;
            return discountPercent;
        },

        getDiscount() {
            return this.info.totalDiscount || 0;
        },

        // Check roundTrip
        getCheck() {
            this.returnTrip = localStorage.getItem('dateTo') !== null;
        },
    },
    created() {
        this.checkTransaction();
        this.getCheck();
    },
    mounted() {
        window.onpopstate = () => {
            this.goHome();
        };
    },
    beforeUnmount() {
        window.onpopstate = null;
    },
}
</script>

<style scoped>
.payment-success {
    border-radius: 8px;
    margin-top: 100px;
    /* padding-bottom: 50px; */
    margin-bottom: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.pay-success {
    padding-top: 30px;
    padding-bottom: 5px;
}

.payment-completed {
    padding-top: 10px;
    padding-left: 10px;
    border-bottom: 1px solid rgb(217, 211, 211);
}

.payment-completed p {
    font-size: 25px;
    font-weight: 600;

}

.resend-email {
    /* padding-left: 20px; */
    /* padding-top: 5px; */
}

.thank-you {
    padding-left: 20px;
    padding-top: 20px;
}

.booking-detail {
    /* padding-left: 20px; */
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
}

.box-more {
    /* padding-left: 20px; */
    text-transform: uppercase;
    /* padding-top: 20px;
        padding-right: 20px; */
    padding: 20px 20px 20px 0px;
}

.box-more button {
    text-transform: uppercase;
}

.booking-detail .detail {
    color: #0c2f54;
    font-weight: 600;
    font-size: 16px;
    /* text-decoration: underline; */
    padding: 16px 10px 0px 16px;
}

.booking {
    margin-top: 15px;
    /* display: fslex; */
}

.booking .titles {
    font-weight: 400;
    font-size: 14px;
    /* width: 10%; */
}


.booking .value {
    font-size: 16px;
    /* width: 90%; */
}

.trip-details {
    padding-top: 20px;
}

.trip-info-details {
    width: 49%;
    border-radius: 6px;
    border: 1px solid #666B80;
}

.booking-info-details {
    width: 49%;
    /* border-radius: 6px; */
    /* border: 1px solid #666B80; */
}

.dev-color-detail-trip div {
    color: #666B80;
    font-weight: 600;
}

@media (width<950px) {
    .pay-success {
        padding-top: 5px;
    }

    .payment-success {
        margin-top: 60px;
    }
}

@media screen and (max-width: 700px) {
    .booking-detail {
        display: block !important;
    }

    .booking-info-details {
        width: 100% !important;
    }

    .trip-info-details {
        width: 100% !important;
        margin-bottom: 10px;
    }

    .booking-detail,
    .box-more {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
}

@media screen and (max-width: 500px) {

    .thank-you p,
    .resend-email p {
        font-size: 14px !important;

    }

    /* .booking-detail , .box-more{
            padding-left: 4px !important;
            padding-right: 4px !important;
        } */
}
</style>