<template>
    <div v-if="noResult" class="norecord"></div>
    <div v-else class="bg-flash">
        <div class="containers">
            <div class="flash-header">
                <p class="flash-sale">
                    {{ $t('message.flashSale') }}
                    <span>
                        <!-- <v-icon color="#0066FF">mdi-flash</v-icon> -->
                        <img src="@/assets/icon_bars/icon-flashsele.svg" alt=" fashsale">
                    </span>
                </p>
                <button class="show-more-btn" @click="toggleShowMore">

                    {{ showAll ? $t('message.seeLess') : $t('message.seeMore') }}
                </button>
            </div>
            <!-- :class="{ 'pt-2': flashSale.scheduleType == 3 }" -->
            <!-- Updated cards container -->
            <div class="cards-container">
                <v-card v-for="(flashSale, index) in displayedFlashSales" :key="index" class="flash-card"
                    style="border-radius: 12px; position: static !important;" outlined>
                    <div class="flex-header">
                        <span class="imgs">
                            <img v-if="flashSale.scheduleType == 1" src="@/assets/vireak-buntham.png" alt="">
                            <img v-if="flashSale.scheduleType == 3" src="@/assets/airbus.png" alt="">
                        </span>
                        <span class="set-font" :class="{ 'pt-2': flashSale.scheduleType == 3 }">
                            {{  flashSale.description }}
                        </span>
                    </div>

                    <div class="apply-period">
                        {{ $t('message.applyPeriod') }}: {{ flashSale.applyPeriod }}
                    </div>

                    <div class="price-section" v-if="flashSale.promotionPrice < flashSale.price">
                        <img src="@/assets/icon_bars/price-tag.jpg" alt="">
                        <span class="sale-price">${{ flashSale.promotionPrice }}</span>
                        <span class="original-price">${{ flashSale.price }}</span>
                    </div>

                    <button class="book-now-btn" :class="{ 'active': index === 0 }" @click="bookSeat(flashSale.destinationFrom, flashSale.destinationFromName,
                        flashSale.destinationTo, flashSale.destinationToName,
                        flashSale.journeyId)">
                        {{ $t('message.bookNow') }}
                    </button>
                </v-card>

            </div>
            <div v-if="showAll" class="show-less-container">
                <hr>
                <div class="show-less-wrapper">
                    <button class="show-less-btn" @click="toggleShowMore">
                        {{ $t('message.seeLess') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
// import qs from 'qs';
export default {
    data() {
        return {
            showAll: false,
            showImgArrRight: true,
            ShowImgArrLeft: false,
            flashSales: [],
            noResult: true,
            lang: localStorage.getItem("lang"),
        }
    },
    computed: {
        displayedFlashSales() {
            if (this.showAll) {
                return this.flashSales;
            }
            return this.flashSales.slice(0, 4);
        }
    },
    methods: {
        toggleShowMore() {
            this.showAll = !this.showAll;

            // Optional: Scroll to top when clicking "Show Less"
            if (!this.showAll) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
        showArrow() {
            this.ShowImgArrLeft = true;
            this.showImgArrRight = true;
        },
        showHide() {
            this.ShowImgArrLeft = true;
            this.showImgArrRight = true;
        },
        async getFlashSale() {
            if(this.lang == 'en'){
                this.lang = 'en';
            }else if(this.lang == 'kh'){
                this.lang = 'kh';
            }else if(this.lang == 'ch'){
                this.lang = 'cn';
            }
            const data = {
                type: 1,
                lang: this.lang
                // 'page': 1,
                // 'rowsPerPage': 200,
                // 'total': 0,
            }
            const url = this.$url + `promotion/list`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: data,
                url
            };

            axios(config).then((response) => {
                // console.log(response);
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.flashSales = response.data.body;
                        if (response.data.body.length == 0) {
                            this.noResult = true;
                        } else {
                            this.noResult = false;
                        }
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        bookSeat(destinationFrom, destinationFromName, destinationTo, destinationToName, journeyId) {
            localStorage.removeItem("handleBrowser");
            localStorage.setItem('journeyid', journeyId);
            localStorage.setItem('departureFromId', destinationFrom);
            localStorage.setItem('departureFromName', destinationFromName);
            localStorage.setItem('destinationToId', destinationTo);
            localStorage.setItem('destinationToName', destinationToName);
            localStorage.setItem('flashSale', 1);
            localStorage.setItem("book-again", 1);
            this.$router.push("/bus-list");
        },
    },
    created() {
        setTimeout(() => {
            this.getFlashSale();
        }, 1000);
    }
}
</script>
<style scoped>
.apply-bg {
    padding-bottom: 40px;
    padding-top: 68px;
    background: #F2F2F2;
}

.bg-flash {
    /* position: relative; */
    background: #F2F2F2;
    padding-top: 3px;
    padding-bottom: 20px;

    /* margin-bottom: 28px;    */

}

/* .cards-container v-card {
    position: static !important;
} */

.flash-sale {
    font-weight: 600;
    font-size: 32px;
    color: #312783;
    padding: 12px 0px 12px 0px;
    /* padding-bottom: 20px; */
}

p {
    margin-bottom: 0;
}

.flash-sale .v-icon {
    width: 25px;
}

.norecord {
    padding-bottom: 100px;
    background: #F2F2F2;
}

.pa-t-0 {
    padding-top: 0;
}

.pad-in {
    position: relative;
    border-radius: 12px;
    padding: 10px;
    padding: 15px;
    margin-right: 10px;
}

.flex-header {
    display: flex;
    height: 35px;

}

.flex-header .imgs {
    height: 100%;
}

.flex-header img {
    width: 32px;
}

.flex-header span {
    height: 100%;
    line-height: 30px;
    font-weight: 600;
    font-size: 14px;
    color: #1D1D1D;
}

.water {
    display: flex;
    height: 35px;
}

.water .imgs {
    height: 100%;
    padding-top: 2px;
}

.water img {
    width: 25px;
}

.water .price {
    height: 100%;
    line-height: 30px;
}

.water .price p {
    color: #DE5D0A;
    font-size: 16px;
    font-weight: 700;
}

.water .discount {
    padding-left: 10px;
    padding-top: 1.60px;
    width: 58%;
    height: 100%;
    line-height: 30px;
    color: #BCBCBD;
    font-weight: 500;
    -webkit-text-decoration-line: line-through;
    /* Safari */
    text-decoration-line: line-through;
}

.water .discount p {
    color: #BCBCBD;
    font-size: 14px;
    font-weight: 400;
}

.amnity {
    display: flex;
    height: 28px;
}

.amnity .imgs {
    height: 100%;
}

.amnity img {
    width: 25px;
}

.amnity .price {
    height: 100%;
    line-height: 30px;
}

.amnity .price p {
    color: #DE5D0A;
    font-size: 16px;
    font-weight: 700;
}

.amnity .discount {
    padding-left: 10px;
    padding-top: 1px;
    width: 58%;
    height: 100%;
    line-height: 30px;
    color: #BCBCBD;
    -webkit-text-decoration-line: line-through;
    /* Safari */
    text-decoration-line: line-through;
}

.amnity .discount p {
    color: #BCBCBD;
    font-size: 14px;
    font-weight: 400;
}

.customBtn {
    cursor: pointer;
    /* position: absolute; */
    text-align: center;
    bottom: 45px;
    right: 10px;
    font-size: 12px;
    border-radius: 5px !important;
    padding: 7px 7px;
    font-weight: 600;
    color: #DE5D0A;
    border: 1px solid #DE5D0A;
}

.customBtn:hover {
    background-color: #DE5D0A;
    color: #fff;
}

/* Responsive */
@media (width < 1169px) {
    .res {
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
    }
}

@media (width < 950px) {
    .norecord {
        padding-bottom: 100px !important;
    }

    .apply-bg {
        padding-top: 65px;
        padding-bottom: 35px;
    }
}

@media (width < 621px) {
    .res {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
    }
}

@media (width < 500px) {
    .apply-bg {
        padding-bottom: 35px;
        padding-top: 65px;
    }

    .flash-sale {
        font-size: 20px;
    }

    .flash-sale .v-icon {
        width: 17px;
    }
}

.flash-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
}

.show-more-btn {
    padding-top: 2px;
    padding-right: 5px;
    background: transparent;
    border: none;
    color: #DE5D0A;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.show-more-btn:hover {
    opacity: 0.8;
    text-decoration: underline
}

/* Updated and new styles */
.cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    /* margin-top: 20px; */
}

.flash-card {
    width: 100%;
    min-width: 0;
    /* Prevents overflow */
    padding: 12px;
    /* border-radius: 12px ; */
    background: white;
    display: flex;
    flex-direction: column;
}

.flex-header {
    display: flex;
    align-items: start;
    gap: 10px;
    /* margin-bottom: 10px; */
}

.flex-header .imgs {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
}

.flex-header .set-font {
    font-weight: 600;
    font-size: 14px;
    color: #1D1D1D;
    line-height: 1.2;
    /* padding-top: 12px; */
}

.apply-period {
    color: #424245;
    font-size: 12px;
    /* margin: 8px 0; */
    padding-top: 12px;
    padding-bottom: 12px;
}

.price-section {
    display: flex;
    align-items: center;
    gap: 10px;
    /* margin: 10px 0; */
    padding-bottom: 12px;

}

.price-section img {
    width: 25px;
    height: 25px;
}

.sale-price {
    color: #DE5D0A;
    font-weight: 700;
    font-size: 16px;
}

.original-price {
    color: #BCBCBD;
    text-decoration: line-through;
    font-size: 14px;
}

.book-now-btn {
    width: 100%;
    padding: 8px;
    border: 1px solid #DE5D0A;
    border-radius: 5px !important;
    background: transparent;
    color: #DE5D0A;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;
}

/* .book-now-btn.active {
    background: #DE5D0A;
    color: white;
} */

.book-now-btn:hover {
    background: #DE5D0A;
    color: white;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .cards-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .cards-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .cards-container {
        grid-template-columns: 1fr;
    }

    .flash-sale {
        font-size: 20px;
    }

    .flash-sale .v-icon {
        width: 17px;
    }
}

/* Add these new styles */
.show-less-container {
    margin-top: 20px;
}

.show-less-container hr {
    border: none;
    border-top: 1px solid #E5E5E5;
    margin-bottom: 15px;
}

.show-less-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
}

.show-less-btn {
    background-color: #DE5D0A;
    padding: 8px 24px;
    border-radius: 4px;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: opacity 0.3s;
}

.show-less-btn:hover {
    opacity: 0.9;
}
</style>
