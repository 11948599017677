<template>
  <!-- <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div> -->

  <div class="travelpackage">
    <div class="container-fluid" style="background: #f2f2f2;">
      <div class="containers" style="padding-bottom: 70px;">
        <div class=" mb-5 travelpackageheader">
          <div class="content-package" v-for="(list, i) in listcontent" :key="i">
            <div class="quest-package">
              <h2>{{ lang == 'en' ? list.titleEn : lang == "kh" ? list.titleKh : list.titleCn }}</h2>
            </div>
            <div class="content-package-description">
              <p v-for="(paragraph, index) in getParagraphs(lang == 'en' ? list.descEn : lang == 'kh' ? list.descKh : list.descCn)"
                :key="index" v-html="paragraph"> </p>
            </div>
          </div>
          <!-- <div class="col-lg-5 col-md-5 col-sm-5">
                        <img src="@/assets/travel_package/bus.3d41a651.png" alt="" width="100%" height="100%">
                    </div> -->
        </div>
        <div class="loading-travel" v-if="loading">
          <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div v-else>
          <div v-if="listpacakge.length != 0">
            <div class="row mb-3 mt-8">
              <div v-for="(listinfo, i) in listpacakge" :key="i" class="col-lg-4 col-md-6 col-sm-12">
                <div class="package-card">
                  <div class="package-image">
                    <img style="width: 100%; height: 100%; "
                      :src="listinfo.photo == '' ? require('@/assets/placeholder-image.jpeg') : listinfo.photo" alt="">
                    <!-- <div class="duration-badge">
                                            {{ listinfo.duration }}
                                        </div> -->
                  </div>
                  <div class="package-content">
                    <p class="package-price">${{ listinfo.price }}</p>
                    <h3 class="package-title">{{ lang == 'en' ? listinfo.name : lang == 'kh' ? listinfo.nameKh :
                      listinfo.nameCn}}</h3>


                    <p class="package-description">{{ lang == 'en' ? listinfo.description : lang == 'kh' ?
                      listinfo.descriptionKh : listinfo.descriptionCn}}</p>
                    <button class="buy-now-btn" @click="registerBuyPackagetravel(listinfo.id, listinfo.photo)">
                      {{ $t('message.buyNow') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row mb-6" style="display: flex;align-items: center;justify-content: center;text-align: center;">
              <div style="display: flex;align-items: center;justify-content: center;text-align: center;"
                class="set-heights">
                <div>
                  <img src="@/assets/icon-social-media/not_found.jpg" alt="">
                  <p class="no-result">{{ $t('message.dataNotFound') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      listpacakge: [],
      linehide: true,
      lang: localStorage.getItem("lang"),
      listcontent: [],
      loading: true,
    }
  },
  methods: {
    getParagraphs(description) {
      // Handle null or undefined description
      if (!description) return [];

      // Split into paragraphs based on bullet points or double line breaks
      const paragraphs = description.split(/(?:\r?\n\s*\r?\n|\s*•\s*)/g)
        .map(paragraph => paragraph.trim())
        .filter(Boolean);

      return paragraphs;
    },
    registerBuyPackagetravel(id, photo) {
      this.$router.push({
        path: '/register-buy-package',
        query: {
          id: id,
          photo: photo,
        }
      });
      // this.$router.push("/register-buy-package");
    },
    async getContentTravelPackage() {
      const url = this.$url + `travel-package/content`;
      let config = {
        method: "POST",
        headers: this.$header,
        url,
      };

      axios(config).then((response) => {
        if (response.status == 200) {
          if (response.data.header.result == true && response.data.header.statusCode == 200) {
            this.listcontent = response.data.body;
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    async getListTravelPackage() {
      this.loading = true;
      const url = this.$url + `travel-package/list`;
      let config = {
        method: "POST",
        headers: this.$header,
        url,
      };

      axios(config).then((response) => {
        if (response.status == 200) {
          this.loading = false;
          if (response.data.header.result == true && response.data.header.statusCode == 200) {
            this.listpacakge = response.data.body;
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
  created() {
    this.scrollToTop();
    this.getContentTravelPackage();
    setTimeout(() => {
      this.getListTravelPackage();
    }, 500);
    // localStorage.setItem("handleBrowser", 1);
  }
}
</script>
<style scoped>
.content-package {
  width: 80%;
}

.content-package p {
  font-size: 14px;
}

.travelpackage {
  padding-top: 55px;

}

.left-image-package {
  width: 38%;
}

.right-image-package {
  padding: 0px 0px 0px 10px;
  width: 62%;
}

.travelpackageheader {
  padding-top: 50px;
  /* display: flex;
      justify-content: center;
      align-items: center; */
  /* padding-bottom: 70px; */
  /* box-sizing: border-box; */
}

.no-result {
  margin-top: 15px;
  font-size: 25px;
  font-weight: 600;
}

.ttitle-package {
  margin-top: 19px;
}

.quest-package {
  margin-bottom: 10px;
  font-size: 17px;
}

.loading-travel {
  text-align: center;
  line-height: 40vh;
  height: 40vh;
  z-index: 100000;
}

.quest-package h2 {
  color: #312783;

}

.box-price {
  background-color: white;
  color: #DE5D0A;
  border: 0.5px solid #DE5D0A;
  border-radius: 20px;
  padding: 0px 26px 0px 26px;
}

.box-price-btn {
  background: #E38F5A;
  color: #E38F5A;
  border: 0.5px solid #E38F5A;
  border-radius: 20px;
  cursor: pointer;
  padding: 0px 24px 0px 24px;
}

.limited-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Number of lines to show */
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-image-package p {
  margin-bottom: 2px !important;
}

.courser {
  cursor: pointer;
}

.fw-600 {
  font-weight: 600;
}

.fw-800 {
  font-weight: 700;
}

.mt-price {
  margin-top: 84px;
  font-weight: bold;
}

@media screen and (width < 950px) {

  /* .travelpackage {
          margin-top: -20px;
      } */
  .right-image-package p {
    margin-bottom: 2px !important;
    /* font-size: 10px; */
  }

  .content-package {
    width: 100%;
  }

}

@media screen and (width < 500px) {

  /* .travelpackage {
          margin-top: -20px;
      } */
  .right-image-package p {
    margin-bottom: 2px !important;
  }

  .travelpackageheader {
    padding-top: 20px;
    padding-bottom: 40px;
    opacity: 1;
  }

  .content-package {
    width: 100%;
  }
}

@media screen and (width < 499px) {
  .travelpackageheader {
    padding-top: 20px;
    padding-bottom: 0px;
    opacity: 1;
  }
}

@media screen and (width < 438px) {

  /* .travelpackage {
          margin-top: -20px;
      } */
  .content-package {
    width: 100%;
  }

  .travelpackageheader {
    padding-top: 20px;
    padding-bottom: 0px;
    opacity: 1;
  }

}

.package-card {
  background: #FFFFFF;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;

}

.package-image {
  position: relative;
  width: 100%;
  /* height: 200px; */
  overflow: hidden;
}

.package-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.duration-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #FF9F43;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: 600;
}

.package-content {
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.package-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  min-height: 54px; /* For consistent card heights */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.package-price {
  font-size: 20px;
  font-weight: 700;
  color: #DE5D0A;
  margin-bottom: 2px;
}

.package-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 63px; /* Approximately 3 lines of text */
}

.buy-now-btn {
  background: #E38F5A;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.buy-now-btn:hover {
  background: #DE5D0A;
}
</style>