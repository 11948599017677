<template>
    <div>
        <div class="tk_po">
            <div class="loading" v-if="loading"><v-progress-circular indeterminate
                    color="#de5d0a"></v-progress-circular></div>
            <div class="card-form ">
                <!-- <v-card class="p-20-tb"> -->
                <!-- <v-radio-group row mandatory v-model="radios">
                        <v-radio @click="selectCambo" color="#312783" style="font-weight: bold"
                            :label="$t('message.cambodian')" value="1"></v-radio>
                        <v-radio @click="selectNonCambo" color="#312783" style="font-weight: bold"
                            :label="$t('message.nonCambodian')" value="2"></v-radio> -->
                <!-- </v-radio-group> -->
                <!-- </v-card> -->
                <div class="row pos-card">
                    <div class="col-lg-3 col-md-3 col-custom" style="height: 75px; padding-right: 0%;">
                        <div style="position: relative;">
                            <input type="text" v-model="departureFrom" @click="selectDeparture" @keyup="searchDeparture"
                                :placeholder="$t('message.departingFrom')" class="custom_select">
                            <div style="position: absolute;top:49px;width: 100%;">
                                <div v-if="showListData"
                                    class="v-menu__content theme--light menuable__content__active v-autocomplete__content"
                                    v-click-outside="hidePopup"
                                    style="max-height: 340px;min-width: 100%;transform-origin: left top; z-index: 100;">
                                    <div v-if="emptyData" role="listbox"
                                        class="v-list v-select-list v-sheet theme--light v-list--dense theme--light">
                                        <div role="option" class="v-list-item v-list-item--link theme--light">
                                            <div class="v-list-item__content" style="padding: 13px !important;">
                                                <div class="v-list-item__title">{{ $t('message.dataNotFound') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="infoFrom.length > 0" role="listbox" v-for="item in infoFrom"
                                        :key="item.id"
                                        class="v-list v-select-list v-sheet theme--light v-list--dense theme--light">
                                        <div role="option" class="v-list-item v-list-item--link theme--light">
                                            <div class="v-list-item__content edit-list"
                                                style="padding: 13px !important;"
                                                @click="onConfirmFrom(departureFromName = item.name, departureFromId = item.id)">
                                                <div class="v-list-item__title">{{ getLocalizedName(item) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="location" @click="selectDeparture"
                                style="position: absolute;top: 8px;left: 8px;color: #c4c3c3;">
                                <img src="@/assets/icon_bars/Near me.svg" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-custom" style="height: 75px; padding-right: 0%;">
                        <div style="position: relative;">
                            <input ref='focusMe' type="text" v-model="destinationTo" @click="onClickDestination()"
                                @keyup="searchDestination" :placeholder="$t('message.goingTo')" class="custom_select">
                            <div style="position: absolute;top:49px;width: 100%;">
                                <div v-if="showListDataTo"
                                    class="v-menu__content theme--light menuable__content__active v-autocomplete__content"
                                    v-click-outside="hidePopupTo"
                                    style="max-height: 340px;min-width: 100%;transform-origin: left top; z-index: 8;">
                                    <div v-if="emptyData" role="listbox"
                                        class="v-list v-select-list v-sheet theme--light v-list--dense theme--light">
                                        <div role="option" class="v-list-item v-list-item--link theme--light">
                                            <div class="v-list-item__content" style="padding: 13px !important;">
                                                <div class="v-list-item__title">{{ $t('message.dataNotFound') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="infoTo.length > 0" role="listbox" v-for="item in infoTo"
                                        :key="item.id"
                                        class="v-list v-select-list v-sheet theme--light v-list--dense theme--light">
                                        <div role="option" class="v-list-item v-list-item--link theme--light">
                                            <div class="v-list-item__content edit-list"
                                                style="padding: 13px !important;"
                                                @click="onConfirmTo(destinationToName = item.name, destinationToId = item.id)">
                                                <div class="v-list-item__title">
                                                    {{ getLocalizedName(item) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="location" @click="onClickDestination"
                                style="position: absolute;font-size: 18px;top: 8px;left: 12px;color: #c4c3c3;">
                                <i class="fa-sharp fa-solid fa-location-dot"></i>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-2 col-custom-date" style="height: 75px; padding-right: 0%;">
                        <v-menu v-model="menu" offset-y min-width="auto">
                            <template v-slot:activator="{ attrs }">
                                <div class="reposo-height">
                                    <input ref='focusDate' class="custom_select" label="Depart Date" v-model="dateFrom"
                                        solo v-bind="attrs" @click="menu = true" readonly>
                                    <div @click="menu = true" class="icon-date"
                                        style="position: absolute;top: 9px;left: 10px;color: #c4c3c3;">
                                        <!-- <img src="@/assets/icon_bars/date.svg" alt=""> -->
                                        <i class="fa-regular fa-calendar" style="font-size: 18px;"></i>
                                    </div>
                                </div>

                            </template>
                            <v-date-picker
                                :locale="lang == 'ch' ? 'zh-cn' : lang == 'kh' ? 'en' : lang == 'en' ? 'en' : 'en'"
                                color="#de5d0a" v-model="dateFrom" :min="new Date().toISOString().substr(0, 10)"
                                @input="storeDate(dateFrom)"></v-date-picker>
                        </v-menu>
                    </div>

                    <div class="col-lg-2 col-md-2 col-custom-date" style="height: 75px; padding-right: 0%;">
                        <v-menu v-model="menu2" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <div style="position: relative;">
                                    <input class="reposo-height custom_select" :placeholder="$t('message.returnDate')"
                                        v-model="dateTo" solo readonly v-bind="attrs" v-on="on">
                                    <div>
                                        <div @click="clearDates" v-if="clearDate" class="icon-date"
                                            style="position: absolute;top: 9px;left: 10px;color: #c4c3c3;">
                                            <!-- <img src="@/assets/icon_bars/date.svg" alt=""> -->
                                            <i class="fa-regular fa-calendar" style="font-size: 18px;"></i>
                                        </div>
                                        <div @click="clearDates" v-if="clearDate" class="icon-date"
                                            style="position: absolute;top: 11px;right: 13px;color: #c4c3c3;">
                                            <i class="fa-solid fa-xmark"></i>
                                        </div>
                                        <div v-on="on" v-else class="icon-date"
                                            style="position: absolute;top: 9px;left: 10px;color: #c4c3c3;">
                                            <!-- <img src="@/assets/icon_bars/date.svg" alt=""> -->
                                            <i class="fa-regular fa-calendar" style="font-size: 18px;"></i>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <v-date-picker
                                :locale="lang == 'ch' ? 'zh-cn' : lang == 'kh' ? 'en' : lang == 'en' ? 'en' : 'en'"
                                color="#de5d0a" v-model="dateTo"
                                :min="new Date(this.dateFrom).toISOString().substr(0, 10)"
                                @input="storeDateTo(dateTo)"></v-date-picker>
                        </v-menu>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 col-buttom" style="height: 75px;">
                        <SearchButton />
                    </div>
                </div>
            </div>
        </div>
        <v-row justify="center">
            <v-dialog v-model="dialog" max-width="290">
                <v-card>
                    <v-card-title class="text-h5" text-center>Information</v-card-title>
                    <v-card-text style="display: flex;">Please Select Departure</v-card-text>
                    <v-card-actions style="display: flex;justify-content: center;">
                        <v-btn color="primary" @click="dialog = false">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import SearchButton from '../SearchButton.vue';
import axios from 'axios';
import qs from 'qs';
// import km from "../../locales/kh/km.js";
export default {
    components: { SearchButton },
    data() {
        return {
            loading: false,
            emptyData: false,
            radios: localStorage.getItem('selectNational'),
            menu: false,
            menu2: false,
            showListData: false,
            showListDataTo: false,
            dialog: false,
            clearDate: false,
            infoFrom: [],
            infoTo: [],
            lang: localStorage.getItem("lang"),
            departureFrom: localStorage.getItem('departureFromName'),
            departureFromid: "",
            destinationTo: localStorage.getItem('destinationToName'),
            destinationToId: "",
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateFrom: localStorage.getItem('dateFrom'),
            dateTo: localStorage.getItem('dateTo'),
            language: '',
        }
    },
    //     computed: {
    //         km() {
    //         return {
    //             ...km, 

    //         };
    //     },
    //   },
    computed: {
        // lang() {
        //     return this.$i18n.locale;
        // }
    },

    methods: {
        searchDeparture() {
            this.getDeparture();
        },
        searchDestination() {
            this.getDestination();
        },
        selectCambo() {
            localStorage.setItem('selectNational', 1);

            if (localStorage.getItem('selectNationalCam') == 'false') {
                // code
            } else {
                this.$router.go();
            }
        },
        selectNonCambo() {
            localStorage.setItem('selectNational', 2);

            if (localStorage.getItem('selectNationalCam') == 'false') {
                // code
            } else {
                this.$router.go();
            }
        },
        selectDeparture() {
            this.departureFrom = '';
            this.departureFromid = '';
            localStorage.removeItem('departureFromName');
            localStorage.removeItem('departureFromId');
            this.showListData = true;
            this.showListDataTo = false;
            this.getDeparture();
        },
        selectDestination() {
            this.destinationTo = '';
            this.destinationToId = '';
            localStorage.removeItem('destinationToName');
            localStorage.removeItem('destinationToId');
            this.showListDataTo = true;
            this.showListData = false;
            this.getDestination();
            this.$refs.focusMe.focus();
        },
        onConfirmFrom(departureFromName, departureFromId) {
            this.departureFrom = departureFromName;
            this.departureFromid = departureFromId;

            // Find the selected item to get all language versions
            const selectedItem = this.infoFrom.find(item => item.id === departureFromId);
            if (selectedItem) {
                // Store all language versions
                localStorage.setItem('departureFromName', selectedItem.nameEn);
                localStorage.setItem('departureFromNameKh', selectedItem.nameKh);
                localStorage.setItem('departureFromNameCn', selectedItem.nameCn);
                localStorage.setItem('departureFromId', departureFromId);
            }
            if (this.lang == 'kh') {
                this.departureFrom = selectedItem.nameKh;
            } else if (this.lang == 'ch') {
                this.departureFrom = selectedItem.nameCn;
            } else {
                this.departureFrom = selectedItem.nameEn;
            }

            this.showListData = false;
            this.selectDestination();
            this.showListDataTo = true;
        },
        onConfirmTo(destinationToName, destinationToId) {
            localStorage.removeItem('handleBrowser');
            this.destinationTo = destinationToName;
            this.destinationToId = destinationToId;

            // Find the selected item to get all language versions
            const selectedItem = this.infoTo.find(item => item.id === destinationToId);
            if (selectedItem) {
                // Store all language versions
                localStorage.setItem('destinationToName', selectedItem.nameEn);
                localStorage.setItem('destinationToNameKh', selectedItem.nameKh);
                localStorage.setItem('destinationToNameCn', selectedItem.nameCn);
                localStorage.setItem('destinationToId', destinationToId);
            }
            if (this.lang == 'kh') {
                this.destinationTo = selectedItem.nameKh;
            } else if (this.lang == 'ch') {
                this.destinationTo = selectedItem.nameCn;
            } else {
                this.destinationTo = selectedItem.nameEn;
            }

            this.showListDataTo = false;
            this.$refs.focusDate.focus();
            this.menu = true;
        },
        // getDestinationFrom() {
        //     if(this.lang == 'en') {
        //         return localStorage.getItem('departureFromName');
        //     } else if(this.lang == 'kh') {
        //         return localStorage.getItem('departureFromNameKh');
        //     } else if(this.lang == 'ch') {
        //         return localStorage.getItem('departureFromNameCn');
        //     }
        // },
        // getdestinationToName() {
        //     if(this.lang == 'en') {
        //         return localStorage.getItem('destinationToName');
        //     } else if(this.lang == 'kh') {
        //         return localStorage.getItem('destinationToNameKh');
        //     } else if(this.lang == 'ch') {
        //         return localStorage.getItem('destinationToNameCn');
        //     }
        // },
        onClickDestination() {
            this.selectDestination();
        },
        getFromDate() {
            return new Date().toISOString().substr(0, 10);
        },
        storeDate(dateFrom) {
            localStorage.setItem('dateFrom', dateFrom);
            this.dateTo = '';
            localStorage.removeItem('dateTo');
            this.clearDate = false;
        },
        storeDateTo(dateTo) {
            localStorage.setItem('dateTo', dateTo);
            this.clearDate = true;
        },
        getToDate() {
            const today = new Date().toISOString().substr(0, 10);
            this.dateTo = today;
        },
        clearDates() {
            this.dateTo = '';
            localStorage.removeItem('dateTo');
            this.clearDate = false;
        },
        async getDeparture() {

            const data = { 'searchText': this.departureFrom, 'type': localStorage.getItem('types') };
            const url = this.$url + `destination/from`;
            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };
            this.loading = true;
            axios(config).then(response => {
                this.loading = false;
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        if (response.data.body == 0) {
                            this.emptyData = true;
                        } else {
                            this.emptyData = false;
                        }
                        this.infoFrom = response.data.body;
                    }
                }
            }).catch(error => { console.log(error); })
        },
        async getDestination() {

            const data = { 'fromId': localStorage.getItem('departureFromId'), 'searchText': this.destinationTo, 'type': localStorage.getItem('types') };
            const url = this.$url + `destination/to`;
            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };
            this.loading = true;
            axios(config).then(response => {
                this.loading = false;
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        if (response.data.body == 0) {
                            this.emptyData = true;
                        } else {
                            this.emptyData = false;
                        }
                        this.infoTo = response.data.body;
                    }
                }

            }).catch(error => { console.log(error); })
        },
        showPopup() {
            this.showListData = true;
        },
        hidePopup() {
            this.showListData = false;
        },
        showPopupTo() {
            this.showListDataTo = true;
        },
        hidePopupTo() {
            this.showListDataTo = false;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getLocalizedName(item) {
            const nameMap = {
                'en': item.nameEn,
                'kh': item.nameKh,
                'ch': item.nameCn
            };
            return nameMap[this.lang] || item.nameEn; // fallback to English if language not found
        }
    },
    created() {
        this.scrollToTop();
        localStorage.setItem('types', 1);

        // Initialize departure based on selected language
        // const lang = localStorage.getItem('lang');
        // if (lang === 'kh') {
        //     this.departureFrom = localStorage.getItem('departureFromNameKh');
        // } else if (lang === 'ch') {
        //     this.departureFrom = localStorage.getItem('departureFromNameCn');
        // } else {
        //     this.departureFrom = localStorage.getItem('departureFromName');
        // }

        // Initialize destination based on selected language
        // if (lang === 'kh') {
        //     this.destinationTo = localStorage.getItem('destinationToNameKh');
        // } else if (lang === 'ch') {
        //     this.destinationTo = localStorage.getItem('destinationToNameCn');
        // } else {
        //     this.destinationTo = localStorage.getItem('destinationToName');
        // }

        if (localStorage.getItem('dateFrom') == null || localStorage.getItem('dateFrom') == '') {
            this.dateFrom = this.getFromDate();
            localStorage.setItem('dateFrom', this.getFromDate());
        }
    },
}
</script>

<style scoped>
.custom_select {
    padding-top: 2px;
    padding-left: 37px;
    outline: none;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    border: 1px solid #c6c6c6;
    background: #ffffff;
    color: #1d1d1d;
    font-weight: 500;

}

.custom_select:hover {
    cursor: pointer;
}


.list-data {
    padding-top: 10px;
    max-width: 100%;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.icon-date {
    cursor: pointer;

}

.tk_po {
    position: relative;
    width: 100%;
    height: 100%;
}

.card-form {
    color: #fff;
    position: relative;
    width: 100%;
    height: 88px;
}

.pos-card {
    width: 100%;
    margin: auto;
    position: absolute;
    padding-left: 12px;
    padding-right: 22px;
    opacity: 90%;
    height: 100%;
    padding: 12px 22px 9px 22px;
    border-radius: 8px;
    margin: auto;
    background-color: #fff;
}

.p-20-tb {
    opacity: 85%;
    width: 100%;
    height: 100%;
    padding: 12px 22px 9px 22px;
    border-radius: 8px;
    margin: auto;
}

@media (max-width: 961px) {
    .p-20-tb {
        padding-top: 12px !important;
        padding-bottom: 6px !important;
    }

    .v-input--selection-controls {
        margin-top: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 10px;
    }
}

@media (max-width: 850px) {
    .card-position {
        padding-left: 68px;
        padding-right: 68px;
    }
}

@media (max-width: 600px) {
    .card-position {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (max-width: 422px) {
    .card-position {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* @media(width < 1264px) {
        .pos-card {
            position: absolute;
            top: 20%;
        }
        .card-form {
            height: 190px;
        }
        .col-custom {
            height: 50px !important;
        }
        .col-custom-date {
            height: 55px !important;
            padding-top: 10px;
        }
        .col-buttom label {
            display: none;
        }
        .col-buttom{
            max-width: 100%;
            height: 55px !important;
            padding-top: 0px;
            padding-right: 0;
        }
    } */
/* @media(max-width: 959.60px) {
    .pos-card {
        position: absolute;
        top: 17%;
    }

    .card-form {
        height: 288px;
        position: absolute;
        top: -106px;
    }
} */

@media(max-width: 959.60px) {
    .card-form {
        height: 300px;
        position: absolute;
        top: -110px;
    }
}

@media(max-width: 960px) {
    .col-custom {
        padding-top: 0;
        height: 55px !important;
    }

    .col-custom-date {
        padding-top: 0;
        height: 55px !important;
    }
}

@media(max-width: 950px) {
    .card-form {
        height: 300px;
        position: absolute;
        top: -0px;
    }
}

@media(width < 526px) {
    .card-form {
        height: 300px;
        top: -40px;
    }

    .col-buttom {
        padding-top: 10px;
    }

    .pos-card {
        top: 20%;
    }
}

@media(width < 500px) {
    .card-form {
        height: 300px;
        top: -40px;
    }

    .col-buttom {
        padding-top: 10px;
    }

    .pos-card {
        top: 20%;
    }
}

@media(max-width: 425px) {
    .card-form {
        height: 300px;
        top: -30px;
    }

    .col-custom {
        padding-top: 0;
        height: auto;
    }

    .custom_select {
        height: 45px;
    }
}

@media(max-width: 375.60px) {
    .pos-card {
        top: 15%;
    }
}

.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
    padding: 5px !important;
}

.v-list-item--dense,
.v-list--dense .v-list-item {
    min-height: 20px !important;
}

.v-dialog>.v-card>.v-card__title {
    justify-content: center;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 11px !important;
    justify-content: center;
    align-items: center;
}

.v-progress-circular {
    margin: 1rem;
}

.loading {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.v-icon.v-icon {
    color: #000000 !important;
}

.v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.reposo-height {
    position: relative;
}

.edit-list:hover {
    background: #1867c0;
    color: #FFF;
    cursor: pointer;
    border-radius: 2px;
}

.v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    height: auto;
    color: #312783;
}
</style>