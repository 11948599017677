<template>
    <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else style="background: #F2F2F2">
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div class="passengers" v-else>
            <div class="container-fluid passenger-pd">
                <div class="containers mtop pt-20 pb-20">
                    <div class="title-booking-detail">
                        <p @click="checkName()">{{ $t('message.bookingDetail') }}</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12">
                            <div class="passDetails">
                                <div class="number">1</div>
                                <div class="title-pass">{{ $t('message.passengerDetail') }}</div>
                            </div>
                            <div class="form">
                                <div class="passenger">
                                    <div class="row hide-label-phone">
                                        <div class="col-md-5 col-sm-12">
                                            <label>{{ $t('message.fullName') }}<span
                                                    style="color: #FF0000; padding-left: 1px;"> *</span></label>
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <label>{{ $t('message.nationality') }}<span
                                                    style="color: #FF0000; padding-left: 1px;"> *</span></label>
                                        </div>
                                        <div class="col-md-3 col-sm-12">
                                            <label>{{ $t('message.gender') }} <span
                                                    style="color: #FF0000; padding-left: 1px;">*</span></label>
                                        </div>
                                    </div>
                                    <div style="margin-top: -23px;">
                                        <div class="row" v-for="(seat, index) in seatLabels" :key="seat">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="group-form">
                                                    <label for="" class="hide-label equal_label">{{
                                                        $t('message.fullName') }} <span
                                                            style="color: #FF0000; padding-left: 1px;"> *</span></label>
                                                    <input type="text" v-model="fullname[index]"
                                                        @input="autoFieldToBack(index)"
                                                        class="custom-input equals_input"
                                                        :placeholder="$t('message.enterYourfullName')">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <div class="group-form">
                                                    <label for="" class="hide-label equal_label">{{
                                                        $t('message.nationality') }}</label>
                                                    <!-- nationality one way -->
                                                    <div class="equals_input" style="position: relative;">
                                                        <div style="position: relative;" class="nationality-dropdown">
                                                            <i @click="selectNation(index)"
                                                                style="position: absolute;top: 34%;right: 9px; color: #6E6E73;"
                                                                class="fa-sharp fa-solid fa-caret-down"></i>
                                                            <input @click="selectNation(index)" @input="filterNations"
                                                                type="text" v-model="nationality[index]"
                                                                :class="{ 'is-invalid': is_register && $v.nationality.$error }"
                                                                class="custom-input"
                                                                :placeholder="$t('message.selectNationality')" />

                                                            <!-- Custom Dropdown -->
                                                            <div v-show="showListDataNation && activeNationalityItem === index"
                                                                style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px; height: 200px; overflow: auto">
                                                                <div v-for="(nation, i) in filteredNations" :key="i"
                                                                    @click="setNational(index, nation.id, nation.name)"
                                                                    class="hover-color"
                                                                    style="margin: 10px 0; cursor: pointer; padding-left: 12px; padding-top: 5px; padding-bottom: 5px;">
                                                                    {{ nation.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3">
                                                <div class="group-form">
                                                    <label for="" class="hide-label equal_label">{{ $t('message.gender')
                                                        }} <span
                                                            style="color: #FF0000; padding-left: 1px;">*</span></label>
                                                    <div class="equals_input" style="position: relative;">
                                                        <div v-if="selected[index] == 0" style="position: relative;">
                                                            <input type="text" readonly :value="$t(selectedOption)"
                                                                class="custom-input" @click="selectGender(index)">
                                                            <i style="position: absolute;top: 34%;right: 9px;"
                                                                @click="selectGender(index)"
                                                                class="fa-sharp fa-solid fa-caret-down"></i>
                                                        </div>
                                                        <div v-if="selected[index] != 0">
                                                            <div v-if="selected[index] == 1"
                                                                style="position: relative;">
                                                                <input type="text" readonly v-model="selectedOption1"
                                                                    class="custom-input" @click="selectGender(index)">
                                                                <i style="position: absolute;top: 34%;right: 9px;"
                                                                    @click="selectGender(index)"
                                                                    class="fa-sharp fa-solid fa-caret-down"></i>
                                                            </div>
                                                            <div v-if="selected[index] == 2"
                                                                style="position: relative;">
                                                                <input type="text" readonly v-model="selectedOption2"
                                                                    class="custom-input" @click="selectGender(index)">
                                                                <i style="position: absolute;top: 34%;right: 9px;"
                                                                    @click="selectGender(index)"
                                                                    class="fa-sharp fa-solid fa-caret-down"></i>
                                                            </div>
                                                        </div>
                                                        <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px;"
                                                            v-if="showListDataGen" class="hideNational"
                                                            :class="{ showNational: index == activeItem }"
                                                            v-click-outside="{
                                                                handler: hidePopupGen,
                                                                closeConditional: () => showListDataGen
                                                            }">
                                                            <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;"
                                                                class="hover-color" @click="setGender(index, 1)">{{
                                                                    $t("message.male") }}</div>
                                                            <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;"
                                                                class="hover-color" @click="setGender(index, 2)">{{
                                                                    $t("message.Female") }}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="dotted"><img style="width: 100%;" src="@/assets/line.png"
                                                    alt=""></div>
                                        </div>
                                    </div>


                                </div>
                                <div style="padding-top: 30px;">
                                    <div class="passDetails">
                                        <div class="number">2</div>
                                        <div class="title-pass">{{ $t('message.location') }} {{ getDestinationFrom() }}
                                            {{
                                                $t('message.to') }} {{ getdestinationToName() }}</div>
                                    </div>
                                    <div class="row rowScreen passenger" style="height:auto;">
                                        <div class="col-lg-12 col-md-12 col-sm-12">

                                            <label for="" class="equal_label1">{{ $t('message.boardingPoint') }} <span
                                                    style="color: #FF0000;" v-if="boardingPoint.length != 1">*</span>
                                            </label>
                                            <div variant="outlined" readonly class="custom-select" :tabindex="tabindex">
                                                <div class="selected"
                                                    :class="{ hideAfterdrop: boardingPoint.length == 1, open: open }"
                                                    @click="open = !open">
                                                    <!-- {{ limitStr(selectedBox, 35) }} -->
                                                    <div class="mobileScreen">
                                                        {{ selectedBox == "" ? this.$t('message.selectpoardingpoing') :
                                                            selectedBox }}
                                                    </div>
                                                    <!-- <div :title="selectedBox" class="mobileScreen d-none-for-mobile">
                                                        {{ limitStr(selectedBox, 50) }}
                                                    </div> -->

                                                </div>
                                                <div class="items" :class="{ selectHide: !open }"
                                                    v-if="boardingPoint.length != 1">
                                                    <div class="header-drop-point"> {{ $t('message.boardingPoint') }}
                                                    </div>
                                                    <div class="d-flex mb-3" v-for="point in boardingPoint"
                                                        :key="point.id"
                                                        @click="storeData(pointName = point.address, pointId = point.id), selectedBox = point.address + ' ' + convertTimes(point.time); open = false; $emit('input', point.name);">
                                                        <div class="checkbox-mobile"
                                                            style="margin-left: -9px; margin-right: 30px;">
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" disabled
                                                                    :checked="selectedCheckbox == point.id"
                                                                    :value="point.id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="right-info-name-map">
                                                            <div class="d-flex justify-space-between"
                                                                style="padding-bottom: 8px;">
                                                                <div class="name-width">
                                                                    <span style="color: #E38F5A; font-weight: 600;">{{
                                                                        point.name }} {{ convertTimes(point.time)
                                                                        }}</span>

                                                                </div>
                                                                <div class="map-point-width">
                                                                    <a @click.stop="navigateToMap(point.lats, point.longs), open = true;"
                                                                        style="text-decoration: underline; font-weight: 600; color: #312783;">{{
                                                                            $t('message.viewMap') }}</a>
                                                                </div>
                                                            </div>
                                                            <div style="width:95%; margin-left: -12px;">
                                                                <p>{{ point.address }}</p>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <!-- <div  v-for="point in boardingPoint" :key="point.id" @click="storeData(pointName = point.address, pointId= point.id),selectedBox = point.address; open = false; $emit('input', point.name);">
                                                        {{ point.address }}
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="row passenger"
                                        style="margin-bottom: auto !important; padding-top: 0px !important; height:auto;">
                                        <div class="col-lg-12 col-md-12 col-sm-12">

                                            <label for="" class="equal_label1">{{ $t('message.dropOffPoint') }} <span
                                                    style="color: #FF0000;"
                                                    v-if="dropOffPoint.length != 1">*</span></label>
                                            <div class="custom-select" :tabindex="tabindex">
                                                <div class="selected"
                                                    :class="{ hideAfterdrop: dropOffPoint.length == 1, open1: open1 }"
                                                    @click="open1 = !open1">
                                                    <!-- {{ limitStr(selectedBox, 35) }} -->
                                                    <div class="mobileScreen">
                                                        {{ selectedBoxDown == "" ? "Select your Drop Off point" :
                                                            selectedBoxDown }}
                                                    </div>
                                                    <!-- <div :title="selectedBoxDown" class="mobileScreen d-none-for-mobile" style="line-height: 35px;">
                                                        {{ limitStr(selectedBoxDown, 50) }} 
                                                    </div> -->


                                                </div>
                                                <div class="items" :class="{ selectHide: !open1 }"
                                                    v-if="dropOffPoint.length != 1">
                                                    <div class="header-drop-point"> {{ $t('message.dropOffPoint') }}t
                                                    </div>
                                                    <div class="d-flex mb-3" v-for="dropoff in dropOffPoint"
                                                        :key="dropoff.id"
                                                        @click="storeDataDown(pointNameDown = dropoff.address, pointIdDown = dropoff.id), selectedBoxDown = dropoff.address + ' ' + convertTimes(dropoff.time); open1 = false; $emit('input', dropoff.name);">
                                                        <div class="checkbox-mobile"
                                                            style="margin-left: -9px; margin-right: 30px;">
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" disabled
                                                                    :checked="selectedCheckboxDown == dropoff.id"
                                                                    :value="dropoff.id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="right-info-name-map">
                                                            <div class="d-flex justify-space-between"
                                                                style="padding-bottom: 8px;">
                                                                <div class="name-width">
                                                                    <span style="color: #E38F5A; font-weight: 600;">{{
                                                                        dropoff.name }} {{ convertTimes(dropoff.time)
                                                                        }}</span>
                                                                </div>
                                                                <div class="map-point-width">
                                                                    <a @click.stop="navigateToMap(dropoff.lats, dropoff.longs), open1 = true;"
                                                                        style="text-decoration: underline; font-weight: 600; color: #312783;">{{
                                                                            $t('message.viewMap') }}</a>
                                                                </div>
                                                            </div>
                                                            <div style="width:95%; margin-left: -12px;">
                                                                <p>{{ dropoff.address }}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <!-- <div  v-for="dropoff in dropOffPoint" :key="dropoff.id"  @click="storeDataDown(pointNameDown = dropoff.address, pointIdDown = dropoff.id),selectedBoxDown = dropoff.address; open1 = false; $emit('input', dropoff.name);">
                                                        {{ dropoff.address }}
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div v-if="returnTrip" class="passDetails-info">
                                    <div class="passDetails passDetails-number">
                                        <div class="number">3</div>
                                        <div class="title-pass">{{ $t('message.passengerDetail') }} </div>
                                    </div>
                                    <div class="passenger">
                                        <div class="row hide-label-phone">
                                            <div class="col-md-5 col-sm-12">
                                                <label for="">{{ $t('message.fullName') }}<span style="color: #FF0000;">
                                                        *</span></label>
                                            </div>
                                            <div class="col-md-4 col-sm-12">
                                                <label for="">{{ $t('message.nationality') }}</label>
                                            </div>
                                            <div class="col-md-3 col-sm-12">
                                                <label for="">{{ $t('message.gender') }} <span style="color: #FF0000;">
                                                        *</span></label>
                                            </div>
                                        </div>
                                        <div style="margin-top: -23px;">
                                            <div class="row" v-for="(seat, index) in seatLabelbacks" :key="seat.id">
                                                <div class="col-lg-5 col-md-5 col-sm-5">
                                                    <div class="group-form">
                                                        <label for="" class="hide-label equal_label">{{
                                                            $t('message.fullName') }}<span
                                                                style="color: #FF0000; padding-left: 1px;">
                                                                *</span></label>
                                                        <input type="text" v-model="fullnameback[index]"
                                                            @click="myChangeFunction(index)"
                                                            class="custom-input equals_input"
                                                            :placeholder="$t('message.enterYourfullName')">
                                                    </div>
                                                </div>
                                                <!-- return trip nationality -->
                                                <div class="col-lg-4 col-md-4 col-sm-4">
                                                    <div class="group-form">
                                                        <label for="" class="hide-label equal_label">{{
                                                            $t('message.nationality') }}</label>
                                                        <div class="equals_input" style="position: relative;">
                                                            <div style="position: relative;"
                                                                class="nationality-dropdown">
                                                                <i @click="selectNation(index, true)"
                                                                    style="position: absolute;top: 34%;right: 9px; color: #6E6E73;"
                                                                    class="fa-sharp fa-solid fa-caret-down"></i>
                                                                <input @click="selectNation(index, true)"
                                                                    @input="filterNations" type="text"
                                                                    v-model="nationalityBack[index]"
                                                                    :class="{ 'is-invalid': is_register && $v.nationality.$error }"
                                                                    class="custom-input"
                                                                    :placeholder="$t('message.selectNationality')" />

                                                                <!-- Custom Dropdown -->
                                                                <div v-show="showListDataNation && activeNationalityItemBack === index"
                                                                    style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px; height: 200px; overflow: auto">
                                                                    <div v-for="(nation, i) in filteredNations" :key="i"
                                                                        @click="setNational(index, nation.id, nation.name, true)"
                                                                        class="hover-color"
                                                                        style="margin: 10px 0; cursor: pointer; padding-left: 12px; padding-top: 5px; padding-bottom: 5px;">
                                                                        {{ nation.name }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-3">
                                                    <div class="group-form">
                                                        <label for="" class="hide-label equal_label">{{
                                                            $t('message.gender') }} <span style="color: #FF0000;">
                                                                *</span></label>
                                                        <div class="equals_input" style="position: relative;">
                                                            <div v-if="selectedback[index] == 0"
                                                                style="position: relative;">
                                                                <input type="text" readonly :value="$t(selecteGenBack0)"
                                                                    class="custom-input"
                                                                    @click="selectGenderBack(index)">
                                                                <i style="position: absolute;top: 34%;right: 9px;"
                                                                    @click="selectGenderBack(index)"
                                                                    class="fa-sharp fa-solid fa-caret-down"></i>
                                                            </div>
                                                            <div v-if="selectedback[index] != 0">
                                                                <div v-if="selectedback[index] == 1"
                                                                    style="position: relative;">
                                                                    <input type="text" readonly
                                                                        v-model="selectedOptionBack1"
                                                                        class="custom-input"
                                                                        @click="selectGenderBack(index)">
                                                                    <i style="position: absolute;top: 34%;right: 9px;"
                                                                        @click="selectGenderBack(index)"
                                                                        class="fa-sharp fa-solid fa-caret-down"></i>
                                                                </div>
                                                                <div v-if="selectedback[index] == 2"
                                                                    style="position: relative;">
                                                                    <input type="text" readonly
                                                                        v-model="selectedOptionBack2"
                                                                        class="custom-input"
                                                                        @click="selectGenderBack(index)">
                                                                    <i style="position: absolute;top: 34%;right: 9px;"
                                                                        @click="selectGenderBack(index)"
                                                                        class="fa-sharp fa-solid fa-caret-down"></i>
                                                                </div>
                                                            </div>
                                                            <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px;"
                                                                v-if="showListDataGenBack" class="hideNational"
                                                                :class="{ showNational: index == activeItem }"
                                                                v-click-outside="hidePopupGen">
                                                                <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;"
                                                                    class="hover-color"
                                                                    @click="setGenderBack(index, 1)">{{
                                                                        $t('message.male') }}</div>
                                                                <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;"
                                                                    class="hover-color"
                                                                    @click="setGenderBack(index, 2)">{{
                                                                        $t('message.Female') }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dotted"><img style="width: 100%;" src="@/assets/line.png"
                                                        alt=""></div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div v-if="returnTrip" style="padding-top: 30px;">
                                    <div class="passDetails" style="padding-bottom: 20px;">
                                        <div class="number">4</div>
                                        <div class="title-pass">{{ $t('message.location') }} {{ getdestinationToName()
                                            }}
                                            {{ $t('message.to') }} {{ getDestinationFrom() }}</div>
                                    </div>
                                    <div class="row passenger"
                                        style=" margin-bottom: auto !important; padding-top: 0px !important; height:auto;">
                                        <div class="col-lg-12 col-md-12 col-sm-12">

                                            <label for="" class="equal_label1">{{ $t('message.boardingPoint') }} <span
                                                    style="color: #FF0000;"
                                                    v-if="boardingPointBack.length != 1">*</span></label>
                                            <div class="custom-select" :tabindex="tabindex">
                                                <div class="selected"
                                                    :class="{ hideAfterdrop: boardingPointBack.length == 1, open2: open2 }"
                                                    @click="open2 = !open2">
                                                    <!-- {{ limitStr(selectedBox, 35) }} -->
                                                    <div class="mobileScreen">
                                                        {{ selectedBoxBack == '' ?
                                                            this.$t('message.selectpoardingpoing') :
                                                            selectedBoxBack }}
                                                    </div>
                                                    <!-- <div :title="selectedBoxBack" class="mobileScreen d-none-for-mobile">
                                                        {{ limitStr(selectedBoxBack, 50) }}
                                                    </div> -->


                                                </div>

                                                <div class="items" :class="{ selectHide: !open2 }"
                                                    v-if="boardingPointBack.length != 1">
                                                    <div class="header-drop-point"> {{ $t('message.boardingPoint') }}
                                                    </div>
                                                    <div class="d-flex mb-3" v-for="pointBack in boardingPointBack"
                                                        :key="pointBack.id"
                                                        @click="storeDataBack(pointName = pointBack.address, pointId = pointBack.id), selectedBoxBack = pointBack.address + ' ' + convertTimes(pointBack.time); open2 = false">
                                                        <div class="checkbox-mobile"
                                                            style="margin-left: -9px; margin-right: 30px;">
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" disabled
                                                                    :checked="selectedCheckboxBack == pointBack.id"
                                                                    :value="pointBack.id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="right-info-name-map">
                                                            <div class="d-flex justify-space-between"
                                                                style="padding-bottom: 8px;">
                                                                <div class="name-width">
                                                                    <span style="color: #E38F5A; font-weight: 600;">{{
                                                                        pointBack.name }} {{
                                                                            convertTimes(pointBack.time) }}</span>

                                                                </div>
                                                                <div class="map-point-width">
                                                                    <a @click.stop="navigateToMap(pointBack.lats, pointBack.longs), open2 = true;"
                                                                        style="text-decoration: underline; font-weight: 600; color: #312783;">{{
                                                                            $t('message.viewMap') }}</a>
                                                                </div>
                                                            </div>
                                                            <div style="width:95%; margin-left: -12px;">
                                                                <p>{{ pointBack.address }}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <!-- <div  v-for="pointBack in boardingPointBack" :key="pointBack.id" @click="storeDataBack(pointName = pointBack.address, pointId= pointBack.id),selectedBoxBack = pointBack.address; open2 = false ">
                                                        {{ pointBack.address }}
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row passenger"
                                        style="padding-top: 0px !important; margin-top: 0px !important; margin-bottom: auto !important; height:auto;">
                                        <div class="col-lg-12 col-md-12 col-sm-12">

                                            <label for="" class="equal_label1">{{ $t('message.dropOffPoint') }} </label>
                                            <div class="custom-select" :tabindex="tabindex">
                                                <div class="selected"
                                                    :class="{ hideAfterdrop: dropOffPointBack.length == 1, open3: open3 }"
                                                    @click="open3 = !open3">
                                                    <!-- {{ limitStr(selectedBox, 35) }} -->
                                                    <div class="mobileScreen">
                                                        {{ selectedBoxBackDown == '' ? 'Select your Drop Off point' :
                                                            selectedBoxBackDown }}
                                                    </div>
                                                    <!-- <div :title="selectedBoxBackDown" class="mobileScreen d-none-for-mobile" style="line-height: 35px;">
                                                    
                                                        {{limitStr(selectedBoxBackDown,50) }}
                                                    </div> -->


                                                </div>

                                                <div class="items" :class="{ selectHide: !open3 }"
                                                    v-if="dropOffPointBack.length != 1">
                                                    <div class="header-drop-point"> {{ $t('message.dropOffPoint') }}
                                                        <span style="color: #FF0000;"
                                                            v-if="dropOffBack.length != 1">*</span>
                                                    </div>
                                                    <div class="d-flex mb-3" v-for="dropOffBack in dropOffPointBack"
                                                        :key="dropOffBack.id"
                                                        @click="storeDataBackDown(pointName = dropOffBack.address, pointId = dropOffBack.id), selectedBoxBackDown = dropOffBack.address + ' ' + convertTimes(dropOffBack.time); open3 = false">
                                                        <div class="checkbox-mobile"
                                                            style="margin-left: -9px; margin-right: 30px;">
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" disabled
                                                                    :checked="selectedCheckboxBackDown == dropOffBack.id"
                                                                    :value="dropOffBack.id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="right-info-name-map">
                                                            <div class="d-flex justify-space-between"
                                                                style="padding-bottom: 8px;">
                                                                <div class="name-width">
                                                                    <span style="color: #E38F5A; font-weight: 600;">{{
                                                                        dropOffBack.name }} {{
                                                                            convertTimes(dropOffBack.time) }}</span>

                                                                </div>
                                                                <div class="map-point-width">
                                                                    <a @click.stop="navigateToMap(dropOffBack.lats, dropOffBack.longs), open3 = true;"
                                                                        style="text-decoration: underline; font-weight: 600; color: #312783;">{{
                                                                            $t('message.viewMap') }}</a>
                                                                </div>
                                                            </div>
                                                            <div style="width:95%; margin-left: -12px;">
                                                                <p>{{ dropOffBack.address }}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div v-for="dropOffBack in dropOffPointBack" :key="dropOffBack.id"
                                                        @click="storeDataBackDown(pointName = dropOffBack.address, pointId = dropOffBack.id), selectedBoxBackDown = dropOffBack.address; open3 = false">
                                                        {{ dropOffBack.address }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="details-screen">
                                    <div class="passDetails" style="padding-bottom: 20px;">
                                        <div class="number" v-if="returnTrip">5</div>
                                        <div class="number" v-else>3</div>
                                        <div class="title-pass">{{ $t('message.billingDetail') }} </div>
                                    </div>
                                    <div class="row">
                                        <div style="margin-top: -12px; margin-left: 55px">{{
                                            $t('message.yourBookingDetailticketWillBeSent') }}</div>
                                    </div>
                                    <div class="row passenger" style="padding-top: 3px !important;">
                                        <div class="col-lg-7 col-md-6 col-sm-6">
                                            <div class="group-form1">
                                                <label for="email">{{ $t('message.email') }} <span
                                                        style="color: #FF0000;">*</span></label>
                                                <input type="email" v-model="email" class="custom-input"
                                                    :placeholder="$t('message.emailAddress')">
                                                <span v-if="msg.email" style="color: #FF0000;">{{ msg.email }}</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-6 col-sm-6">
                                            <div class="group-form1">
                                                <label for="">{{ $t('message.telephoneNumber') }} <span
                                                        style="color: #FF0000;"
                                                        v-if="isUseLuckyDraw == 1">*</span></label>
                                                <input type="tel" v-model="phone" @input="validatePhoneNumber"
                                                    @keypress="onlyNumber($event)" @paste="handlePaste($event)"
                                                    class="custom-input"
                                                    :placeholder="$t('message.enterTelephoneNumber')">
                                                <span v-if="phoneNumberError.phone" style="color: #FF0000;">{{
                                                    phoneNumberError.phone }}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row" style="margin-top: 14px;">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="noted-rq">
                                            <div class="img-note">
                                                <div class="bg-lights">
                                                    <img src="@/assets/light.png" alt="">
                                                </div>
                                                <div style="font-size: 16px;font-weight: 600;color: #424245;">{{
                                                    $t('message.noted') }} *</div>
                                            </div>
                                            <div class="noted-text">
                                                <div style="font-weight: bold;" class="pb-2">{{ $t('message.attention')
                                                    }} !</div>
                                                <span>{{ $t('message.desAttention') }}</span>
                                                <div style="font-weight: bold;" class="pb-2 pt-2">{{
                                                    $t('message.boardingPoint') }}</div>
                                                <span>{{ $t('message.desBoardingPointNight') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-12 padd-lefe">
                            <!-- information seat -->
                            <div class="row"
                                style="background: #FFFFFF !important; border-radius: 8px; opacity: 1; z-index: 100;">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="fare-detail ml-3">
                                        {{ $t('message.fareDetail') }}
                                    </div>
                                    <div class="data-details ml-3 mt-2">
                                        <div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.direction') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ getDestinationFrom() }} {{ $t('message.to') }}
                                                    {{ getdestinationToName() }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.departureDate') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ getDateFrom() }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.departureTime') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ convertTimes(getDeparture()) }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.arrivalTime') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ convertTimes(getArrvalDate()) }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.selectedSeat') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ getSeat() || 0 }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.seatNumber') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ getSeatLabel() || '-' }}
                                                </div>
                                            </div>
                                            <div v-if="returnTrip">
                                                <div v-if="priceOriginal">

                                                </div>
                                                <div v-else>
                                                    <!-- <div class="d-flexs">
                                                        <div class="static">
                                                            {{ $t('message.grandTotal') }}:
                                                        </div>
                                                        <div class="dynamic">
                                                            ${{ formatPrice(grandTotal()) }}
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div v-else>
                                                <!-- <div class="d-flexs">
                                                    <div class="static">
                                                        {{ $t('message.grandTotal') }}:
                                                    </div>
                                                    <div class="dynamic">
                                                        ${{ formatPrice(grandTotal()) }}
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div v-if="returnTrip">
                                            <div class="dotteded" style="margin-bottom: -8px !important;"><img
                                                    style="width: 100%;" src="@/assets/Line1.png" alt=""></div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.direction') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ getdestinationToName() }} {{ $t('message.to') }}
                                                    {{ getDestinationFrom() }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.departureDate') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ getDateTo() }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.departureTime') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ convertTimes(getDepartureBack()) }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.arrivalTime') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ convertTimes(getArrvalDateBack()) }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.selectedSeat') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ getSeatBack() || 0 }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.seatNumber') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ getSeatLabelBack() || '-' }}
                                                </div>
                                            </div>
                                            <!-- <div class="d-flexs" v-if="!showpriceOriginalback">
                                                <div class="static">
                                                    {{ $t('message.grandTotal') }}:
                                                </div>
                                                <div class="dynamic">
                                                    ${{ formatPrice(grandTotalBack()) }}
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- total calulation seat -->
                            <div class="row mt-8"
                                style="background: #FFFFFF !important; border-radius: 8px; opacity: 1; z-index: 100;">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="fare-detail ml-3">
                                        {{ $t('message.totalSummary') }}
                                    </div>
                                    <div class="data-details mt-2">
                                        <div>
                                            <!-- <div class="d-flexs">
                                                <div class="static">
                                                    Lucky Draw:
                                                </div>
                                                <div class="dynamic" style="text-align: end;">
                                                    {{ handleCheckboxChangeluckydraw() != 0 ?   "$" + formatPrice(handleCheckboxChangeluckydraw()) : '$-' }}
                                                </div>
                                            </div> -->
                                            <div style="background: #F1AF88;" v-if="isLuckyDrawEnabled">
                                                <div class="check-box" style="justify-content: center;">
                                                    <div class="text d-flex"
                                                        style="color: #FFFFFF; font-size: 14px; width:92%;">
                                                        {{ $t('message.luckyTicketTitle') }}
                                                    </div>
                                                </div>
                                                <div class="check-box" style="justify-content: center;">
                                                    <div class="text d-flex"
                                                        style="color: #FFFFFF; width:92%; padding-bottom: 10px;">
                                                        <div style="margin-top: -10px; margin-left: 2px">
                                                            <v-checkbox color="#DE5D0A" style="transform: scale(1.5);"
                                                                v-model="isCheckedBox"
                                                                @change="handleCheckboxChangeluckydraw"></v-checkbox>
                                                        </div>
                                                        <div style="font-size: 14px;">
                                                            <!-- If you check the <a target="_blank" href="https://www.facebook.com/VirakBunthamExpress" style="color: #DE5D0A;">LUCKY DRAW BUTTON </a> You will charge $0.25. You See Lucky draw on FB Page. -->
                                                            <div>
                                                                {{ $t('message.desLuckyTicket') }}
                                                            </div>
                                                            <div class="mt-3">
                                                                {{ $t('message.subDesLuckyTicketAdd') }} <span
                                                                    style="font-weight: bold; color: #FFFFFF;">{{
                                                                        $t('message.desLuckyTicketSpanBlod') }} </span> {{
                                                                        $t('message.subDesLuckyTicket') }} <a target="_blank"
                                                                    href="https://www.facebook.com/VirakBunthamExpress"
                                                                    style="text-decoration: underline; color: #FFFFFF;">{{
                                                                        $t('message.desLuckyTicketSpan') }}</a> {{
                                                                        $t('message.subDesLuckyTicket1') }}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="getSeat() <= 1 && (!returnTrip || (returnTrip && getSeatBack() <= 1))"
                                                style="border-top: 1px solid #000; margin-top: 15px;">
                                                <div class="check-box" style="justify-content: center;">
                                                    <div class="text d-flex"
                                                        style="color: #000; width:92%; padding-bottom: 10px;">
                                                        <div style="margin-top: -10px; margin-left: 2px">
                                                            <v-checkbox color="#DE5D0A" style="transform: scale(1.5);"
                                                                v-model="isCheckPackageApply"
                                                                @change="handleCheckboxTravelPackage"></v-checkbox>
                                                        </div>
                                                        <div style="font-size: 14px; padding-top: 10px;">
                                                            {{ $t('message.travelPackageTitle') }}
                                                        </div>
                                                        <div style="margin-left: 10px;" v-if="isCheckPackageApply">
                                                            <input type="text" class="custom-input"
                                                                v-model="packageCode">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ml-3 mt-3">
                                                <!-- <div class="d-flexs">
                                                    <div class="static" style="color: #424245;">
                                                        Apply Coupon Code
                                                    </div>
                                                </div> 
                                                <div class="d-flex align-center justify-space-between mt-3">
                                                    <div style="width: 70%;">
                                                        <div class="group-form1">
                                                            <input type="text" style="border: 1px solid #C6C6C6;" class="custom-input" placeholder="Enter your code">
                                                        </div>
                                                        
                                                    </div>
                                                    <div style="width: 26%;">
                                                        <div class="apply">
                                                            <div>Apply</div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="d-flexs mt-5">
                                                    <div class="static">
                                                        {{ $t('message.grandTotal') }}:
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;">
                                                        <!-- ${{ parseFloat(this.grandTotal()) + parseFloat(this.grandTotalBack()) }} -->
                                                        ${{ formatPrice(calculateGrandTotal()) }}
                                                    </div>
                                                </div>
                                                <div class="d-flexs mt-3">
                                                    <div class="d-flex static">
                                                        {{ $t('message.discount') }}
                                                        <div v-if="returnTrip">
                                                            <!-- ({{ getDiscountPercentage() }}%): -->
                                                        </div>
                                                        <div v-else style="padding-left: 2px;">
                                                            <!-- ({{ getDiscountPercentage() }}%) -->
                                                        </div>
                                                        :
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;" v-if="returnTrip">
                                                        ${{ formatPrice(parseFloat(disConut()) +
                                                            parseFloat(disConutBack())) }}
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;" v-else>
                                                        ${{ formatPrice(disConut()) }}
                                                    </div>
                                                </div>
                                                <div class="d-flexs mt-3" v-if="handleCheckboxChangeluckydraw() != 0">
                                                    <div class="static">
                                                        {{ $t('message.luckyTicket') }} :
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;">
                                                        ${{ formatPrice(handleCheckboxChangeluckydraw()) }}

                                                    </div>

                                                </div>
                                                <div class="d-flexs mt-3" v-if="returnTrip">
                                                    <div class="static">
                                                        {{ $t('message.total') }}:
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;">
                                                        ${{ formatPrice(priceDisTotalBack()) }}
                                                    </div>
                                                </div>
                                                <div class="d-flexs mt-3" v-else>
                                                    <div class="static">
                                                        {{ $t('message.total') }}:
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;" v-if="isCheckedBox">
                                                        ${{ formatPrice(priceDisTotalHaveLuckyDraw()) }}
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;" v-else>
                                                        ${{ formatPrice(formatPrice(priceDisTotal())) }}
                                                    </div>
                                                </div>
                                                <div class="check-box">
                                                    <!-- <v-checkbox v-model="checkbox"></v-checkbox> -->
                                                    <div class="text" style="color: #424245;">
                                                        {{ $t('message.desTermCoditionPrivacyPolicy') }} <a
                                                            href="/term-condition" target="_blank"
                                                            style="color: #312783;">{{
                                                                $t('message.termCondition') }} </a> {{
                                                                $t('message.desTermCoditionPrivacyPolicyAnd') }} <a
                                                            href="/privacy-policy" target="_blank"
                                                            style="color: #312783;">{{
                                                                $t('message.privacyPolicy') }}</a></div>
                                                </div>
                                                <!-- v-if="checkbox" -->
                                                <div class="proccess">
                                                    <div v-if="returnTrip" @click="processPaymentTwo">{{
                                                        $t('message.processPayment') }}</div>
                                                    <div v-else @click="processPayment">{{ $t('message.processPayment')
                                                        }}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';

export default {
    name: 'PassengerPage',
    props: {
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            loading: false,  // Change initial value to false
            validateField: false,
            fullname: [], // Initialize as empty array instead of single item
            fullnameback: [],
            email: localStorage.getItem('emailProfile'),

            index: 0,
            selectedOption: 'message.gender',
            selectedOption1: this.$t("message.male"),
            selectedOption2: this.$t("message.Female"),
            selecteGenBack0: 'message.gender',
            selectedOptionBack1: this.$t("message.male"),
            selectedOptionBack2: this.$t("message.Female"),
            phone: '',
            phoneNumberError: [],
            maxLength: 9, // maximum allowed phone number length
            maxLengths: 10, // maximum allowed phone number length
            msg: [],
            isValidEmail: true,
            seatNum: [],
            seatNumBack: [],
            seatLabelBack: '',
            total: 0,
            returnTrip: false,
            checkbox: false,
            seatLabels: [],
            seatLabelbacks: [],
            checkNational: true,
            radioGroup: 1,

            packageCode: "",
            showpriceOriginalback: false,
            priceOriginal: false,
            discount: 5,
            discountpackage: "",
            selected: [],      // Outbound gender array
            selectedback: [],  // Return trip gender array
            selectedname: [],
            activeItem: 0,
            showListDataGen: false,
            showListDataGenBack: false,
            check: false,
            isCheckedBox: false,
            isCheckPackageApply: false,
            isUseLuckyDraw: '',
            dropOffBack: [], // Add this line
            boardingPoint: [],
            dropOffPoint: [],
            boardingPointBack: [],
            dropOffPointBack: [],
            selectedBoxId: "",
            selectedBox: "",
            selectedBoxIdBack: "",
            selectedBoxBack: "",
            selectedBoxIdBackDown: "",
            selectedBoxBackDown: "",

            selectedBoxDownId: "",
            selectedBoxDown: "",

            selectedCheckbox: null,
            selectedCheckboxDown: null,
            selectedCheckboxBack: null,
            selectedCheckboxBackDown: null,

            pointName: "",
            pointId: "",
            pointNameDown: "",
            pointIdDown: "",
            pointNameBack: "",
            pointIdBack: "",
            pointNameBackDown: "",
            pointIdBackDown: "",

            disPercent: localStorage.getItem('disPercent'),
            packagedis: '',
            disPercentBack: localStorage.getItem('disPercentBack'),

            open: false,
            open1: false,
            open2: false,
            open3: false,
            isPackageApplied: false, // New property to manage checkbox state
            is_booking: false,
            showNationalityDropdown: false,
            nationalitySelected: [], // Add this line
            nationalitySelectedBack: [], // Add this line
            activeNationalityItem: null, // Track which dropdown is active
            showNationalityDropdownBack: false,
            activeNationalityItemBack: null,
            listnation: [],
            nationalityid: [],
            showListDataNation: false,
            filteredNations: [],
            nationality: [], // Change to array to store nationality for each passenger
            nationalityBack: [], // For return trip
            nationalityidBack: [], // For return trip
            lang: localStorage.getItem('lang'),

        }
    },
    watch: {
        email(value) {
            // binding this to the data value in the email input
            this.email = value;
            this.validateEmail(value);
        },
        phone(value) {
            this.phone = value;
            this.validatePhoneNumber(value);
        },
        returnTrip: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    // Re-initialize nationality arrays when return trip is enabled
                    this.initializeNationality();
                }
            }
        }
    },
    computed: {
        isLuckyDrawEnabled() {
            const cutoffDate = new Date('2024-12-31');
            const journeyDate = new Date(localStorage.getItem('dateFrom'));
            const journeyDateBack = localStorage.getItem('dateTo') ? new Date(localStorage.getItem('dateTo')) : null;

            // Check if either journey date is after cutoff
            if (journeyDate > cutoffDate) return false;
            if (journeyDateBack && journeyDateBack > cutoffDate) return false;

            return true;
        }


    },

    methods: {
        hidePopupNation() {
            this.showListDataNation = false;
            this.activeNationalityItem = null;
            this.activeNationalityItemBack = null;
        },

        onClickOutside(event) {
            // Check if the click was outside the dropdown
            const dropdowns = document.querySelectorAll('.nationality-dropdown');
            let clickedInside = false;

            dropdowns.forEach(dropdown => {
                if (dropdown.contains(event.target)) {
                    clickedInside = true;
                }
            });

            if (!clickedInside) {
                this.hidePopupNation();
            }
        },

        setNational(index, id, name, isReturn = false) {
            if (isReturn) {
                // For return trip
                this.$set(this.nationalityBack, index, name);
                this.$set(this.nationalityidBack, index, id);
            } else {
                // For outbound trip
                this.$set(this.nationality, index, name);
                this.$set(this.nationalityid, index, id);

                // Automatically copy to return trip if it exists
                if (this.returnTrip) {
                    this.$set(this.nationalityBack, index, name);
                    this.$set(this.nationalityidBack, index, id);
                }
            }
            this.hidePopupNation();
        },
        async selectNation(index, isReturn = false) {
            // Toggle dropdown for clicked index
            const activeIndex = isReturn ? this.activeNationalityItemBack : this.activeNationalityItem;

            if (activeIndex === index) {
                this.hidePopupNation();
            } else {
                // Set the appropriate active item based on trip direction
                if (isReturn) {
                    this.activeNationalityItemBack = index;
                    this.activeNationalityItem = null; // Close outbound dropdown
                } else {
                    this.activeNationalityItem = index;
                    this.activeNationalityItemBack = null; // Close return dropdown
                }

                this.showListDataNation = true;

                // Initialize filtered nations if needed
                if (!this.filteredNations.length) {
                    this.filteredNations = [...this.listnation];
                }
            }
        },
        async getNationality() {
            try {
                const response = await axios({
                    method: "POST",
                    headers: this.$header,
                    url: this.$url + 'travel-package/nationalityList'
                });

                if (response.status === 200 &&
                    response.data.header.result &&
                    response.data.header.statusCode === 200) {

                    this.listnation = response.data.body.data;
                    this.filteredNations = [...this.listnation];

                    // After getting nations, initialize stored values if any
                    const storedId = localStorage.getItem('nationalityId');
                    const storedName = localStorage.getItem('nationalityIdProfile');

                    if (storedId && storedName) {
                        this.$set(this.nationality, 0, storedName); // Changed from Vue.set
                        this.$set(this.nationalityid, 0, storedId); // Changed from Vue.set

                        if (this.returnTrip) {
                            this.$set(this.nationalityBack, 0, storedName); // Changed from Vue.set
                            this.$set(this.nationalityidBack, 0, storedId); // Changed from Vue.set
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching nationalities:', error);
            }
        },
        storeData(pointName, pointId) {
            this.pointName = pointName;
            this.pointId = pointId;
            if (pointId == '') {
                this.selectedCheckbox = null;
            } else if (this.selectedCheckbox == pointId) {
                this.selectedCheckbox = pointId;
            } else {
                this.selectedCheckbox = pointId;
            }
            localStorage.setItem("boardingpointAddress", this.pointName);
            localStorage.setItem('boardingPointId', this.pointId);
        },
        storeDataDown(pointNameDown, pointIdDown) {
            this.pointNameDown = pointNameDown;
            this.pointIdDown = pointIdDown;
            if (pointIdDown == '') {
                this.selectedCheckboxDown = null;
            } else if (this.selectedCheckboxDown == pointIdDown) {
                this.selectedCheckboxDown = pointIdDown;
            } else {
                this.selectedCheckboxDown = pointIdDown;
            }
            localStorage.setItem("dropOffPointName", this.pointNameDown);
            localStorage.setItem('dropOffPointId', this.pointIdDown);
        },
        storeDataBack(pointNameBack, pointIdBack) {
            this.pointNameBack = pointNameBack;
            this.pointIdBack = pointIdBack;
            if (pointIdBack == '') {
                this.selectedCheckboxBack = null;
            } else if (this.selectedCheckboxBack == pointIdBack) {
                this.selectedCheckboxBack = pointIdBack;
            } else {
                this.selectedCheckboxBack = pointIdBack;
            }
            localStorage.setItem("boardingPointNameBack", this.pointNameBack);
            localStorage.setItem('boardingPointIdBack', this.pointIdBack);
        },
        storeDataBackDown(pointNameBackDown, pointIdBackDown) {
            this.pointNameBackDown = pointNameBackDown;
            this.pointIdBackDown = pointIdBackDown;
            if (pointIdBackDown == '') {
                this.selectedCheckboxBackDown = null;
            } else if (this.selectedCheckboxBackDown == pointIdBackDown) {
                this.selectedCheckboxBackDown = pointIdBackDown;
            } else {
                this.selectedCheckboxBackDown = pointIdBackDown;
            }
            localStorage.setItem("dropOffPointNameBack", this.pointNameBackDown);
            localStorage.setItem('dropOffPointIdBack', this.pointIdBackDown);
        },

        navigateToMap(lats, longs) {
            const url = `https://maps.google.com/maps?q=${lats},${longs}`;
            window.open(url, '_blank');
        },
        autoFieldToBack(index) {
            if (this.returnTrip) {
                // Only copy name if valid
                if (index < this.fullnameback.length) {
                    this.fullnameback[index] = this.fullname[index];
                }
                // Only copy nationality if return trip nationality hasn't been manually set
                if (!this.nationalityBack[index] && this.nationality[index]) {
                    this.nationalityBack[index] = this.nationality[index];
                    this.nationalityidBack[index] = this.nationalityid[index];
                }
                // Only copy gender if return trip gender hasn't been manually set
                if (!this.selectedback[index] && this.selected[index]) {
                    this.selectedback[index] = this.selected[index];
                    // Update display text based on gender
                    if (this.selected[index] === 1) {
                        this.selectedOptionBack1 = this.$t("message.male");
                    } else if (this.selected[index] === 2) {
                        this.selectedOptionBack2 = this.$t("message.Female");
                    }
                }
            }
        },

        myChangeFunction(index) {
            if (this.returnTrip) {
                // Only copy name if valid
                if (index < this.fullnameback.length) {
                    this.fullnameback[index] = this.fullname[index];
                }
                // Only copy nationality if return trip nationality hasn't been manually set
                if (!this.nationalityBack[index] && this.nationality[index]) {
                    this.nationalityBack[index] = this.nationality[index];
                    this.nationalityidBack[index] = this.nationalityid[index];
                }
                // Only copy gender if return trip gender hasn't been manually set
                if (!this.selectedback[index] && this.selected[index]) {
                    this.selectedback[index] = this.selected[index];
                    // Update display text based on gender
                    if (this.selected[index] === 1) {
                        this.selectedOptionBack1 = this.$t("message.male");
                    } else if (this.selected[index] === 2) {
                        this.selectedOptionBack2 = this.$t("message.Female");
                    }
                }
            }
        },
        // Check roundTirp
        getCheck() {
            if (localStorage.getItem('dateTo') != null) {
                this.returnTrip = true;
            } else {
                this.returnTrip = false;
            }
        },
        // Start Get Data
        getDestinationFrom() {
            if (localStorage.getItem('lang') == 'ch') {
                return localStorage.getItem('departureFromNameCn');
            } else if (localStorage.getItem('lang') == 'kh') {
                return localStorage.getItem('departureFromNameKh');
            } else {
                return localStorage.getItem('departureFromName');
            }
        },
        getdestinationToName() {
            if (localStorage.getItem('lang') == 'ch') {
                return localStorage.getItem('destinationToNameCn');
            } else if (localStorage.getItem('lang') == 'kh') {
                return localStorage.getItem('destinationToNameKh');
            } else {
                return localStorage.getItem('destinationToName');
            }
        },
        getDateFrom() { return localStorage.getItem('dateFrom'); },
        getDateTo() { return localStorage.getItem('dateTo'); },
        getDeparture() { return localStorage.getItem('departure'); },
        getDepartureBack() { return localStorage.getItem('departureback'); },
        getArrvalDate() { return localStorage.getItem('arrival'); },
        getArrvalDateBack() { return localStorage.getItem('arrivalback'); },
        convertTimes(time) {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}`;
        },
        getSeat() {
            const seatStr = localStorage.getItem('seat');
            if (seatStr && seatStr !== '') {
                const seatNumArray = seatStr.split(",").filter(s => s); // Remove empty strings
                this.seatNum = seatNumArray.length;
                return this.seatNum;
            }
            return 0;
        },
        getSeatLabel() {
            const seatLabelStr = localStorage.getItem('seatlabel');
            if (seatLabelStr && seatLabelStr !== '') {
                const seatArray = seatLabelStr.split(",").filter(s => s); // Remove empty strings
                return seatArray.join(", ");
            }
            return '';
        },
        getSeatBack() {
            const seatBackStr = localStorage.getItem('seatback');
            if (seatBackStr && seatBackStr !== '') {
                const seatNumBackArray = seatBackStr.split(",").filter(s => s); // Remove empty strings
                this.seatNumBack = seatNumBackArray.length;
                return this.seatNumBack;
            }
            return 0;
        },
        getSeatLabelBack() {
            const seatLabelBackStr = localStorage.getItem('seatlabelback');
            if (seatLabelBackStr && seatLabelBackStr !== '') {
                const seatArray = seatLabelBackStr.split(",").filter(s => s); // Remove empty strings
                this.seatLabelBack = seatArray.join(", ");
                return this.seatLabelBack;
            }
            return '';
        },
        // Start One way 
        // Grand Total both Period and auto discount(5%)
        grandTotal() {
            if (localStorage.getItem('priceOriginal') == "" || localStorage.getItem('priceOriginal') == null) {
                this.priceOriginal = false;
            } else {
                this.priceOriginal = true;
            }
            return localStorage.getItem('resultTotal');
        },
        // Total both Period and auto discount(5%)
        priceDisTotal() {
            const totalAmount = parseFloat(localStorage.getItem('resultTotal')) - this.disConut();
            return totalAmount;
        },
        priceDisTotalHaveLuckyDraw() {
            return localStorage.getItem('resultTotal') - this.disConut() + parseFloat(this.handleCheckboxChangeluckydraw());
        },
        disConut() {
            // If package discount is applied, use package discount rate
            if (this.isCheckPackageApply) {
                const totalAmount = parseFloat(localStorage.getItem('resultTotal')) || 0;
                const discountPercentage = this.packagedis || 0;
                const rawDiscount = Math.floor((totalAmount * (discountPercentage / 100)) * 100) / 100;
                return rawDiscount.toFixed(2);
            }

            // Check if originalPrice exists - if so, no regular discount applies
            const hasOriginalPrice = localStorage.getItem('priceOriginal') !== "" &&
                localStorage.getItem('priceOriginal') !== null;
            if (hasOriginalPrice) {
                return "0.00";
            }

            // Regular 5% discount calculation
            const totalAmount = parseFloat(localStorage.getItem('resultTotal')) || 0;
            const discountPercentage = 5;
            const rawDiscount = Math.floor((totalAmount * (discountPercentage / 100)) * 100) / 100;
            return rawDiscount.toFixed(2);
        },

        // End One way 

        // Start Two way 

        // Grand Total both Period and auto discount(5%)
        grandTotalBack() {
            if (localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null) {
                this.showpriceOriginalback = false;
            } else {
                this.showpriceOriginalback = true;
            }
            return localStorage.getItem('resultTotalBack');
        },

        // Total both Period and auto discount(5%)
        priceDisTotalBack() {
            const totalAmount = parseFloat(localStorage.getItem('resultTotal')) +
                parseFloat(localStorage.getItem('resultTotalBack'));
            const totalDiscount = this.totalDiscosunt();
            const luckyDrawAmount = parseFloat(this.handleCheckboxChangeluckydraw());
            return totalAmount - totalDiscount + luckyDrawAmount;
        },

        grandTotalBackSubDis() {
            if (localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null) {
                return localStorage.getItem('resultTotalBack') - this.disConutBack();
            } else {
                return localStorage.getItem('resultTotalBack');
            }
        },

        disConutBack() {
            // If package discount is applied, use package discount rate
            if (this.isCheckPackageApply) {
                const totalAmount = parseFloat(localStorage.getItem('resultTotalBack')) || 0;
                const discountPercentage = this.packagedis || 0;
                const rawDiscount = (totalAmount * (discountPercentage / 100));
                return this.roundDiscount(rawDiscount);
            }

            // Check if originalPrice exists for return trip - if so, no regular discount applies
            const hasOriginalPriceBack = localStorage.getItem('priceOriginalback') !== "" &&
                localStorage.getItem('priceOriginalback') !== null;
            if (hasOriginalPriceBack) {
                return "0.00";
            }

            const discountBack = parseFloat(localStorage.getItem('discountBack')) || 0;
            const numberOfSeatsBack = parseInt(this.getSeatBack()) || 0;
            const rawDiscount = discountBack * numberOfSeatsBack;
            return this.roundDiscount(rawDiscount);
        },

        totalDiscosunt() {
            // If package discount is applied, calculate total package discount
            if (this.isCheckPackageApply) {
                const totalAmount = this.calculateGrandTotal();
                const discountPercentage = parseFloat(this.packagedis) || 0;
                const rawDiscount = (totalAmount * (discountPercentage / 100));
                return this.roundDiscount(rawDiscount);
            }

            // For regular discount, sum both trip discounts (if applicable)
            const firstTripDiscount = parseFloat(this.disConut());
            const returnTripDiscount = parseFloat(this.disConutBack());
            const totalDiscount = firstTripDiscount + returnTripDiscount;
            return this.roundDiscount(totalDiscount);
        },

        totalReture() {
            let totals = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
            return this.total = totals;
        },
        // End Two way 

        formatPrice(value) {
            let val = (value / 1).toFixed(2);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        /* ------- Start One Way -----*/
        getSeatLabelRetureBack() {
            // Handle outbound seats
            const seatLabels = localStorage.getItem('seatlabel')?.split(',').filter(s => s) || [];
            this.seatLabels = seatLabels;
            this.selected = new Array(seatLabels.length).fill(0);

            // Handle return trip seats if they exist
            if (localStorage.getItem('seatlabelback')) {
                const seatLabelsBack = localStorage.getItem('seatlabelback').split(',').filter(s => s);
                this.seatLabelbacks = seatLabelsBack;
                this.selectedback = new Array(seatLabelsBack.length).fill(0);
            }

            // Initialize first passenger's gender from profile if available
            const profileGender = localStorage.getItem('genderProfile');
            if (profileGender) {
                const genderValue = parseInt(profileGender);
                if (this.selected.length > 0) {
                    this.selected[0] = genderValue;
                    this.setGender(0, genderValue); // This will handle display text updates
                }
                if (this.returnTrip && this.selectedback.length > 0) {
                    this.selectedback[0] = genderValue;
                    this.setGenderBack(0, genderValue); // This will handle display text updates
                }
            }
        },
        calculateGrandTotal() {
            if (this.grandTotalBack() == null || this.grandTotalBack() == "") {
                return (parseFloat(this.grandTotal()));

            } else {
                return (parseFloat(this.grandTotal()) + parseFloat(this.grandTotalBack()));
            }

        },

        showPopupGen() {
            this.showListDataGen = true;
            this.showListDataGenBack = true;
        },
        hidePopupGen() {
            this.showListDataGen = false;
            this.showListDataGenBack = false;
        },
        /*--------- Get Travel Package Code  -------*/
        async getTravelPackageCode() {
            this.loading = false;
            const url = this.$url + `travel-package/packageBuyList`;

            const data = {
                'session': localStorage.getItem('session_start_login'),
            }
            let config = {
                method: "POST",
                headers: {
                    ...this.$header,
                    "Content-Type": "multipart/form-data"
                },
                data: data,
                url,
            };

            axios(config).then((response) => {
                if (response.status == 200) {
                    this.loading = false;
                    if (response.data.header.result == true &&
                        response.data.header.statusCode == 200 &&
                        response.data.body.length > 0) {
                        // Clear old packageCode
                        localStorage.removeItem('packageCode');
                        // Get the last package code from the array
                        const lastIndex = response.data.body.length - 1;
                        this.packageCode = response.data.body[lastIndex].packageCode;
                        localStorage.setItem('packageCode', this.packageCode);
                    } else {
                        this.packageCode = null;
                        this.isCheckPackageApply = false;
                        alert("Can not apply travel package")
                    }
                }
            }).catch((error) => {
                console.log(error);
                this.isCheckPackageApply = false;
                alert("Don't have Travel Package");
            });
        },


        checkPackageApply() {
            const session = localStorage.getItem('session_start_login');
            const journeyId = localStorage.getItem('journeyid');
            const travelDate = localStorage.getItem('dateFrom');
            const code = localStorage.getItem('packageCode');

            const data = new FormData();
            data.append('session', session);
            data.append('code', code);
            data.append('journeyId', journeyId);
            data.append('travelDate', travelDate);

            const url = this.$url + `booking/checkPackageApply`;

            let config = {
                method: "POST",
                headers: {
                    ...this.$header,
                    "Content-Type": "multipart/form-data"
                },
                data: data,
                url,
            };


            // Add better error handling and logging
            axios(config)
                .then((response) => {

                    if (response.status == 200) {
                        this.loading = false;
                        if (response.data.header.result == true &&
                            response.data.header.statusCode == 200) {
                            this.isPackageApplied = true;

                            if (response.data.body.status == 1) {

                                const discountPercentage = response.data.body.discount;
                                this.packagedis = discountPercentage;

                                // Calculate discount
                                const discountValue = parseFloat(discountPercentage) / 100;
                                const totalAmount = this.calculateGrandTotal();
                                const discountAmount = totalAmount * discountValue;
                                const newTotal = totalAmount - discountAmount;

                                this.discountpackage = discountAmount;
                                localStorage.setItem('totalAmount', newTotal);
                            } else {

                                this.isPackageApplied = false;
                                alert("Package not applied");
                            }
                        } else {
                            if (response.data.body.result == false) {
                                alert("Apply already");
                            }

                        }
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error("API call failed:", error);

                });
        },

        /*--------- Start One Way Boarding Point -------*/
        async getBoardingPoint(bId) {
            const url = this.$url + `boarding-point/findBySchedule/${bId}`;
            let config = {
                method: "POST",
                headers: this.$header,
                url
            };
            this.loading = true;
            axios(config).then(response => {
                this.loading = false;
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.boardingPoint = response.data.body;

                        if (response.data.body.length == 1) {
                            for (let i = 0; i < this.boardingPoint.length; i++) {
                                this.selectedBoxId = this.boardingPoint[0].id;
                                this.selectedBox = this.boardingPoint[0].address + "  " + this.convertTimes(this.boardingPoint[0].time);
                            }
                            localStorage.setItem('boardingPointId', this.selectedBoxId);
                            localStorage.setItem("boardingpointAddress", this.selectedBox);
                        } else {
                            this.selectedBoxId = "";
                            this.selectedBox = "";
                            localStorage.setItem('boardingPointId', this.selectedBoxId);
                            localStorage.setItem("boardingpointAddress", this.selectedBox);

                        }

                    } else {
                        this.errored = true
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },
        async getDropOffPoint(dId) {
            const url = this.$url + `drop-off-point/findBySchedule/${dId}`;
            let config = {
                method: "POST",
                headers: this.$header,
                url
            };
            axios(config).then(response => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.dropOffPoint = response.data.body;

                        // let dropOffPointId = response.data.body;
                        if (response.data.body.length == 1) {
                            for (let i = 0; i < this.dropOffPoint.length; i++) {
                                this.selectedBoxDownId = this.dropOffPoint[0].id;
                                this.selectedBoxDown = this.dropOffPoint[0].address + "  " + this.convertTimes(this.dropOffPoint[0].time);
                            }
                            localStorage.setItem('dropOffPointId', this.selectedBoxDownId);
                            localStorage.setItem('dropOffPointName', this.selectedBoxDown);
                        } else {
                            this.selectedBoxDownId = '';
                            this.selectedBoxDown = '';
                            localStorage.setItem('dropOffPointId', this.selectedBoxDownId);
                            localStorage.setItem('dropOffPointName', this.selectedBoxDown);
                        }

                    } else {
                        this.errored = true
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },

        selectGender(index) {
            this.activeItem = index;
            this.showListDataGen = true;
        },
        selectGenderBack(index) {
            this.activeItem = index;
            this.showListDataGenBack = true;
        },

        setGender(index, value) {
            // Update outbound gender
            this.$set(this.selected, index, value);

            // Update display text
            if (value === 1) {
                this.selectedOption1 = this.$t("message.male");
            } else if (value === 2) {
                this.selectedOption2 = this.$t("message.Female");
            }

            // Auto-copy to return trip if it exists and hasn't been manually set
            if (this.returnTrip && !this.selectedback[index]) {
                this.selectedback[index] = value;
                if (value === 1) {
                    this.selectedOptionBack1 = this.$t("message.male");
                } else if (value === 2) {
                    this.selectedOptionBack2 = this.$t("message.Female");
                }
            }

            this.showListDataGen = false;
        },
        setGenderBack(index, value) {
            // Update return trip gender
            this.$set(this.selectedback, index, value);

            // Update display text
            if (value === 1) {
                this.selectedOptionBack1 = this.$t("message.male");
            } else if (value === 2) {
                this.selectedOptionBack2 = this.$t("message.Female");
            }

            this.showListDataGenBack = false;
        },
        getGender() {
            let check = true;
            for (let i = 0; i < this.selected.length; i++) {
                if (this.selected[i] == 0) {
                    check = false;
                    break;
                }
            }
            return check;
        },

        checkName() {
            // Check outbound names
            const outboundValid = this.fullname.every(name => name && name.trim());
            if (!outboundValid) {
                this.check = false;
                return false;
            }

            // Check return trip names if applicable
            if (this.returnTrip) {
                const returnValid = this.fullnameback.every(name => name && name.trim());
                if (!returnValid) {
                    this.check = false;
                    return false;
                }
            }

            this.check = true;
            return true;
        },

        checkNameBack() {
            let name = [];
            let seatName = this.fullnameback;
            for (let i = 0; i < seatName.length; i++) {
                name.push(seatName[i]);
                if (name == '' || name == undefined) {
                    this.check = false;
                    break;
                } else {
                    return this.check = true;
                }
            }
        },

        getGenderBack() {
            let check = true;
            for (let i = 0; i < this.selectedback.length; i++) {
                if (this.selectedback[i] == 0) {
                    check = false;
                    break;
                }
            }
            return check;
        },

        // checkbox luckydraw
        handleCheckboxChangeluckydraw() {
            let totalluckydraw;
            if (this.isCheckedBox) {
                totalluckydraw = (this.seatNumBack + this.getSeat()) * 0.25;
                this.isUseLuckyDraw = 1;
                // Travel Package Reset
                this.isCheckPackageApply = false;
                this.packageCode = "";
            } else {
                totalluckydraw = 0;
                this.isUseLuckyDraw = 0;
            }
            localStorage.setItem('totalluckydraw', totalluckydraw);

            return totalluckydraw;

        },


        handleCheckboxTravelPackage() {
            if (this.isCheckPackageApply) {
                this.handleCheckboxChangeluckydraw();
                this.getTravelPackageCode();
                this.checkPackageApply()
            }
        },

        processPayment() {
            // Reset loading at start
            this.loading = false;

            if (this.seatLabels.length == this.fullname.length && this.checkName()) {
                // Check required fields
                if (!this.getGender() && this.email == "") {
                    alert("Please input Email and Select Gender");
                    return;
                }

                if (this.email == "") {

                    alert("Please input Email.");
                    return;
                }

                if (!this.getGender()) {
                    alert(this.$t('message.pleaseselectgender'));
                    return;
                }

                // Only require phone for lucky draw
                if (this.isUseLuckyDraw == 1 && !this.phone) {
                    alert("Please input telephone number for lucky draw");
                    return;
                }

                if (localStorage.getItem('boardingPointId') == '') {
                    alert(this.$t('message.Pleaseselectboardingpoint'));
                    return;
                }

                if (localStorage.getItem('dropOffPointId') == '') {
                    alert("Please Select Drop Off point");
                    return;
                }

                // Validate email format
                if (!this.validateEmail(this.email)) {

                    this.msg['email'] = this.$t('message.emailInvalid');
                    alert("Email address is required");
                    return;
                }

                // Only validate phone if provided or lucky draw selected
                if (this.phone || this.isUseLuckyDraw == 1) {
                    const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/;
                    if (!regex.test(this.phone) ||
                        (this.phone.length != this.maxLength && this.phone.length != this.maxLengths)) {
                        this.phoneNumberError['phone'] = 'Invalid phone number';
                        alert("Invalid phone number");
                        return;
                    }
                }

                // Add nationality validation
                const seatCount = this.seatLabels.length;
                for (let i = 0; i < seatCount; i++) {
                    if (!this.nationalityid[i]) {
                        // alert(this.$t('message.pleaseSelectNationality') + ` (${this.$t('message.passenger')} ${i + 1})`);
                        alert(this.$t('message.pleaseSelectNationality'))
                        return;
                    }
                }

                localStorage.setItem('email', this.email);

                // Prepare booking data
                const bookingData = {
                    boardingPointId: parseInt(localStorage.getItem('boardingPointId')),
                    dropOffId: parseInt(localStorage.getItem('dropOffPointId')),
                    email: this.email,

                    journeyDate: [localStorage.getItem('dateFrom')],
                    journeyId: [localStorage.getItem('journeyid')],
                    journeyType: parseInt(localStorage.getItem('types')),
                    nationally: localStorage.getItem("selectNational"),
                    seatNationallyId: this.nationalityid, // Ensure no null values
                    seatGender: this.selected,
                    seatJourney: Array(this.seatLabels.length).fill(localStorage.getItem('journeyid')),
                    seatName: this.fullname,
                    seatNum: localStorage.getItem('seat').split(","),
                    seatPrice: Array(this.seatLabels.length).fill(localStorage.getItem('price')),
                    telephone: this.phone || "", // Make phone optional
                    isUseLuckyDraw: parseInt(this.isUseLuckyDraw),
                    totalAmount: parseFloat(localStorage.getItem('resultTotal')),
                    totalDiscount: this.disConut(),
                    totalSeat: String(this.getSeat()),
                    session: localStorage.getItem('session_start_login'),
                    packageTravelCode: this.isCheckPackageApply ? localStorage.getItem('packageCode') : ''
                };

                // Set loading before API call
                this.loading = true;

                const url = this.$url + `booking/confirm`;
                const config = {
                    method: "POST",
                    headers: this.$header,
                    data: qs.stringify(bookingData),
                    url
                };

                axios(config)
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data.header.result && response.data.header.statusCode == 200) {
                                if (response.data.body.status == 1) {
                                    // Store data in localStorage
                                    localStorage.setItem("discount", response.data.body.discount);
                                    localStorage.setItem("discountPercent", response.data.body.discountPercent);
                                    localStorage.setItem("total", response.data.body.total);
                                    localStorage.setItem("subTotal", response.data.body.subTotal);

                                    // Clear loading and redirect
                                    this.loading = false;
                                    this.info = response.data.body;
                                    this.transactionId = this.info.transactionId;
                                    this.$router.push({
                                        path: "/payment-cart",
                                        query: {
                                            transactionId: this.info.transactionId
                                        }
                                    });
                                } else if (response.data.body.status == 2) {
                                    this.loading = false;
                                    this.info = response.data.body;
                                    this.transactionId = this.info.transactionId;
                                    this.$router.push({
                                        path: "/payment-success",
                                        query: {
                                            transactionId: this.info.transactionId
                                        }
                                    });
                                } else {
                                    this.loading = false;
                                    if (response.data.body.msg == "Sorry, some seats you selected are not available.") {
                                        alert("Seat is not available, please chose your trip again.");
                                        this.$router.push("/");
                                    } else {
                                        alert("Something went wrong, Please try it again.");
                                        this.$router.push("/");
                                    }
                                }
                            } else {
                                this.loading = false;
                                this.$router.go();
                            }
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        console.error("Booking API error:", error);
                        alert("An error occurred during booking. Please try again.");
                    });
            } else {
                this.loading = false;
                alert(this.$t('message.pleaseinputyourname'));
            }
        },
        /*--------- End One Way Boarding Point -------*/

        /*--------- Start Two Way Boarding Point -------*/
        async getBoardingPointBack(bIdBack) {
            const url = this.$url + `boarding-point/findBySchedule/${bIdBack}`;
            let config = {
                method: "POST",
                headers: this.$header,
                url
            };
            axios(config).then(response => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.boardingPointBack = response.data.body;
                        // let boardingPointIdBack = [];
                        if (response.data.body.length == 1) {
                            for (let i = 0; i < this.boardingPointBack.length; i++) {
                                this.selectedBoxIdBack = this.boardingPointBack[0].id;
                                this.selectedBoxBack = this.boardingPointBack[0].address + "  " + this.convertTimes(this.boardingPointBack[0].time);
                            }
                            localStorage.setItem('boardingPointIdBack', this.selectedBoxIdBack);
                            localStorage.setItem('boardingPointNameBack', this.selectedBoxBack);
                        } else {
                            this.selectedBoxIdBack = '';
                            this.selectedBoxBack = '';
                            localStorage.setItem('boardingPointIdBack', this.selectedBoxIdBack);
                            localStorage.setItem('boardingPointNameBack', this.selectedBoxBack);
                        }

                    } else {
                        this.errored = true;
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },
        async getDropOffPointBack(dIdBack) {
            const url = this.$url + `drop-off-point/findBySchedule/${dIdBack}`;
            let config = {
                method: "POST",
                headers: this.$header,
                url
            };
            axios(config).then(response => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.dropOffPointBack = response.data.body;
                        // let dropOffPointIdBack = response.data.body;
                        if (response.data.body.length == 1) {
                            for (let i = 0; i < this.dropOffPointBack.length; i++) {
                                this.selectedBoxIdBackDown = this.dropOffPointBack[0].id;
                                this.selectedBoxBackDown = this.dropOffPointBack[0].address + "  " + this.convertTimes(this.dropOffPointBack[0].time);
                            }
                            localStorage.setItem('dropOffPointIdBack', this.selectedBoxIdBackDown);
                            localStorage.setItem('dropOffPointNameBack', this.selectedBoxBackDown);
                        } else {
                            this.selectedBoxIdBackDown = '';
                            this.selectedBoxBackDown = '';
                            localStorage.setItem('dropOffPointIdBack', this.selectedBoxIdBackDown);
                            localStorage.setItem('dropOffPointNameBack', this.selectedBoxBackDown);
                        }

                    } else {
                        this.errored = true
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },
        processPaymentTwo() {
            // Reset loading state
            this.loading = false;

            // Check names are filled for both trips
            if (this.seatLabels.length == this.fullname.length && this.checkName()) {
                // Validate email and gender
                if (!this.getGender() && !this.getGenderBack() && this.email == "") {
                    alert("Please input Email and Select Gender");
                    return;
                }

                if (this.email == "") {
                    alert("Please input Email");
                    return;
                }
                // Validate email format
                if (!this.validateEmail(this.email)) {

                    this.msg['email'] = this.$t('message.emailInvalid');
                    alert(this.$t('message.emailaddressrequired'));
                    return;
                }


                if (!this.getGender() || !this.getGenderBack()) {
                    alert(this.$t('message.pleaseselectgender'));
                    return;
                }

                // Validate boarding points
                if (localStorage.getItem('boardingPointId') == '') {
                    alert(this.$t('message.Pleaseselectboardingpoint'));
                    return;
                }

                if (localStorage.getItem('dropOffPointId') == '') {
                    alert("Please Select Drop Off point");
                    return;
                }

                if (localStorage.getItem('boardingPointIdBack') == '') {
                    alert("Please Select boarding point for return trip");
                    return;
                }

                if (localStorage.getItem('dropOffPointIdBack') == '') {
                    alert("Please Select Drop Off point for return trip");
                    return;
                }

                // Validate phone if lucky draw selected
                if (this.isUseLuckyDraw == 1 && !this.phone) {
                    alert("Please input telephone number for lucky draw");
                    return;
                }

                // Validate phone format if provided
                if (this.phone) {
                    const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/;
                    if (!regex.test(this.phone) ||
                        (this.phone.length != this.maxLength && this.phone.length != this.maxLengths)) {
                        this.phoneNumberError['phone'] = 'Invalid phone number';
                        alert("Invalid phone number");
                        return;
                    }
                }

                // Add this validation block after the phone validation and before preparing booking data
                // in the processPaymentTwo method

                // Validate nationalities for outbound trip
                const outboundSeatCount = this.seatLabels.length;
                for (let i = 0; i < outboundSeatCount; i++) {
                    if (!this.nationalityid[i]) {
                        // alert(this.$t('message.pleaseSelectNationality') + ` (${this.$t('message.passenger')} ${i + 1} - ${this.$t('message.roundTrip')})`);
                        alert(this.$t('message.pleaseSelectNationality'))
                        return;
                    }
                }

                // Validate nationalities for return trip
                const returnSeatCount = this.seatLabelbacks.length;
                for (let i = 0; i < returnSeatCount; i++) {
                    if (!this.nationalityidBack[i]) {
                        // alert(this.$t('message.pleaseSelectNationality') + ` (${this.$t('message.passenger')} ${i + 1} - ${this.$t('message.roundTrip')})`);
                        alert(this.$t('message.pleaseSelectNationality'))
                        return;
                    }
                }

                // Get seat data
                const seatData = localStorage.getItem('seat');
                const seatBackData = localStorage.getItem('seatback');

                if (!seatData || !seatBackData) {
                    alert("Missing seat information. Please select seats again.");
                    return;
                }

                // Prepare booking data
                const bookingData = {
                    boardingPointId: [
                        parseInt(localStorage.getItem('boardingPointId')),
                        parseInt(localStorage.getItem('boardingPointIdBack'))
                    ],
                    dropOffId: [
                        parseInt(localStorage.getItem('dropOffPointId')),
                        parseInt(localStorage.getItem('dropOffPointIdBack'))
                    ],
                    email: this.email,
                    journeyDate: [
                        localStorage.getItem('dateFrom'),
                        localStorage.getItem('dateTo')
                    ],
                    journeyId: [
                        localStorage.getItem('journeyid'),
                        localStorage.getItem('journeyidback')
                    ],
                    journeyType: 2,
                    nationally: localStorage.getItem("selectNational"),
                    seatNationallyId: [
                        ...this.nationalityid, // Outbound trip nationalities
                        ...this.nationalityidBack // Return trip nationalities
                    ],
                    seatGender: [...this.selected, ...this.selectedback],
                    seatJourney: [
                        ...Array(this.seatLabels.length).fill(localStorage.getItem('journeyid')),
                        ...Array(this.seatLabelbacks.length).fill(localStorage.getItem('journeyidback'))
                    ],
                    seatName: [...this.fullname, ...this.fullnameback],
                    seatNum: [
                        ...seatData.split(",").filter(s => s),
                        ...seatBackData.split(",").filter(s => s)
                    ],
                    seatPrice: [
                        ...Array(this.seatLabels.length).fill(localStorage.getItem('price')),
                        ...Array(this.seatLabelbacks.length).fill(localStorage.getItem('priceback'))
                    ],
                    telephone: this.phone || "",
                    totalAmount: this.calculateGrandTotal(), // Use the calculated grand total
                    totalDiscount: this.isCheckPackageApply ?
                        parseFloat(this.totalDiscosunt()) : // Use package discount for both trips
                        parseFloat(this.disConut()) + parseFloat(this.disConutBack()), // Use regular discounts
                    isUseLuckyDraw: parseInt(this.isUseLuckyDraw),
                    totalSeat: String(this.getSeat() + this.getSeatBack()),
                    session: localStorage.getItem('session_start_login'),
                    packageTravelCode: this.isCheckPackageApply ? localStorage.getItem('packageCode') : ''
                };

                // Make API call
                this.loading = true;
                const url = this.$url + `booking/confirm`;
                const config = {
                    method: "POST",
                    headers: this.$header,
                    data: qs.stringify(bookingData),
                    url
                };
                localStorage.setItem('email', this.email);

                axios(config)
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data.header.result && response.data.header.statusCode == 200) {
                                if (response.data.body.status == 1) {
                                    localStorage.setItem("discount", response.data.body.discount);
                                    localStorage.setItem("discountPercent", response.data.body.discountPercent);
                                    localStorage.setItem("total", response.data.body.total);
                                    localStorage.setItem("subTotal", response.data.body.subTotal);

                                    setTimeout(() => {
                                        this.loading = false;
                                        this.info = response.data.body;
                                        this.transactionId = this.info.transactionId;
                                        this.$router.push({
                                            path: "/payment-cart",
                                            query: {
                                                transactionId: this.info.transactionId
                                            }
                                        });
                                    }, 1000);
                                } else if (response.data.body.status == 2) {
                                    this.loading = false;
                                    this.info = response.data.body;
                                    this.transactionId = this.info.transactionId;
                                    this.$router.push({
                                        path: "/payment-success",
                                        query: {
                                            transactionId: this.info.transactionId
                                        }
                                    });
                                } else {
                                    if (response.data.body.msg == "Sorry, some seats you selected are not available.") {
                                        alert("Seat is not available, please chose your trip again.");
                                        this.$router.push("/");
                                    } else {
                                        alert("Something went wrong, Please try it again.");
                                        this.$router.push("/");
                                    }
                                }
                            } else {
                                this.$router.go();
                            }
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        console.error("Booking API error:", error);
                        alert("An error occurred during booking. Please try again.");
                    });
            } else {
                this.loading = false;
                alert("Please input your name");
            }
        },
        /*--------- End Two Way Boarding Point -------*/
        validatePhoneNumber(value) {
            const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/; // regular expression to validate phone number
            if (regex.test(value)) {
                if (value.length < this.maxLength) {
                    this.phoneNumberError['phone'] = this.$t('message.yourTelephoneNumberInvalid');
                } else if (value.length <= this.maxLength || this.phone.length == this.maxLengths) {
                    this.phoneNumberError['phone'] = ''; // valid phone number within length limit
                } else {
                    this.phoneNumberError['phone'] = this.$t('message.yourTelephoneNumberInvalid'); // invalid phone number exceeding length limit
                }
            } else {
                this.phoneNumberError['phone'] = ''; // invalid phone number
            }
        },
        removefixscroll() {
            const htmlElement = document.querySelector('html');
            htmlElement.classList.remove('fix-scroll');
        },
        validateEmail(value) {
            // Improved email regex pattern
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!value) {
                this.msg['email'] = 'Email is required';
                return false;
            }
            if (!emailPattern.test(value)) {
                this.msg['email'] = this.$t('message.emailInvalid');
                return false;
            }
            this.msg['email'] = '';
            return true;
        },
        limitStr(string, limit) {
            let str = string;

            if (typeof str === 'string' && str.length > limit) {
                str = str.slice(0, limit) + '...';
            }

            return str;
        },
        initializeGenderFromProfile() {
            const profileGender = localStorage.getItem('genderProfile');
            if (profileGender) {
                // Convert gender value to number if needed
                const genderValue = parseInt(profileGender);

                // Initialize arrays if not already done
                if (!this.selected.length) {
                    this.selected = new Array(this.seatLabels.length).fill(0);
                }
                if (!this.selectedback.length) {
                    this.selectedback = new Array(this.seatLabelbacks.length).fill(0);
                }

                // Set gender for first passenger
                this.selected[0] = genderValue;
                this.selectedback[0] = genderValue;

                // Update display text
                if (genderValue === 1) {
                    this.selectedOption1 = this.$t("message.male");
                    this.selectedOptionBack1 = this.$t("message.male");
                } else if (genderValue === 2) {
                    this.selectedOption2 = this.$t("message.Female");
                    this.selectedOptionBack2 = this.$t("message.Female");
                }

                // Store in localStorage
                localStorage.setItem('gender', this.selected);
                localStorage.setItem('genderback', this.selectedback);
            }
        },
        initializePassengerNames() {
            // Get profile name from localStorage
            const profileName = localStorage.getItem('nameProfile');

            // Get number of seats from localStorage
            const seatLabels = localStorage.getItem('seatlabel')?.split(',').filter(s => s) || [];
            const seatLabelsBack = localStorage.getItem('seatlabelback')?.split(',').filter(s => s) || [];

            // Initialize outbound trip names
            this.fullname = new Array(seatLabels.length).fill('');
            if (profileName) {
                this.fullname[0] = profileName; // Set first passenger name from profile
            }

            // Initialize return trip names if needed
            if (seatLabelsBack.length > 0) {
                this.fullnameback = new Array(seatLabelsBack.length).fill('');
                if (profileName) {
                    this.fullnameback[0] = profileName; // Set first passenger name from profile
                }
            }
        },
        getDiscountPercentage() {
            // If using package discount, return package discount percentage
            if (this.isCheckPackageApply) {
                return this.packagedis || '0';
            }

            // For round trip, return the individual trip's discount
            if (this.returnTrip) {
                // Check if we're showing the first trip's price
                if (localStorage.getItem('priceOriginal') === "" ||
                    localStorage.getItem('priceOriginal') === null) {
                    return localStorage.getItem('disPercent') || '0';
                }
                // Otherwise show the return trip's discount
                return localStorage.getItem('disPercentBack') || '0';
            }

            // For single trip
            return localStorage.getItem('disPercent') || '0';
        },
        // Add helper function for rounding discount values
        roundDiscount(amount) {
            const value = parseFloat(amount);
            if (isNaN(value)) return "0.00";

            // Get cents as whole number
            const cents = Math.round((value % 1) * 100);
            const lastDigit = cents % 10;

            // Handle values less than a dollar
            if (value < 1) {
                if (cents <= 4) {
                    return "0.00";
                } else if (cents >= 5) {
                    return value.toFixed(2);
                }
            }

            // For values >= 1
            if (lastDigit >= 5) {
                // Round up (e.g., 1.125 -> 1.13)
                return (Math.ceil(value * 100) / 100).toFixed(2);
            } else {
                // Round down (e.g., 1.124 -> 1.12)
                return (Math.floor(value * 100) / 100).toFixed(2);
            }
        },
        hideNationalityDropdown() {
            this.showNationalityDropdown = false;
        },

        setNationality(index, value) {
            // Update the nationality for the specific passenger
            this.nationalitySelected[index] = value;

            // Store in localStorage - use first passenger's nationality as default
            localStorage.setItem('selectNational', value);

            this.showNationalityDropdown = false;
        },
        selectNationality(index) {
            this.activeNationalityItem = index;
            this.showNationalityDropdown = true;
        },
        initializeNationality() {
            // Initialize arrays with empty strings
            const seatCount = this.seatLabels?.length || 1;
            const seatCountBack = this.seatLabelbacks?.length || 1;

            // Initialize with empty strings instead of null
            this.nationality = Array(seatCount).fill('');
            this.nationalityid = Array(seatCount).fill('');
            this.nationalityBack = Array(seatCountBack).fill('');
            this.nationalityidBack = Array(seatCountBack).fill('');

            // Load stored values
            const storedId = localStorage.getItem('nationalityId');
            const storedName = localStorage.getItem('nationalityIdProfile');

            if (storedId && storedName) {
                // Set for first passenger in outbound
                Vue.set(this.nationality, 0, storedName);
                Vue.set(this.nationalityid, 0, storedId);

                // Set for first passenger in return if round trip
                if (this.returnTrip) {
                    Vue.set(this.nationalityBack, 0, storedName);
                    Vue.set(this.nationalityidBack, 0, storedId);
                }
            }
        },
        selectNationalityBack(index) {
            this.showNationalityDropdownBack = true;
            this.activeNationalityItemBack = index;
        },

        hideNationalityDropdownBack() {
            this.showNationalityDropdownBack = false;
            this.activeNationalityItemBack = null;
        },

        setNationalityBack(index, value) {
            this.$set(this.nationalitySelectedBack, index, value);
            this.hideNationalityDropdownBack();
        },
        filterNations(event) {
            const searchTerm = event.target.value.toLowerCase();
            if (!searchTerm) {
                this.filteredNations = [...this.listnation];
            } else {
                this.filteredNations = this.listnation.filter(nation =>
                    nation.name.toLowerCase().includes(searchTerm)
                );
            }
        },
        cleanupNationalityData() {
            // Clear nationality arrays
            this.nationality = [];
            this.nationalityBack = [];
            this.nationalityid = [];
            this.nationalityidBack = [];
            this.filteredNations = [];
            this.listnation = [];

            // Reset dropdowns
            this.showListDataNation = false;
            this.activeNationalityItem = null;
            this.activeNationalityItemBack = null;
        },
        beforeDestroy() {
            // Clear nationality data when component is destroyed
            this.nationality = [];
            this.nationalityBack = [];
            this.nationalityid = [];
            this.nationalityidBack = [];
            this.filteredNations = [];
            this.listnation = [];
            this.showListDataNation = false;
            this.activeNationalityItem = null;
            this.activeNationalityItemBack = null;
            document.removeEventListener('click', this.handleClickOutside);
        },
        handleClickOutside(event) {
            const dropdowns = document.querySelectorAll('.nationality-dropdown');
            let clickedInside = false;

            dropdowns.forEach(dropdown => {
                if (dropdown.contains(event.target)) {
                    clickedInside = true;
                }
            });

            if (!clickedInside) {
                this.hidePopupNation();
            }
        },
        initializeArrays() {
            // Get seat counts
            const seatCount = this.getSeat();

            // Initialize arrays with empty values
            this.nationality = new Array(seatCount).fill('');
            this.nationalityid = new Array(seatCount).fill('');

            if (this.returnTrip) {
                this.nationalityBack = new Array(seatCount).fill('');
                this.nationalityidBack = new Array(seatCount).fill('');
            }
        },
        initializeGenderArrays() {
            // Get seat counts from localStorage
            const seatLabels = localStorage.getItem('seatlabel')?.split(',').filter(s => s) || [];
            const seatLabelsBack = localStorage.getItem('seatlabelback')?.split(',').filter(s => s) || [];

            // Initialize outbound gender array
            this.selected = new Array(seatLabels.length).fill(0);

            // Initialize return trip gender array if needed
            if (this.returnTrip) {
                this.selectedback = new Array(seatLabelsBack.length).fill(0);
            }

            // Set first passenger's gender from profile if available
            const profileGender = localStorage.getItem('genderProfile');
            if (profileGender) {
                const genderValue = parseInt(profileGender);
                if (this.selected.length > 0) {
                    this.selected[0] = genderValue;
                    this.setGender(0, genderValue); // This will handle display text updates
                }
                if (this.returnTrip && this.selectedback.length > 0) {
                    this.selectedback[0] = genderValue;
                    this.setGenderBack(0, genderValue); // This will handle display text updates
                }
            }
        },
        onlyNumber(event) {
            const keyCode = event.keyCode || event.which;
            const keyValue = String.fromCharCode(keyCode);
            const isNumeric = /^[0-9]+$/.test(keyValue);

            if (!isNumeric) {
                event.preventDefault();
            }
        },

        handlePaste(event) {
            // Prevent the default paste
            event.preventDefault();

            // Get pasted data
            const pastedData = (event.clipboardData || window.clipboardData).getData('text');

            // Only keep numbers
            const numbersOnly = pastedData.replace(/[^0-9]/g, '');

            // Update the value respecting maxLength
            const newValue = (this.phone + numbersOnly).slice(0, this.maxLength);
            this.phone = newValue;
        },


    },
    mounted() {
        // this.initializeGenderProfile();
        let phone = localStorage.getItem('telephoneProfile');
        if (phone == "undefined") {
            this.phone = '';
        } else {
            this.phone = phone;
        }
        // Check if the user is logged in
        const isLoggedIn = localStorage.getItem('session_start_login');
        if (isLoggedIn) {
            // Call getNationality if logged in
            this.getNationality();
        }
        this.removefixscroll();
        window.onpopstate = () => {
            if (localStorage.getItem('dateTo') != null) {
                localStorage.removeItem("seatback");
                localStorage.removeItem("seatlabelback");
            } else {
                localStorage.removeItem("seat");
                localStorage.removeItem("seatlabel");
            }
        };
        this.$emit("input", this.selectedBox);
        this.$emit("input", this.selectedBoxDown);
        this.initializeNationality();
        this.getNationality();

        // Add click outside listener
        document.addEventListener('click', this.handleClickOutside);
    },
    created() {
        this.loading = true;
        this.initializeNationality();

        // Only call getNationality if user is logged in
        if (localStorage.getItem('session_start_login')) {
            this.getNationality();
        }

        this.cleanupNationalityData(); // Clear old data first
        this.initializePassengerNames();
        this.getNationality();

        // if (localStorage.getItem('selectNational') == 2) {
        //     this.checkNational = true;
        // } else {
        //     this.checkNational = false;
        // }

        // Set timeout to load boarding points
        setTimeout(() => {
            if (localStorage.getItem('seatlabelback') == '' || localStorage.getItem('seatlabelback') == null) {
                this.getBoardingPoint(localStorage.getItem('journeyid'));
                this.getDropOffPoint(localStorage.getItem('journeyid'));
            } else {
                this.getBoardingPoint(localStorage.getItem('journeyid'));
                this.getDropOffPoint(localStorage.getItem('journeyid'));
                this.getBoardingPointBack(localStorage.getItem('journeyidback'));
                this.getDropOffPointBack(localStorage.getItem('journeyidback'));
            }
            this.getCheck();
            this.getSeatLabelRetureBack();

            // Set loading to false after initialization
            this.loading = false;
        }, 1000);

        // Initialize arrays when component is created
        this.initializeGenderArrays();
    }
}
</script>

<style scoped>
.hideNational {
    transition: .5s;
    display: none;
}

.passenger-pd {
    background-color: #F2F2F2;
    padding-top: 25px;
}

.showNational {
    display: block;
}

.mtop {
    margin-top: 100px;
    margin-bottom: 50px;
}

.title-booking-detail p {
    font-size: 20px;
    font-weight: 600;
    color: #1D1D1D;
}

.custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 4px;
}

/* .custom-checkbox input:checked ~ .checkmark {
        border: 2px solid #4CAF50;
    } */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 1px;
    width: 7px;
    height: 18px;
    border: solid #4CAF50;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

.bg-lights {
    width: 35px;
    height: 35px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #DE5D0A;
    border-radius: 50%;
}

.img-note {
    display: flex;
    align-items: center;
}

.img-note div {
    margin-right: 10px;
}

.bg-lights img {
    width: 85%;
    height: 85%;
    border-radius: 50%;
}

.noted-text {
    margin-top: 14px;
    padding-left: 45px;
}

.name-width {
    margin-left: -25px;
    width: 80%;
}

.map-point-width {
    width: 20%;
}

/* Right */
.passDetails {
    display: flex;
    align-items: center;
}

.passenger {
    padding-top: 17px;
    padding-left: 45px;
}

.d-none-for-mobile {
    display: none;
}

.padd-lefe {
    padding-left: 80px;
}

.passDetails .number {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #312783;
    background: #312783;
}

.passDetails .title-pass {
    margin-left: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #424245;
}

.custom-input {
    cursor: pointer;
    padding-left: 12px;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #6E6E73;
}

.select-radio {
    padding-top: 20px;
    padding-left: 44px;
}

.select-radio .card-select-payment {
    background: #EEEEF3;
    margin-top: 20px;
    padding: 25px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.select-radio .card-select-payment .text .title {
    font-weight: 600;
    font-size: 12px !important;
    color: #424245;
}

.dotted {
    display: none;
    padding-top: 10px;
}

.select-radio .card-select-payment .text .txt {
    font-weight: 400;
    font-size: 12px !important;
    color: #6E6E73;
}

.select-radio .card-select-payment .img-card {
    width: 10%;
}

.select-radio .card-select-payment .text {
    width: 80%;
}

.select-radio .card-select-payment .radios {
    display: flex;
    justify-content: end;
    width: 10%;
}

/* left */
.fare-detail {
    font-weight: 600;
    font-size: 16px;
    color: #424245;
}

.d-flexs {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.d-flexs .static {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    width: 28%;
}

.smallLineHeight {
    line-height: 25px;
}

.largeLineHeight {
    line-height: 40px;
}

.d-flexs .dynamic {
    margin-left: 5%;
    width: 70%;
    font-size: 14px;
    font-weight: 400;
    color: #1D1D1D;
}

.total {
    margin-top: 25px;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 6px;
    background: #D9D9D9;
}

.total div {
    width: 50%;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.total .price {
    text-align: end;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.check-box {
    display: flex;
    align-items: center;
}

.check-box .text {
    margin-top: 10px;
}

.proccess {
    cursor: pointer;
    margin-top: 5px;
    text-align: center;
    padding: 15px;
    border-radius: 6px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    background: #DE5D0A;
}

.apply {
    cursor: pointer;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    background: #DE5D0A;
}

.theme--light.v-icon {
    color: #C6C6C6 !important;
    /* border-color: transparent !important; */
}

.theme--light.v-input--selection-controls .v-icon {
    color: transparent !important;
}

.hide-label {
    display: none;
    padding-bottom: 6px;
}

.custom-select {
    margin-top: 0px;
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    line-height: 24px;
    padding-top: 5px;
}

.custom-select .selected {
    border-radius: 6px;
    border: 1px solid #6E6E73;
    color: #000;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    padding: 11px 6px;
}

.custom-select .selected.open {
    border-radius: 6px;
}

.header-drop-point {
    color: #1D1D1D;
    cursor: text !important;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 14px;
    padding-top: 14px;
}

.right-info-name-map {
    border: 1px solid #C6C6C699;
    width: 90%;
    border-radius: 8px;
    /* padding: 0px 10px; */
    padding-top: 10px;
    padding-left: 10px;
    /* padding-right: 10px; */
}

.custom-select .selected:after {
    position: absolute;
    content: "";
    top: 27px !important;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #666666 transparent transparent transparent;
}

.hideAfterdrop:after {
    content: none !important;
}

.custom-select .items {
    color: #000;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    position: absolute;
    background-color: #FFF;
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

/* .custom-select .items div:hover{
        color: #312783 !important;
       
    } */
.custom-select .items div {
    color: #000;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
}

/* .custom-select .items div:hover {
        background-color: white;
    } */
.selectHide {
    display: none;
}

.details-screen {
    padding-top: 6px;
}

.proccessed {
    cursor: not-allowed;
    margin-top: 5px;
    text-align: center;
    padding: 15px;
    border-radius: 6px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    background: #6A6969;
}

@media(width < 960px) {
    .select-radio {
        padding-left: 0px;
    }

    .select-radio .card-select-payment .text {
        padding-left: 20px;
    }

    .passenger {
        padding-left: 0px;
    }

    .padd-lefe {
        padding-left: 10px;
    }

    .hide-label-phone {
        display: none;
    }

    .hide-label {
        display: block;
    }

    .details-screen,
    .passDetails-info {
        padding-top: 20px !important;
    }

    .passDetails-number {
        padding-bottom: 10px;
    }
}

@media(width < 600px) {
    .dotted {
        display: block;
    }

    .group-form {
        display: flex;
    }

    .equal_label {
        width: 30%;
        display: flex;
        align-items: center;
    }

    .equal_label1 {
        display: flex;
        align-items: center;
    }

    .equals_input {
        width: 70%;
    }

    .d-block-show-large {
        display: none !important;
    }

    .d-none-for-mobile {
        display: block !important;
    }

    .passenger-pd {
        padding-top: 10px;
    }

    .smallLineHeight {
        line-height: 25px;
    }

    .largeLineHeight {
        line-height: 25px;
    }

    .checkbox-mobile {
        display: none;
    }

    .name-width {
        width: 72%;
    }

    .map-point-width {
        width: 28%;
    }

    .details-screen {
        padding-top: 20px !important;
    }

    .mobileScreen {
        width: 97%;
        font-size: 14px;
    }
}

@media (width < 457px) {
    .select-radio .card-select-payment {
        padding: 8px;
    }
}

@media(width < 435px) {
    .passDetails .title-pass {
        font-size: 13px;
    }
}

@media(width < 400px) {
    .details-screen {
        padding-top: 20px !important;
    }

    .name-width {
        width: 60%;
    }

    .map-point-width {
        width: 40%;
    }
}

@media (width < 450px) {
    .noted-text {
        font-size: 14px;
    }

    /* .custom-select .selected:after{
            top: 26px !important;
        } */
    .custom-select {
        border-radius: 5px;
    }

    .mobileScreen {
        width: 96%;
        font-size: 14px;
    }

    /* .custom-select .items div{
            width: 90%;
            margin: 0px auto;
            border-bottom: 1px solid #DE5D0A;
        } */
    .rowScreen {
        margin-bottom: auto !important;
    }

    .custom-select .selected {
        line-height: 2;
        padding: 8px;

    }

    .custom-select .items {
        line-height: 2;
        font-size: 14px;
    }

    .custom-select .selected:after {
        top: 28px;
        font-weight: bold;
    }
}

.v-input--selection-controls__input input[role=checkbox] {
    opacity: 1 !important;
}

.hover-color:hover {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    color: #ffffff;
    background: #DE5D0A;
}
</style>