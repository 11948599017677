<template>
  <div class="resort-listing">
    <div class="slider">
      <div class="slider-container">
        <img v-for="(image, i) in sliderImages" :key="i" :src="image" :class="{ active: currentIndex === i }"
          alt="Slider Image" />
      </div>
    </div>
    <div class="containers">
      <div style="display: flex; justify-content: center; align-items: center; width: 100%;">
        <div class="resort-list">
          <div v-for="resort in resorts" :key="resort.name" class="resort-card">
            <div class="resort-image">
              <img :src="resort.photo" :alt="resort.name" />
            </div>

            <div class="resort-info">
              <div style="display: flex; justify-content: space-between; padding: 0px 0px 20px 0px;">
                <h3 style="font-size: 14px; font-weight: 500; display: flex; align-items: center;">
                  {{ resort.name }}
                </h3>
                <p class="price">{{ resort.price }}</p>
              </div>

              <div class="price-book">
                <button class="book-btn" @click="handleRedirect(resort.link)">{{ $t('message.bookNow') }}</button>
              </div>
              <div class="location" v-if="resort.location && resort.location.trim()">
                <svg xmlns="http://www.w3.org/2000/svg" class="location-icon" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <p>{{ resort.location }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

// import sliceshow from "@/assets/esperanza-inlet-sunrise-1080x480.jpg"
export default {
  name: 'ResortListing',
  data() {
    return {
      currentIndex: 0,
      sliderImages: [
        require('@/assets/resort/cover website _3.jpg'),
        // require('@/assets/image.jpg'),
        // require('@/assets/0204h12000a5tqdrv8D9D_R_960_660_R5_D.jpg')
      ],
      resorts: [],
    }
  },
  methods: {
    handleDetailClick(resort) {
      this.$router.push(`/resort/${resort.id}`)
    },
    handleRedirect(url) {
      window.open(url, '_blank');
    },
    nextSlide() {
      if (this.slides && this.slides.length > 0) {
        // Proceed with your code logic
        // } else {
        //   console.error("Slides array is undefined or empty.");
      }

    },

    async getresort() {
      const url = this.$url + `resorts/list`;
      let config = {
        method: "POST",
        headers: this.$header,
        url,
      };

      try {
        const response = await axios(config);
        console.log('API Response:', response.data);
        
        // Check if we have a valid response with body array
        if (response.data && response.data.header.result === true && 
            response.data.header.statusCode === 200 && Array.isArray(response.data.body)) {
          this.resorts = response.data.body;
          console.log('Resorts loaded:', this.resorts);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.sliderImages.length) % this.sliderImages.length
    },
    setCurrentSlide(index) {
      this.currentIndex = index
    }
  },
  created() {
    this.getresort();
  },
  mounted() {
    setInterval(this.nextSlide, 5000)
  }
}
</script>

<style scoped>
/* Main Listing Container */
.resort-listing {
  max-width: 100%;
  margin: 0 auto;
  /* font-family: Arial, sans-serif; */
  padding-bottom: 60px;
}

/* Slider Styles */
.slider {
  position: relative;
  width: 100%;
  height: 380px;
  overflow: hidden;
  padding-top: 20px;
}

.slider-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slider-container img.active {
  opacity: 1;
}

.slider-dots {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #fff;
}

/* Resort List as Grid - Update the base styles */
.resort-list {
  display: grid;
  gap: 20px;
  padding-top: 50px;
  width: 100%;
  /* max-width: 100%; */
  margin: 0 auto;
}

.resort-card {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(198, 198, 198, 0.3);
  transition: all 0.3s ease;
  cursor: pointer;
}

.resort-image {
  width: 100%;
  height: 192px;
  overflow: hidden;
  /* margin-bottom: 16px; */
}

.resort-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Info Section */
.resort-info {
  padding: 12px;
}

.resort-info h3 {
  font-size: 16px;
  color: #333;
  /* margin: 12px ; */
  font-weight: 500;
}

.price-book {
  display: flex;
  flex-direction: column;
  /* padding-bottom: 12px; */
  gap: 8px;
}

.price {
  color: #DE5D0A;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.book-btn {
  background: #DE5D0A;
  color: white;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.location {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.location-icon {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  flex-shrink: 0;
}

.location p {
  font-size: 14px;
  margin: 0;
  line-height: 1.5;
}

/* Responsive Styles */
@media (min-width: 1024px) {
  .resort-list {
    grid-template-columns: repeat(3, 1fr);
    /* width: 1154px; */
    padding-top: 50px;
  }

  .slider {
    height: 380px;
  }

  .resort-image {
    height: 192px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .resort-list {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    max-width: 768px;
    padding-top: 30px;
    /* padding: 16px 24px; */
  }

  .slider {
    height: 300px;
  }

  .resort-image {
    height: 180px;
  }

  .resort-header h3 {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .resort-list {
    grid-template-columns: 1fr;
    width: 100%;
    padding-top: 30px;
    /* padding: 16px; */
  }

  .slider {
    height: 250px;
    padding-top: 10px;
  }

  .resort-image {
    height: 200px;
  }

  .resort-card {
    max-width: 100%;
  }

  .resort-header h2 {
    font-size: 16px;
  }

  .location p {
    font-size: 13px;
  }
}

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 8px; */
}

.redirect-btn {
  padding: 4px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.redirect-btn:hover {
  background-color: #45a049;
}

.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  border: none;
  /* padding: 10px 15px; */
  cursor: pointer;
  border-radius: 50%;
  font-size: 18px;
}

.slider-btn:hover {
  background: rgba(255, 255, 255, 0.8);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
@media (width < 500px) {
    .containers {
   padding: 0px;
   width: 94%;
    }
}
</style>