<template>
    <div class="buytravelpackage">
        <!-- <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div> -->

        <div class="container-fluid" style="background: #f2f2f2;">

            <div class="containers" style="padding-bottom: 70px;">

                <div class="row travelpackageheader" style="margin: 0px;">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 0px;">
                        <div>
                            <h1 class="text-center" style="color: #1D1D1D;"> {{ $t('message.travelPackage') }} </h1>
                        </div>
                        <div class="d-flex justify-center mt-6">
                            <div class="d-flex mobile-screen">
                                <div class="block" style="display: block;">
                                    <div style="width: 30px;"><img src="@/assets/travel_package/personal-info.svg" alt="" width="25px"
                                            style="margin-left: 2px;"></div>
                                    <div class="d-flex justify-center number-img">
                                        <div class="number"
                                            style="background-color: #DE5D0A;   border: 1px solid #DE5D0A;">1</div> <img
                                            src="@/assets/travel_package/Line.svg" alt="" style="margin-left: 2px;">
                                    </div>

                                    <div class="title-pagination mt-1">{{ $t('message.packageinformation') }}</div>
                                </div>
                                <div>
                                    <div style="width: 30px;"><img src="@/assets/travel_package/register-info.svg" alt="" width="25px"
                                            style="margin-left: 2px;"></div>
                                    <div class="d-flex justify-center number-img">
                                        <div class="number"
                                            :class="{ 'box-color': showNewContent == 2 || showNewContent == 3 }">2</div>
                                        <img src="@/assets/travel_package/Line.svg" alt="">
                                    </div>

                                    <div class="title-pagination mt-1">{{ $t('message.registerinformation') }}</div>
                                </div>
                                <div style="width: 30px;">
                                    <div style="width: 30px;"><img src="@/assets/travel_package/payment.svg" alt="" width="25px"
                                            style="margin-left: 2px;"></div>
                                    <div class="number-img" >
                                        <div class="number" :class="{ 'box-color': showNewContent == 3 }">3</div>
                                    </div>

                                    <div class="title-pagination mt-1">{{ $t('message.confirmPayment') }}</div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <transition v-if="showNewContent == 1" name="slide" mode="out-in">

                    <div key="box1">
                        <div class="row travelpackageheader " v-for="(item, i) in listonepacakge" :key="i">
                            <div class="row" style="position: relative; padding-left: 12px; padding-right: 12px;">
                                <div class="col-lg-7 col-md-7 col-sm-7">
                                    <img :src="item.otherPhoto[0].photo" alt="" width="100%" height="100%">
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center">
                                    <div class="d-flex justify-center" style="background: #FFFFFF; width: 100%;">
                                        <div class="mt-4" style="width: 90%; padding-bottom: 12px;">
                                            <h2 class="mb-2">{{ lang == 'en' ? item.name : lang == 'kh' ? item.nameKh :
                                                item.nameCn }}</h2>
                                            <span>{{ lang == 'en' ? item.description : lang == 'kh' ? item.descriptionKh
                                                : item.descriptionCn }}</span>
                                            <p class="mt-5" style="font-size: 22px;"><span style="font-weight: 650;">
                                                    {{ $t('message.price') }}:</span> <span style="color: #DE5D0A; font-weight: 550;"> ${{
                                                        item.price }}</span></p>
                                            <div>
                                                <v-btn @click="nextRegister()"
                                                    style="border-radius: 10px; width:  50%; outline: none;"
                                                    color="#DE5D0A" height="42px"
                                                    class="text-capitalize btn-width reposo-height">
                                                    <span style="color: #FFF; font-size: 16px !important;"> {{
                                                        $t('message.next') }} </span>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-12 col-md-12 col-sm-12" v-if="item.otherPhoto.length==1">
                                    <img :src="item.otherPhoto[0].photo" alt="" width="100%" height="95%">
                                </div>
                                <ssr-carousel v-else :slides-per-page='2' v-model='page'>
                                    <slide v-for="(photo, j) in item.otherPhoto"
                                        :key="j"
                                        :index="j"
                                        :class="j % 2 == 0 ? 'col-lg-7 col-md-7 col-sm-7' : 'col-lg-5 col-md-5 col-sm-5'"
                                        :style="{ 'margin-right': '0px !important', position: j % 2 == 0 ? '' : 'relative' }">   
                                        <img :src="photo.photo" alt="" width="100%" height="95%">     
                                            
                                    </slide> -->
                                <!-- <slide :index='2' class="col-lg-5 col-md-5 col-sm-5" :style="{ 'margin-right': '0px !important','position':'relative' }">   
                                        <img src="@/assets/travel_package/pk2.png" alt="" width="100%" height="95%">         
                                    </slide> -->

                                <!-- </ssr-carousel>
                                <div v-if="page>=1" class="d-flex align-center justify-center" style="position:absolute; left:1%; border-radius: 50%; padding: 22px; cursor: pointer; top: 40%; background: #00000040;">
                                    <img src="@/assets/travel_package/left-chevron.svg" alt="" width="30px">
                                </div>
                                <div v-if="item.otherPhoto.length>2" class="d-flex align-center justify-center" style="position:absolute; right:1%; border-radius: 50%; padding: 22px; cursor: pointer; top: 40%; background: #00000040;">
                                    <img src="@/assets/travel_package/right-chevron.svg" alt="" width="30px">
                                </div>  -->
                            </div>
                            <div class="row" style="margin-top: 20px !important;">
                                <div class="col-lg-8 col-md-8 col-sm-8">
                                    <!-- <div class="row ml-1 mb-2">
                                        <h1 style="color:#1D1D1D; margin-bottom: 8px;">{{ lang == 'en' ? item.name : lang == 'kh' ? item.nameKh : item.nameCn  }}</h1>
                                        <span class="mb-2">{{ lang == 'en' ? item.description : lang == 'kh' ? item.descriptionKh : item.descriptionCn }}</span>
                                        <v-btn  style="border-radius: 6px; width:  25%; outline: none;" color="#DE5D0A" height="40px"  class="text-capitalize screenlg btn-width reposo-height">
                                            <span style="color: #FFF; font-size: 15px !important;"> ${{ item.price }} </span>
                                        </v-btn>
                                    </div>     -->
                                    <div class="row " style="margin-top: 20px !important;">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="pa">
                                        <h3 class="terms-heading">{{ $t('message.TermCon') }}:</h3>
                                        <div class="terms-list">
                                            <ul>
                                                <li v-for="(paragraph, index) in getParagraphs(
                                                    lang == 'en' ? item.termCondition : 
                                                    lang == 'kh' ? item.termConditionKh : 
                                                    item.termConditionCn
                                                )" :key="index" v-html="paragraph"></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div>
                                <!-- <div class="col-lg-4 col-md-4 col-sm-4">  
                                    <div class="row d-flex justify-end mr-2">
                                        <v-btn  style="border-radius: 6px; width:  35%; outline: none;" color="#DE5D0A" height="40px"  class="text-capitalize destopscreen btn-width reposo-height">
                                            <span style="color: #FFF; font-size: 15px !important;"> ${{ item.price }} </span>
                                        </v-btn>
                                    </div>    
                                </div> -->
                            </div>
                        </div>
                    </div>
                </transition>
                <transition v-else-if="showNewContent == 2" name="slide" mode="out-in">
                    <div key="box2">
                        <div class="row">
                            <div v-if="isCameraOpen" style="width: 100%; height: 100%;" class="text-center open-camera">
                                <video ref="cameraPreview" width="70%" height="auto" autoplay></video>
                                <!-- <button >Capture</button> -->
                                <button type="button" class="button-capture" title="capture" @click="capturePhoto">
                                    <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
                                </button>
                                <button type="button" class="button-close" title="close" @click="toggleCamera">
                                    <img src="@/assets/travel_package/close.svg">
                                </button>
                            </div>
                        </div>
                        <div class="row travelpackageheader pl-lg-10 pl-md-10">
                            <div class="col-lg-4 col-md-4 mobileScreenimage">
                                <div class="row screenrow d-flex justify-center" style="margin: 0px;">
                                    <div class="col-lg-8 col-md-8"
                                        style="background-color: #FFFFFF; border-radius: 3px;">
                                        <div class="text-center show-profile">
                                            <img v-if="selectedFile" :src="disUrl" alt="" width="128px" height="128px">
                                            <img v-else src="@/assets/travel_package/profile.svg" alt="" width="128px"
                                                height="128px">
                                        </div>
                                        <div class="text-center" >
                                            <p style="padding-bottom: 0px; margin-bottom: 0px;">{{ $t('message.uploadphoto') }} <span
                                                    style="padding-left: 2px; color: #DE5D0A;"> *</span></p>
                                            <p style="padding-top: 0px;">{{ $t('message.please_p') }}</p>
                                        </div>
                                        <div class="row text-center mt-2 justify-center">
                                            <div class="d-flex justify-center" style="cursor: pointer; width: 45%;"
                                                @click="toggleCamera">
                                                <div>
                                                    <img v-if="!isCameraOpen" src="@/assets/travel_package/camera.svg"
                                                        alt="" style="margin-top: -2px;">
                                                    <img v-else src="@/assets/travel_package/close.svg" alt=""
                                                        width="25px" height="25px" style="margin-top: -2px;">
                                                </div>
                                                <div class="ml-1">
                                                    <span v-if="!isCameraOpen">{{ $t('message.camera') }}</span>
                                                    <span v-else>{{ $t('message.close') }}</span>
                                                </div>

                                            </div>
                                            <!-- <div style="width: 10%;">
                                                <img src="@/assets/travel_package/line-left.svg" alt="">
                                            </div> -->
                                            <div class="d-flex justify-center"
                                                style="cursor: pointer; width: 45%; border-left: 1px #6E6E73 solid;"
                                                @click="openFileInput">
                                                <input ref="fileInput" type="file" style="display: none;"
                                                    @change="handleFileChange">
                                                <div>
                                                    <img src="@/assets/travel_package/galary.svg" alt="">
                                                </div>
                                                <div class="ml-1">
                                                    {{ $t('message.gallery') }}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 fileshowcameraIpad">
                                <div class="row d-flex justify-center">
                                    <div class="col-lg-8 col-md-8 col-sm-8"
                                        style="background-color: #FFFFFF; border-radius: 3px;">
                                        <div class="text-center show-profile">
                                            <img v-if="selectedFile" :src="disUrl" alt="" width="128px" height="128px"
                                                style="object-fit: contain;">
                                            <!-- <img v-else src="@/assets/travel_package/profile.svg" alt="" width="128px"
                                                height="128px"> -->
                                        </div>
                                        <div class="text-center" >
                                            <p style="padding-bottom: 0px; margin-bottom: 0px;">{{ $t('message.uploadphoto') }} <span
                                                    style="padding-left: 2px; color: #DE5D0A;"> *</span></p>
                                            <p style="padding-top: 0px;">{{ $t('message.please_p') }}</p>
                                        </div>
                                        <div class="row text-center">
                                            <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center"
                                                style="cursor: pointer;" @click="toggleCamera">
                                                <div>
                                                    <img v-if="!isCameraOpen" src="@/assets/travel_package/camera.svg"
                                                        alt="" style="margin-top: -2px;">
                                                    <img v-else src="@/assets/travel_package/close.svg" alt=""
                                                        width="25px" height="25px" style="margin-top: -2px;">
                                                </div>
                                                <div class="ml-1">
                                                    <span v-if="!isCameraOpen">{{ $t('message.camera') }}</span>
                                                    <span v-else>{{ $t('message.close') }}</span>
                                                </div>

                                            </div>
                                            <!-- <div class="col-lg-2 col-md-2 col-sm-2">
                                                <img src="@/assets/travel_package/line-left.svg" alt="">
                                            </div> -->
                                            <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center"
                                                style="cursor: pointer; border-left: 1px #6E6E73 solid;"
                                                @click="openFileInput">
                                                <input ref="fileInput" type="file" style="display: none;"
                                                    @change="handleFileChange">
                                                <div>
                                                    <img src="@/assets/travel_package/galary.svg" alt="">
                                                </div>
                                                <div class="ml-1">
                                                    {{ $t('message.gallery') }}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <div class="group-form">
                                            <label for="" class="hide-label equal_label">{{ $t('message.enterFullName') }}<span
                                                    style="color: #FF0000;"> *</span></label>
                                            <input type="text" v-model="names"
                                                :class="{ 'is-invalid': isRegisterBuyPackage && $v.names.$error }"
                                                class="custom-input equals_input" :placeholder="$t('message.enterFullName')">
                                            <div v-if="isRegisterBuyPackage && !$v.names.required"
                                                class="invalid-feedback">{{ $t('message.fullnameRequired') }}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                        <div class="group-form">
                                            <label for="" class="hide-label equal_label">{{ $t('message.gender') }} <span
                                                    style="color: #FF0000;">*</span></label>
                                            <div class="equals_input" style="position: relative;">
                                                <div style="position: relative;">
                                                    <input type="text"
                                                        :class="{ 'is-invalid': isRegisterBuyPackage && $v.selectedOptionGender.$error }"
                                                        @click="selectGender()" v-model="selectedOptionGender" readonly
                                                        class="custom-input" :placeholder="$t('message.selectGender')"
                                                        style="cursor: pointer;">
                                                    <i @click="selectGender()"
                                                        style="position: absolute;top: 34%;right: 9px; color: #6E6E73;"
                                                        class="fa-sharp fa-solid fa-caret-down"></i>
                                                </div>
                                                <div v-if="isRegisterBuyPackage && !$v.selectedOptionGender.required"
                                                    class="invalid-feedback">{{ $t('message.genderisRequired') }}</div>

                                                <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px;"
                                                    v-if="showListDataGen" v-click-outside="hidePopupGen">
                                                    <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;"
                                                        class="hover-color" @click="setGender(1)">{{ $t('message.male') }}</div>
                                                    <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;"
                                                        class="hover-color" @click="setGender(2)">{{ $t('message.Female') }}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                        <div class="group-form">
                                            <label for="" class="hide-label equal_label">
                                                {{ $t('message.nationality') }}<span style="color: #FF0000;"> * </span>
                                            </label>
                                            <div class="equals_input" style="position: relative;">
                                                <div style="position: relative;">
                                                    <i @click="selectNation()"
                                                        style="position: absolute;top: 34%;right: 9px; color: #6E6E73;"
                                                        class="fa-sharp fa-solid fa-caret-down"></i>
                                                    <input @click="selectNation()" @input="filterNations" type="text"
                                                        v-model="nationality"
                                                        :class="{ 'is-invalid': is_register && $v.nationality.$error }"
                                                        class="custom-input"
                                                        :placeholder="$t('message.selectNationality')" />
                                                </div>


                                            </div>
                                            <div class="equals_input" style="position: relative;">
                                                <!-- Custom Dropdown -->
                                                <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px; height: 200px; overflow: auto"
                                                    v-if="showListDataNation" v-click-outside="hidePopupNation">
                                                    <div v-for="(nation, i) in filteredNations" :key="i"
                                                        @click="setNational(nation.id, nation.name)" class="hover-color"
                                                        style="margin: 10px 0; cursor: pointer; padding-left: 12px; padding-top: 5px; padding-bottom: 5px;">
                                                        {{ nation.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <div class="group-form">
                                            <label for="" class="hide-label equal_label">{{ $t('message.emailAddress') }}
                                                <span style="color: #FF0000; padding-left: 2px;"> *</span>
                                                </label>
                                            <input type="email"
                                                :class="{ 'is-invalid': isRegisterBuyPackage && $v.email.$error }"
                                                v-model="email" class="custom-input equals_input" :placeholder="$t('message.emailAddress')">
                                            <!-- <div v-if="isRegisterBuyPackage && !$v.email.required"
                                                class="invalid-feedback">{{ $t('message.emailRequired') }}</div> -->
                                            <div v-if="msg.email" style="color: #FF0000;">{{ msg.email }}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="group-form">
                                            <label for="" class="hide-label equal_label">{{ $t('message.telephoneNumber') }} 
                                                <span style="color: #C6C6C6; padding-left: 2px;">
                                                    ({{ $t('message.optional') }})</span>
                                                </label>
                                            <div class="number-phone-box"
                                                style="display: flex; align-items: center; border-radius: 6px; border: 1px solid #C6C6C6;">
                                                <div
                                                    style="width: 16%; height: 30px; display: flex; justify-content: center; align-items: center; border-right: 1px solid #C6C6C6;">
                                                    +855
                                                </div>
                                                <div style="width: 84%;">
                                                    <input @input="validatePhoneNumber" 
                                                           type="tel"
                                                           v-model="telephoneNumber"
                                                           class="custom-input equals_input form-password"
                                                           :placeholder="$t('message.phoneNumber')" />
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="phoneNumberError.phone" style="color: #FF0000;">{{
                                            phoneNumberError.phone }}</span>
                                    </div>
                                </div>
                                <div class="row mt-15">
                                    <div class="col-lg-10 col-md-10 col-sm-10">
                                        <v-btn @click="toggleaddRegister" :disabled="disabled"
                                            style="border-radius: 6px; width:  30%; outline: none;" color="#DE5D0A"
                                            height="40px"
                                            class="text-capitalize btn-width btn-width-mobile reposo-height">
                                            <span style="color: #FFF; font-size: 15px !important;"> {{ $t('message.' + titlecontent1) }}
                                            </span>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 fileshowcamera">
                                <div class="row d-flex justify-center">
                                    <div class="col-lg-8 col-md-8 col-sm-8"
                                        style="background-color: #FFFFFF; border-radius: 3px;">
                                        <div class="text-center show-profile">
                                            <img v-if="selectedFile" :src="disUrl" alt="" width="128px" height="128px">
                                            <img v-else src="@/assets/profile/default-profile.svg" alt="" width="128px"
                                                height="128px">
                                        </div>
                                        <div class="text-center" style="">
                                            <p style="padding-bottom: 0px; margin-bottom: 0px;">{{ $t('message.uploadphoto') }} <span
                                                    style="padding-left: 2px; color: #DE5D0A;"> *</span></p>
                                            <p style="padding-top: 0px;">{{ $t('message.please_p') }}</p>
                                        </div>
                                        <div class="row text-center" style="display: flex; justify-content: center;">
                                            <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center"
                                                style="cursor: pointer;" @click="toggleCamera">
                                                <div>
                                                    <img v-if="!isCameraOpen" src="@/assets/travel_package/camera.svg"
                                                        alt="" style="margin-top: -2px;">
                                                    <img v-else src="@/assets/travel_package/close.svg" alt=""
                                                        width="25px" height="25px" style="margin-top: -2px;">
                                                </div>
                                                <div class="ml-1">
                                                    <span v-if="!isCameraOpen" class="fontsizeIpad">{{ $t('message.camera') }}</span>
                                                    <span v-else class="fontsizeIpad">{{ $t('message.close') }}</span>
                                                </div>

                                            </div>
                                            
                                            <div class="" style="display: flex; justify-content: center; align-items: center;">
                                            <!-- <img src="@/assets/travel_package/line-left.svg" alt=""> -->
                                            <i class="pad-top"></i>
                                            </div>
                                            <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center"
                                                style="cursor: pointer;" @click="openFileInput">
                                                <!-- <i class="pad-top"></i> -->
                                                <input ref="fileInput" type="file" style="display: none;"
                                                    @change="handleFileChange">
                                                <div>
                                                    <img src="@/assets/travel_package/galary.svg" alt="">
                                                </div>
                                                <div class="fontsizeIpad ml-1">
                                                    {{ $t('message.gallery') }}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>


            </div>
        </div>
    </div>
</template>
<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import {
    required,
    email,
} from "vuelidate/lib/validators";
import axios from 'axios';
import qs from 'qs';
export default {
    // components: { DatePicker },


    data() {
        return {

            index: 0,
            showListDataGen: false,
            showListDataNation: false,
            telephoneNumber: '',
            dob: localStorage.getItem('dobProfile'),
            selectedFile: null,
            // showPassword: false,
            isCameraOpen: false,
            videoStream: null,
            showNewContent: 1,
            radioGroup: 5,
            listonepacakge: [],
            listnation: [],
            filteredNations: [],
            msg: [],
            titlecontent1: 'next',
            disabled: false,
            lang: localStorage.getItem("lang"),

            names: '',
            selectedOptionGender: '',
            selectedOptionGenderid: '',
            nationality: '',
            nationalityid: '',
            email: '',
            imageUrl: '',
            address: localStorage.getItem('addressProfile'),
            maxLength: 9,  // minimum length for phone number
            maxLengths: 10, // maximum length for phone number
            phoneNumberError: [],
            id_travel_package: this.$route.query.id,
            // loading: true,
            isRegisterBuyPackage: false,
            page: 0,
            disUrl: ''

        }
    },
    validations: {
        names: { required },
        selectedOptionGender: {
            required(value) {
                return !!value; // Ensures it's truthy (non-empty string)
            },
        },
        nationality: { required },
        email: { email },
    },
    watch: {
        email(value) {
            // binding this to the data value in the email input
            this.email = value;
            this.validateEmail(value);
        },
        telephoneNumber(value) {
            this.telephoneNumber = value;
            this.validatePhoneNumber(value);
        },
    },
    computed: {
        profileImage() {
            return this.imageUrl
                ? `${this.$imgUrl}${this.imageUrl}`
                : require('@/assets/profile/default-profile.svg');
        },
        isFormValid() {
            const hasValidEmail = this.email.trim() && !this.msg['email'];
            const hasValidPhone = this.telephoneNumber.trim() && !this.phoneNumberError['phone'];
            return hasValidEmail || hasValidPhone;
        }
    },
    methods: {
        openFileInput() {
            this.$refs.fileInput.click();
        },
        async handleFileChange(event) {
            const file = event.target.files[0]; // Get the selected file
            if (file) {
                this.selectedFile = file; // Store the selected file
                this.imageUrl = URL.createObjectURL(file);
                await this.uploadFile(); // Trigger the upload
            }
        },

        async getMyprofile() {
            const url = this.$url + `user-register/me`;
            const data = {
                'session': localStorage.getItem('session_start_login'),
            }
            let config = {
                method: "POST",
                headers: {
                    ...this.$header,
                    "Content-Type": "multipart/form-data"
                },
                data: data,
                url,
            };

            axios(config).then((response) => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.names = response.data.body.name;
                        this.email = response.data.body.email;
                        this.telephoneNumber = response.data.body.telephone;
                        //nationality
                        this.nationality = response.data.body.nationality;
                        this.nationalityid = response.data.body.nationalityId;

                        if (response.data.body.gender) {
                            this.selectedOptionGender = response.data.body.gender === 1 ? this.$t('message.male') : this.$t('message.Female');
                            this.selectedOptionGenderid = response.data.body.gender;
                        }
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },

        async uploadFile() {
            const url = this.$upload + 'uploads/uploadPhotoTravelPackageOrder';
            let formData = new FormData();
            formData.append('photo', this.selectedFile);
            formData.append('token', this.$token);

            try {
                const response = await axios.post(url, formData, {
                    headers: {
                        ...this.$header,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response) {
                    this.disUrl = this.$imgUrl + response.data.img; // Save uploaded filename
                    this.imageUrl = response.data.img; // Update image URL
                } else {
                    alert('Upload failed. Please try again.');
                }
            } catch (error) {
                console.error('Upload Error:', error);
                alert('Error uploading file.');
            }
        },

        getParagraphs(description) {
            // Split the description into paragraphs using \n as delimiter
            return description.split('\n').map(paragraph => paragraph.trim());
        },
        nextSlide() {
            this.page++;
            if (this.page >= this.page.length) {
                this.page = 0;
            }
        },
        toggleCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.startCameraStream();
            }
        },
        startCameraStream() {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(stream => {
                    this.$refs.cameraPreview.srcObject = stream;
                    this.videoStream = stream;
                })
                .catch(error => {
                    console.error('Error accessing camera:', error);
                });
        },
        stopCameraStream() {
            if (this.videoStream) {
                const tracks = this.videoStream.getTracks();
                tracks.forEach(track => track.stop());
            }
        },
        openCameraUrl() {
            window.open('http://192.168.3.1:8080/', '_blank');
        },
        capturePhoto() {
            const canvas = document.createElement('canvas');
            canvas.width = this.$refs.cameraPreview.videoWidth;
            canvas.height = this.$refs.cameraPreview.videoHeight;
            const context = canvas.getContext('2d');

            // Draw the current frame from the video stream onto the canvas
            context.drawImage(this.$refs.cameraPreview, 0, 0, canvas.width, canvas.height);

            // Convert canvas content to Blob
            canvas.toBlob((blob) => {
                // Create a File object from the Blob
                this.selectedFile = new File([blob], 'captured-photo.png', { type: 'image/png' });

                this.disUrl = URL.createObjectURL(blob);
                this.stopCameraStream();
                this.isCameraOpen = false;

                // Pass the file to the upload function
                this.uploadFile();
            }, 'image/png');
        },
        setGender(value) {
            this.index = value;
            if (this.index == 1) {
                this.selectedOptionGender = this.$t('message.male');
                this.selectedOptionGenderid = 1;
            } else if (this.index == 2) {
                this.selectedOptionGender = this.$t('message.Female');
                this.selectedOptionGenderid = 2;
            }
            this.showListDataGen = false;
        },
        selectGender() {
            this.showListDataGen = true;
        },

        hidePopupGen() {
            this.showListDataGen = false;
        },

        //nationality
        setNational(id, name) {
            // this.index = value;
            // localStorage.setItem('nationalityIdProfile',  this.index);
            this.nationality = name;
            this.nationalityid = id;
            this.showListDataNation = false;
        },
        hidePopupNation() {
            this.showListDataNation = false;
            this.filteredNations = [...this.listnation];
        },
        async selectNation() {
            try {
                if (!this.listnation || this.listnation.length === 0) {
                    await this.getNationality();
                }
                // Only show dropdown if clicked, don't toggle
                if (!this.showListDataNation) {
                    this.filteredNations = [...this.listnation];
                    this.showListDataNation = true;
                }
            } catch (error) {
                console.error('Error loading nationalities:', error);
            }
        },
        async getNationality() {
            const url = this.$url + `travel-package/nationalityList`;

            let config = {
                method: "POST",
                headers: this.$header,
                url,
            };

            axios(config).then((response) => {
                if (response.status == 200) {

                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        if (response.data.body.status == true || response.data.body.message == 'Success') {
                            this.listnation = response.data.body.data;
                            if (this.nationalityid == '' && this.nationality == '') {
                                for (var i in this.listnation) {
                                    if (localStorage.getItem('nationalityIdProfile') == this.listnation[i].name) {
                                        this.nationality = this.listnation[i].name;
                                        this.nationalityid = this.listnation[i].id;
                                        this.showListDataNation = false;
                                    }
                                }
                            }
                        } else {
                            alert(response.data.body.message)
                        }

                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        async getTravelPackageOneregister() {
            const url = this.$url + `travel-package/find/` + this.$route.query.id;
            const data = {
                'id': this.$route.query.id,
                'photoPath': this.$route.query.photo,
            }
            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url,
            };

            axios(config).then((response) => {
                if (response.status == 200) {
                    this.loading = false;
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        if (response.data.body.status == true || response.data.body.message == 'Success') {
                            this.listonepacakge = response.data.body.data;
                            localStorage.setItem('pricetravelPk', response.data.body.data[0].price);
                        } else {
                            alert(response.data.body.message);
                        }

                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        toggleaddRegister() {
            this.isRegisterBuyPackage = true;
            this.$v.$touch();
            
            // Check if either email or phone is provided and valid
            const hasValidEmail = this.email && !this.msg['email'];
            const hasValidPhone = this.telephoneNumber && !this.phoneNumberError['phone'];

            if (this.$v.$invalid && !(hasValidEmail || hasValidPhone)) {
             
                return;
            } else if (this.imageUrl == '') {
                alert(this.$t('message.pleasechooseaphoto'));
            } else if (this.id_travel_package == '') {
                alert('Travel Package not found!.');
            } else if (!this.email && !this.telephoneNumber) {
                alert(this.$t('message.pleaseProvideEmailOrPhone'));
                return;
            } else if (!hasValidEmail && !hasValidPhone) {
                if (this.msg['email']) {
                    alert(this.msg['email']);
                } else if (this.phoneNumberError['phone']) {
                    alert(this.phoneNumberError['phone']);
                }
                return;
            } else {
                // Proceed with form submission
                this.titlecontent1 = "Loading";
                this.disabled = true;

                let session = localStorage.getItem('session_start_login');
                const data = {
                    'session': session,
                    'name': this.names,
                    'sex': this.selectedOptionGenderid,
                    'nationality': this.nationalityid,
                    'photo': this.imageUrl,
                    // 'photo'       :' 1.jpg',
                    'email': this.email,
                    'telephone': this.telephoneNumber,
                    'dob': this.dob ? moment(this.dob).format('YYYY-MM-DD') : null, // Format the date in 'YYYY-MM-DD' format
                    'address': this.address,
                    'travelPackageId': this.id_travel_package,
                }
                console.log(data);
                const url = this.$url + `travel-package/confirm`;
                let config = {
                    method: "POST",
                    headers: {
                        ...this.$header,
                        "Content-Type": "multipart/form-data"
                    },
                    data: data,
                    url
                };

                axios(config).then((response) => {
                    if (response.status == 200) {
                        this.titlecontent1 = "next";
                        this.disabled = false;
                        if (response.data.header.result == true && response.data.header.statusCode == 200) {
                            if (response.data.body.status == true || response.data.body.message == 'success') {
                                localStorage.setItem('codeTravelpk', response.data.body.transactionId);
                                this.$router.push({
                                    path: '/payment-travel-pk',
                                    query: {
                                        code: response.data.body.transactionId,
                                        price: localStorage.getItem('pricetravelPk')
                                    }
                                });
                            } else {
                                alert(response.data.body.message);
                            }
                        } else {
                            alert('Something went wrong, Please try it again.')
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        },
        paymentRoute() {
            this.$router.push({
                path: '/payment-travel-pk',
                query: {
                    code: localStorage.getItem('codeTravelpk'),
                    price: localStorage.getItem('pricetravelPk')
                }
            });
        },

        nextRegister() {
            // Check if user is logged in
            const isLoggedIn = localStorage.getItem('session_start_login'); // Replace with your actual login check

            if (!isLoggedIn) {
                localStorage.setItem('redirect_after_login', this.$route.fullPath);
                // Redirect to login page if not logged in
                this.$router.push('/login');
                return; // Exit the method to prevent further execution
            }

            // Set content to show and update localStorage if user is logged in
            this.showNewContent = 2;
            localStorage.setItem("showNewContent", this.showNewContent);
        },
        validatePhoneNumber(value) {
            // Clear error if empty (since it's optional if email is provided)
            if (!value.trim()) {
                this.phoneNumberError['phone'] = '';
                return;
            }

            // Remove any spaces or special characters
            const cleanNumber = value.replace(/[^\d]/g, '');
            
            // Check if number starts with 0
            if (!cleanNumber.startsWith('0')) {
                this.phoneNumberError['phone'] = this.$t('message.phoneNumberMustStartWithZero');
                return;
            }

            // Check length is between 9-10 digits
            if (cleanNumber.length < 9 || cleanNumber.length > 10) {
                this.phoneNumberError['phone'] = this.$t('message.phoneNumberMustBe9or10Digits');
                return;
            }

            // Valid phone number
            this.phoneNumberError['phone'] = '';
        },

        validateEmail(value) {
            // Clear error if empty (since it's optional if phone is provided)
            if (!value.trim()) {
                this.msg['email'] = '';
                return;
            }

            // Only validate format if email is provided
            if (/^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                this.msg['email'] = '';
            } else {
                this.msg['email'] = this.$t('message.emailInvalid');
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        filterNations() {
            if (!this.nationality) {
                this.filteredNations = [...this.listnation];
            } else {
                const searchTerm = this.nationality.toLowerCase();
                this.filteredNations = this.listnation.filter(nation =>
                    nation.name.toLowerCase().includes(searchTerm)
                );
            }
            this.showListDataNation = true;
        },
    },
    created() {
        this.getTravelPackageOneregister();
        this.getMyprofile();
        this.getNationality(); // This will load the data without showing the dropdown
        if (localStorage.getItem('showNewContent') == 1) {
            this.showNewContent = 1;
        } else if (localStorage.getItem('showNewContent') == 2) {
            this.showNewContent = 2;
        }
        localStorage.setItem("showNewContent", this.showNewContent);
        this.scrollToTop();
        //   setInterval(() => {
        //         this.nextSlide();
        //     }, 5000);



    },
    mounted() {
        let telephone = localStorage.getItem('telephoneProfile');
        if (telephone == "undefined") {
            this.telephoneNumber = '';
        } else {
            this.telephoneNumber = telephone;
        }
        // handle back page
        window.onpopstate = () => {
            if (this.showNewContent > 1) {
                this.showNewContent--;
                localStorage.setItem("showNewContent", this.showNewContent);
            }
        };
    },
    beforeUnmount() {
        window.onpopstate = null;
    },
}
</script>

<style scoped>
.buytravelpackage {
    padding-top: 55px;
}

.pad-top {
    padding-top: 15px;
    border-right: none;
    border-left: 2px solid #EEEEEE;
    height: 30px;
}

.invalid-feedback {
    color: #FF0000;
}

.is-invalid {
    border: 1px solid #FF0000 !important;
}

.travelpackageheader {
    padding-top: 35px;
    padding-bottom: 70px;
}

.button {
    margin-left: -28px;
    color: #B7B5B5;
}

.open-camera {
    position: relative;
}

.select-radio .card-select-payment {
    position: relative;
    background: #EEEEF3;
    margin-top: 12px;
    padding: 17px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.select-radio .card-select-payment .text .title {
    font-weight: 600;
    font-size: 12px !important;
    color: #424245;
}

.passDetails {
    display: flex;
    align-items: center;
}

.passDetails .number {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #312783;
    background: #312783;
}

.passDetails .title-pass {
    margin-left: 12px;
    font-weight: 600;
    font-size: 18px;
    color: #0c2f54;
}

.select-radio .card-select-payment .text .txt {
    font-weight: 400;
    font-size: 12px !important;
    color: #6E6E73;
}

.select-radio .card-select-payment .img-card {
    width: 10%;
}

.select-radio .card-select-payment .text {
    width: 80%;
}

.button-capture {
    position: absolute;
    bottom: 5%;
    right: 41%;
    width: 80px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    /* border-radius: 50%; */
    border-radius: 5%;

}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.5s;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave {
    transform: translateX(0%);
}

.slide-enter,
.slide-leave-from {
    transform: translateX(100%);
}

.mobileScreenimage {
    display: none;
}

.fileshowcameraIpad {
    display: none !important;
}

.screenlg {
    display: none !important;
}

.button-close {
    position: absolute;
    bottom: 5%;
    left: 41%;
    width: 80px;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    /* border-radius: 50%; */
    border-radius: 5%;
    height: 35px;
}

.button-close img {
    height: 35px;
    object-fit: cover;
}

.button-capture img {
    height: 35px;
    object-fit: cover;
}

.grid-package {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.number {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #312783;
    background: #312783;
}

.box-color {
    background-color: rgb(222, 93, 10);
    border: 1px solid rgb(222, 93, 10);
}

.title-pagination {
    margin-left: -50px;
text-align: center;
    color: #1D1D1D;
    width: 143px;

}

.mobile-screen {
    margin-left: 32px !important;
}

.custom-input {
    /* cursor: pointer; */
    padding-left: 12px;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #C6C6C6;
}

.custom-select {
    margin-top: 0px;
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 40px;
    line-height: 40px;
    padding-top: 5px;
}

.hover-color:hover {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    color: #ffffff;
    background: #DE5D0A;
}

.form-password {
    border: none !important;
    border-radius: none !important;
}

.mx-datepicker-popup {
    top: 599px !important;
}

.mx-datepicker {
    width: 100%;
}

.mx-datepicker-body .mx-calendar {
    width: 281px;
}

.custom-input-textarea {
    padding-left: 12px;
    padding-top: 5px;
    border-radius: 4px;
    width: 100%;
    height: 100px;
    outline: none;
    border: 1px solid #C6C6C6;
}


@media screen and (max-width: 961px) and (min-width: 700px) {

    .number-img img {
        width: 230px;

    }

    .fileshowcameraIpad {
        display: block !important;
    }

    .fileshowcamera {
        display: none;
    }

    .button-capture {
        left: 52% !important;
    }

    .button-close {
        left: 34% !important;

    }




}

@media screen and (width < 700px) {

    .number-img img {
        width: 160px;

    }

    .button-capture {
        left: 55% !important;
    }

    .button-close {
        left: 30% !important;

    }

    .mobileScreenimage {
        display: block !important;
    }

    .fileshowcamera {
        display: none;
    }

}

@media screen and (width < 600px) {
    .screenlg {
        display: block !important;
    }

    .destopscreen {
        display: none !important;
    }
}

@media screen and (width < 500px) {

    .travelpackageheader {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .number-img img {
        width: 100px;

    }

    .title-pagination {
        font-size: 10px;
        margin-left: -50px;
    }

    .mobile-screen {
        margin-left: 5px !important;
    }

    .fileshowcamera {
        display: none;
    }

    .mobileScreenimage {
        display: block !important;
    }

    .button-capture {
        left: 55% !important;
    }

    .button-close {
        left: 30% !important;

    }

    .fileshowcameraIpad {
        display: none !important;
    }

    .screenlg {
        display: block !important;
    }

    .destopscreen {
        display: none !important;
    }

    .select-radio .card-select-payment {
        padding: 8px;
    }

    .passDetails .title-pass {
        font-size: 13px;
    }

    .select-radio .card-select-payment .text {

        padding-left: 18px;
    }

    .btn-width-mobile {
        width: 30%;
    }

}

@media screen and (width < 438px) {

    .travelpackageheader {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .number-img img {
        width: 100px;
    }

    .title-pagination {
        font-size: 10px;
        margin-left: -50px;
    }

    .fileshowcameraIpad {
        display: none !important;
    }

    .mobile-screen {
        margin-left: 5px !important;
    }

    .fileshowcamera {
        display: none;
    }

    .mobileScreenimage {
        display: block !important;
    }

    .button-capture {
        left: 55% !important;
    }

    .button-close {
        left: 30% !important;

    }

    .screenlg {
        display: block !important;
    }


}

@media screen and (width < 372px) {
    .mobile-screen {
        margin-left: 17px !important;
    }

    .screenrow {
        margin-top: -40px !important;
    }
}
@media screen and (width < 333px) {
    .mobile-screen {
        margin-left: 12px !important;
    }

    .screenrow {
        margin-top: -40px !important;
    }
}

.pa {
    padding: 15px;
}

.terms-heading {
    color: #1D1D1D;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 600;
}

.terms-list ul {
    padding-left: 0;
    margin: 0;
}

.terms-list li {
    list-style-type: none;
    line-height: 1.7;
    font-size: 14px;
    margin-bottom: 8px;
    color: #333;
}

@media screen and (max-width: 767px) {
    .pa {
        padding: 12px;
    }

    .terms-heading {
        font-size: 16px;
    }

    .terms-list li {
        font-size: 13px;
        line-height: 1.6;
    }
}

@media screen and (max-width: 480px) {
    .pa {
        padding: 10px;
    }

    .terms-list li {
        font-size: 12px;
        line-height: 1.5;
    }
}
</style>