<template>
    <div>
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div class="mobiles-app" v-else>
            <div class="containers">
                
                <div v-if="direction">
                    <div class="dparture">
                        <div class="date">
                            <div style="display: flex;align-items: center;padding-bottom: 5px;">
                                <img src="@/assets/icon_bars/place_map.svg"
                                    style="width: 25px;margin-left: -6px;margin-right: 6px;" alt="">
                                <span>{{ getDestinationFrom() }} {{ $t('message.to') }} {{ getdestinationToName() }}</span>
                            </div>
                            <div style="padding-left: 24px;">
                                <span>{{ getDateFrom() }}</span>
                            </div>
                        </div>

                        <div class="search-img">
                            <img src="@/assets/icon_bars/adjust.svg" @click="showFormBook" alt="">
                        </div>
                    </div>
                </div>
                <div class="show-form" v-if="showForm">
                    <form-card />
                </div>
                <div v-if="Result">
                    <div class="not-found">
                        <img src="@/assets/icon-social-media/no-schedule.png" alt="No Results">
                        <div class="not-found-text">{{ $t('message.noSchedule') }}</div>
                    </div>
                </div>
                <div class="card-info" v-for="(item, i) in info" :key="i">
                    <div class="card-box">
                        <div class="box">
                            <div class="logo-type-bus">
                                <div style="width: 15%;">
                                    <img v-if="item.scheduleType == 1" src="@/assets/vireak-buntham.png"
                                        style="width: 50px" alt="">
                                    <img v-if="item.scheduleType == 3" src="@/assets/airbus.png" style="width: 50px"
                                        alt="">
                                </div>
                                <div style="width: 85%;">
                                    <div class="type-bus" style="display: flex; justify-content: space-between;">
                                        <div> {{ item.transportationType }} </div>
                                        <!-- seat for apply status -->
                                        <div class="seat" v-if="item.status == 2"></div>
                                        <div class="seat" v-else-if="item.status == 1">
                                            <span style="color: #DE5D0A;">{{ item.totalSeat - item.seatAvailable
                                                }}</span>/{{ item.totalSeat }} {{ $t('message.seat') }}
                                        </div>
                                        <div class="seat" v-else>
                                            <div v-if="item.status == 3">
                                                <span style="color: #DE5D0A;">{{ item.totalSeat }}</span>/{{
                                                item.totalSeat }} {{ $t('message.seat') }}
                                            </div>
                                            <div v-else>

                                            </div>
                                        </div>
                                    </div>
                                    <div>{{ item.transportRouteDisplay }} - {{ item.nationRoad }}</div>
                                </div>

                            </div>

                            <div class="desta-role">
                                <div class="dp-time">
                                    <div class="text-depature">
                                        <div class="all-parture">{{ $t('message.departure') }}</div>
                                        <div class="all-parture text-center" style="font-weight: 600">{{
                                            convertTime(item.duration) }}</div>
                                        <div class="all-parture text-end">{{ $t('message.arrival') }}</div>
                                    </div>
                                    <div class="number-depature">
                                        <div class="all-part-num">{{ convertTimes(item.departure) }}</div>
                                        <div class="all-part-num text-center"
                                            style="display: flex;width: 100%;align-items: center;padding-left: 10px;padding-right: 10px;">
                                            <hr style="width: 100%">
                                        </div>
                                        <div class="all-part-num text-end">{{ convertTimes(item.arrival) }}</div>
                                    </div>
                                </div>

                                <div class="price">
                                    <div v-if="selectNational">
                                        ${{ item.price }}
                                        <span v-if="item.priceOriginal == ''"
                                            style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;"></span>
                                        <div v-else
                                            style="position: absolute;right: 0;top: -12px;font-size: 16px;color: #616163;text-decoration: line-through;">
                                            {{ item.priceOriginal }}</div>
                                    </div>
                                    <div v-else>
                                        <span v-if="item.priceOriginal != ''">${{ item.price }} </span>
                                        <span v-else>${{ item.price }} </span>
                                        <span v-if="item.priceOriginal == ''"
                                            style="color: #6E6E73;font-size: 20px;font-weight: 600;text-decoration: line-through;"></span>
                                        <div v-else
                                            style="position: absolute;right: 0;top: -12px;font-size: 16px;color: #616163;text-decoration: line-through;">
                                            $ {{ item.priceOriginal }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="national-role">
                                <div class="boarding" style="display: flex;">
                                    <div class="mr-1" style="font-size: 14px; white-space: nowrap;">
                                        {{ $t('message.boarding') }}:
                                    </div>
                                    <div>
                                        <div style="color: #DE5D0A;font-size: 14px;"
                                            v-for="(boardingPoint, i) in item.boardingPointList" :key="i"> - {{
                                            boardingPoint.name }} {{ convertTimes(boardingPoint.time) }}</div>
                                    </div>
                                    <!-- <span style="color: #DE5D0A;font-size: 14px;" v-for="(boardingPoint, i) in item.boardingPointList" :key="i"> {{ boardingPoint.name }} {{ i ==  item.boardingPointList.length-1 ? "": " | " }}</span></div> -->
                                </div>
                                <!-- <div class="seat" v-if="item.status == 2">
                        
                                </div>
                                <div class="seat" v-else-if="item.status == 1">
                                    <span style="color: #DE5D0A;">{{ item.totalSeat - item.seatAvailable }}</span>/{{ item.totalSeat }} Seat
                                </div>
                                <div class="seat" v-else>
                                    <div v-if="item.status == 3">
                                        <span style="color: #DE5D0A;">{{ item.totalSeat }}</span>/{{ item.totalSeat }} Seat
                                    </div>
                                    <div v-else>

                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="boxs">
                        <div class="trip-info">
                            <div class="infos"
                                @click="tirpInfo(item.note, item.slidePhoto, item.transportationPhoto, item.amenities, item.dropOffPointLats, item.dropOffPointLongs, item.boardingPointLats, item.boardingPointLongs, item.boardingPointList, item.dropOffPointList)">
                                <img src="@/assets/icon_bars/info.svg" alt="">
                                <div>{{ $t('message.tripInfo') }}</div>
                            </div>
                        </div>
                        <div class="booking">
                            <div>
                                <div v-if="selectNational">
                                    <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1"
                                        @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.priceOriginal, item.discount, item.disPercent)">
                                        {{ $t('message.bookNow') }}</div>
                                    <div class="btn-books" v-if="item.status == 1 && item.scheduleType == 3"
                                        @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.priceOriginal, item.discount, item.disPercent)">
                                        {{ $t('message.bookNow') }}</div>
                                </div>
                                <div v-else>
                                    <div v-if="item.priceOriginal != ''">
                                        <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1"
                                            @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.price, item.discount, item.disPercent)">
                                            {{ $t('message.bookNow') }}</div>
                                        <div class="btn-book-air" v-if="item.status == 1 && item.scheduleType == 3"
                                            @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.price, item.discount, item.disPercent)">
                                            {{ $t('message.bookNow') }}</div>
                                    </div>
                                    <div v-else>
                                        <div class="btn-book" v-if="item.status == 1 && item.scheduleType == 1"
                                            @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.priceOriginal, item.discount, item.disPercent)">
                                            {{ $t('message.bookNow') }}</div>
                                        <div class="btn-books" v-if="item.status == 1 && item.scheduleType == 3"
                                            @click="bookSeat(item.id, item.price, item.departure, item.duration, item.arrival, item.priceOriginal, item.discount, item.disPercent)">
                                            {{ $t('message.bookNow') }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-leaving" v-if="item.status == 2">
                                {{ $t('message.left') }}
                            </div>
                            <div class="btn-leaving" v-if="item.status == 3">
                                {{ $t('message.full') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormCard from '../froms/FormCard.vue';
import axios from 'axios';
import qs from 'qs';
export default {
    components: { FormCard },
    data() {
        return {
            loading: true,
            selectNational: false,
            direction: true,
            showForm: false,
            info: [],
            Result: false,
        }
    },
    methods: {
        getDestinationFrom() { return localStorage.getItem('departureFromName'); },
        getdestinationToName() { return localStorage.getItem('destinationToName'); },
        getDateFrom() { return localStorage.getItem('dateFrom'); },
        showFormBook() {
            this.showForm = true;
            this.direction = false;
            localStorage.setItem("book-again", 1);
        },

        // Start List schedule from api
        async getDataSchedule() {
            const data = {
                date: localStorage.getItem('dateFrom'),
                destinationFrom: localStorage.getItem('departureFromId'),
                destinationTo: localStorage.getItem('destinationToId'),
                nationally: localStorage.getItem('selectNational'),
                type: localStorage.getItem('types'),
            };
            const url = this.$url + `schedule/listByDate`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url,
            };
            this.loading = true;

            axios(config).then((response) => {
                this.loading = false;
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.info = response.data.body;
                        // Set Result based on info array length
                        this.Result = this.info.length === 0;
                    } else {
                        this.Result = true;
                    }
                }
            }).catch((error) => {
                console.log(error);
                this.Result = true;  // Show no results on error
            });
        },
        bookSeat(id, price, departure, duration, arrival, priceOriginal, discount, disPercent) {
            localStorage.setItem('journeyid', id);
            localStorage.setItem('price', price);
            localStorage.setItem('departure', departure);
            localStorage.setItem('arrival', arrival);
            localStorage.setItem('duration', duration);
            localStorage.setItem('priceOriginal', priceOriginal);
            localStorage.setItem('discount', discount);
            localStorage.setItem('disPercent', disPercent);
            this.$router.push("/choose-seat");
        },
        // End Get Data
        convertTime(time) {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}h`;
        },
        convertTimes(time) {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}`;
        },

        tirpInfo(description, slidePhoto, transportationPhoto, amenities, dropOffPointLats, dropOffPointLongs, boardingPointLats, boardingPointLongs, boardingPointList, dropOffPointList) {

            this.$router.push({
                name: 'tripinfo',
                params: {
                    decri: description,
                    slides: slidePhoto,
                    trans: transportationPhoto,
                    boardingLats: boardingPointLats,
                    boardingLongs: boardingPointLongs,
                    dropOffLats: dropOffPointLats,
                    dropOffLongs: dropOffPointLongs,
                    amen: amenities,
                    boardingPointList: boardingPointList,
                    dropOffPointList: dropOffPointList,
                }
            });
        }
    },
    created() {
        localStorage.removeItem("seatback");
        localStorage.removeItem("seatlabelback");
        setTimeout(() => {
            this.getDataSchedule();
            if (localStorage.getItem('selectNational') == 1) {
                this.selectNational = true;
            } else {
                this.selectNational = false;
            }
        }, 1000);
    }
}
</script>

<style scoped>
.mobiles-app {
    position: relative;
    margin-top: 20%;
    padding-bottom: 50px;
}

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
}

.not-found img {
    max-width: 200px;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.not-found-text {
    margin-top: 20px;
    font-size: 16px;
    color: #424245;
    font-weight: 500;
}

.dparture {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.show-form {
    padding-bottom: 20px;
    height: 363px;
}

.dparture .date {
    width: 80%;
}

.dparture .date span {
    font-weight: 600;
    font-size: 16px;
    color: #424245;
}

.dparture .search-img {
    display: flex;
    justify-content: end;
    width: 20%;
}

.dparture .search-img img {
    width: 25px;
    cursor: pointer;
}

.card-info {
    background: #ffffff;
    border: 1px solid #D7D5D5;
    border-radius: 12px;
    margin-bottom: 15px;
}

.card-info .card-box {
    border-bottom: 1px solid #D7D5D5;
}

.card-info .boxs {
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.card-info .boxs .trip-info {
    width: 50%;
}

.card-info .boxs .trip-info .infos {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.card-info .boxs .trip-info .infos img {
    padding-right: 10px;
}

.card-info .boxs .trip-info .infos div {
    color: #000000;
    font-weight: 600;
    font-size: 14px;
}

.card-info .boxs .booking {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.card-info .boxs .booking .btn-book {
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    background: #DE5D0A;
    border-radius: 4px;
}

.btn-leaving {
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    background: #D1D0D0;
    border: 1px solid #D1D0D0;
    border-radius: 4px;
}

.card-info .boxs .booking .btn-books {
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    background: #662D91;
    border-radius: 4px;
}

.card-info .card-box .box {
    padding: 20px;
}

.card-info .logo-type-bus {
    display: flex;
    align-items: center;
}

.card-info .logo-type-bus img {
    padding-right: 12px;
}

.card-info .logo-type-bus .type-bus {
    /* text-align: end; */
    font-size: 14px;
    font-weight: 600;
}

.desta-role {
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
}

.desta-role .dp-time {
    width: 80%;
}

.text-depature {
    display: flex;
    justify-items: center;
}

.text-depature .all-parture {
    width: 33.33%;
    color: #8E8B8B;
    font-size: 12px;
    font-weight: 400;
}

.number-depature {
    display: flex;
    justify-items: center;
}

.number-depature .all-part-num {
    width: 33.33%;
    font-weight: 600;
    font-size: 16px;
    color: #1D1D1D;
}

.desta-role .price {
    width: 30%;
    text-align: end;
    color: #1D1D1D;
    font-weight: 600;
    font-size: 26px;
}

.national-role {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.national-role .no-6a {
    width: 50%;
    font-weight: 600;
    font-size: 12px;
    color: #6A6969;
}

.national-role .boarding {
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    color: #6A6969;
}

.national-role .seat {
    /* width: 25%; */
    font-weight: 600;
    font-size: 12px;
    color: #424245;
    text-align: end;
}

/* Responsive styles */
@media (min-width: 768px) {
    .not-found {
        padding: 60px 20px;
    }
    
    .not-found img {
        max-width: 250px;
    }

    .not-found-text {
        font-size: 18px;
    }
}

@media (min-width: 1024px) {
    .not-found {
        padding: 80px 20px;
    }
    
    .not-found img {
        max-width: 300px;
    }

    .not-found-text {
        font-size: 20px;
    }
}
</style>